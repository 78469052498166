var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.MediaQuery.smOrSmaller
    ? _c(
        "div",
        [
          _c(
            "v-footer",
            { attrs: { app: "", fixed: "", padless: "" } },
            [
              _c(
                "v-bottom-navigation",
                { attrs: { grow: "", color: "primary" } },
                [
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.goHome()
                        },
                      },
                    },
                    [
                      _c("span", [_vm._v(_vm._s(_vm.$tc("nav.home")))]),
                      _c("v-icon", [_vm._v(_vm._s(_vm.RouterIcons.HOME))]),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    { attrs: { to: { name: _vm.RouterNames.SITEMAP } } },
                    [
                      _c("span", [_vm._v(_vm._s(_vm.$tc("nav.more")))]),
                      _c("v-icon", [_vm._v(_vm._s(_vm.RouterIcons.SITEMAP))]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }