import { i18n } from '@/i18n/i18n'

export class RouterIcons {
  static HOME = 'mdi-home'
  static AUTH_LOGIN = 'mdi-login'
  static AUTH_LOGOUT = 'mdi-logout'
  static AUTH_ACTIVATE = ''
  static AUTH_DENIED = ''
  static AUTH_NEWPASSWORD = ''
  static AUTH_RECOVERY = ''
  static AUTH_SIGNOUT = ''
  static ABOUT = ''
  static ACCOUNT = 'mdi-cog'
  static ACCOUNT_ACTIVITY = 'mdi-list-box'
  static ACCOUNT_ALERTS = ''
  static ACCOUNT_AUTHORIZEDUSER_LIST = 'mdi-account-multiple'
  static ACCOUNT_BOOKINGS = 'mdi-passport'
  static ACCOUNT_CODEWORDS = 'mdi-question'
  static ACCOUNT_COMMUNICATIONS = 'mdi-email'
  static ACCOUNT_CONTRIBUTORS = 'mdi-hand-coin'
  static ACCOUNT_CREDENTIALS = 'mdi-lock'
  static ACCOUNT_LODGING_GUESTS = 'mdi-bed'
  static ACCOUNT_LODGING_STAYS = 'mdi-bed'
  static ACCOUNT_LODGING_UNITS = 'mdi-office-building'
  static ACCOUNT_PAYMENTMETHODS = 'mdi-credit-card'
  // static ACCOUNT_PET_ADD = 'accountVehicleAdd'
  static ACCOUNT_PET_LIST = 'mdi-paw'
  // static ACCOUNT_VEHICLE_ADD = 'accountVehicleAdd'
  static ACCOUNT_VEHICLE_LIST = 'mdi-car'
  static ATTENDANCE = 'mdi-clipboard-check'
  static ATTENDANCE_DOWNLOAD = 'mdi-download'
  static BANK = 'mdi-bank'
  static BANK_ACCOUNT = 'mdi-cash'
  static BANK_BUDGETS = 'mdi-calculator'
  // static BANK_DEPOSITS = i18n.messages[i18n.locale]['icon.currency']
  static BANK_DEPOSITS = 'mdi-currency-usd'
  static BANK_SITEMAP = 'mdi-dots-horizontal'
  static BANK_TRANSFERS = 'mdi-swap-horizontal'
  static BANK_TRANSACTIONS = 'mdi-list-box'
  static CARE_PACKAGES = 'mdi-gift'
  static CARE_ORDERS = 'mdi-format-list-bulleted'
  static COMMENT = 'comment'
  static COMMENT_SAVE = 'check'
  static CLOSE = 'times'
  static DONATE = 'mdi-hand-coin';
  static HEALTH = 'mdi-medical-bag'
  static HEALTH_INFO = 'crutch'
  static HEALTH_MEDS = 'prescription-bottle'
  static INVOICE_LIST = 'mdi-receipt-text';
  static LINK_EXTERNAL = 'share'
  static RESERVATION = 'mdi-calendar-check'
  static SHOP = 'gift'
  static SHOP_BY_CATEGORY = ''
  static SHOP_CART = 'mdi-cart'
  static SHOP_ORDERS = 'list'
  static SITEMAP = 'mdi-dots-horizontal'
  static SITEMAP_CATEGORY = 'ellipsis-h'
  static TOUR = 'search'
  static NOT_IMPLEMENTED = ''
  static HELP = 'question-o'
}
