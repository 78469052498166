<template>
  <span v-if="currentUser && currentUser !== undefined && displayPhotoOption">
    <template v-if="MQ.smOrSmaller">
      <!-- show picture -->
      <v-img
        @click="revealImageUploader()"
        :src="imageUrl"
        alt="Image"
        bottom
        v-if="!showImageUpload"
      ></v-img>
      <v-btn
        @click.prevent.stop="showImageUpload = !showImageUpload"
        :block="MQ.smOrSmaller"
        class="mx-auto"
        color="primary"
        style="margin-left: 0"
        v-if="!showImageUpload && showSetPhoto"
      >Set Photo</v-btn>
      <!-- / show picture -->
      <!-- show upload box -->
      <picture-input
        ref="pictureInput"
        @change="onPictureAdded"
        :hideChangeButton="true"
        width="200"
        height="200"
        margin="16"
        size="3"
        accept="image/jpeg, image/png, image/heic"
        v-if="showImageUpload"
      ></picture-input>
            <v-btn
              v-if="showImageUpload && status === 'ready'"
              large
              :block="MQ.smOrSmaller"
              class="mt-2"
              color="light"
              @click="showImageUpload = !showImageUpload"
            >{{ $tc('phrase.cancel') }}</v-btn>
            <v-btn
              v-if="showImageUpload && status !== 'ready'"
              large
              :block="MQ.smOrSmaller"
              class="mt-2"
              color="gray"
              :disabled="true"
            >
              <v-progress-circular indeterminate/>
              {{ $tc(`${status} ...`) }}
            </v-btn>
      <!-- / show upload box -->
    </template>
    <template v-if="MQ.mdOrBigger">
      <v-container class="align-middle" style="min-width: 220px">
        <!-- show picture -->
        <v-row v-if="!showImageUpload && displayPhotoOption">
          <v-col>
            <v-img
              contain
              title="click to change picture"
              v-if="!showImageUpload"
              @click.native="revealImageUploader()"
              :src="imageUrl"
              height="300"
              alt="placeholder"
            />
          </v-col>
        </v-row>
        <v-row v-if="!showImageUpload && showSetPhoto" class>
          <v-col class="text-center">
            <v-btn
              large
              @click.prevent.stop="showImageUpload = !showImageUpload"
              :block="MQ.smOrSmaller"
              class="mx-auto"
              color="primary"
              style="margin-left: 0"
            >Set Photo</v-btn>
          </v-col>
        </v-row>
        <!-- / show picture -->
        <!-- show upload box -->
        <v-row v-if="showImageUpload" class="mx-auto">
          <v-col>
            <!--
              width, height: (pixels, optional) the maximum width and height of the preview container. The picture will be resized and centered to cover this area. If not specified, the preview container will expand to full width, 1:1 square ratio.
              crop: (boolean, optional) set :crop="false" if you wish to disable cropping. The image will be resized and centered in order to be fully contained in the preview container. Default value: true.
              margin: (pixels, optional) the margin around the preview container. Default value: 0.
              radius: (percentage, optional) The border-radius value for the container. Set radius="50" to get a circular container. Default value: 0.
              plain: (boolean, optional) Set :plain="true" to remove the inner border and text. Default value: false.
              accept: (media type, optional) the accepted image type(s), e.g. image/jpeg, image/gif, etc. Default value: 'image/*'.
              size: (MB, optional) the maximum accepted file size in megabytes.
              removable: (boolean, optional) set :removable="true" if you want to display a "Remove Photo" button. Default value: false.
              hideChangeButton: (boolean, optional) set :hideChangeButton="true" if you want to hide the "Change Photo" button. Default value: false.
              id, name: (string, optional) the id and name attributes of the HTML input element.
              buttonClass: (string, optional) the class which will be applied to the 'Change Photo' button. Default value: 'btn btn-primary button'.
              removeButtonClass: (string, optional) the class which will be applied to the 'Remove Photo' button. Default value: 'btn btn-secondary button secondary'.
              prefill: (image url or File object, optional) use this to specify the path to a default image (or a File object) to prefill the input with. Default value: empty.
              prefillOptions: (object, optional) use this if you prefill with a data uri scheme to specify a file name and a media or file type:
                fileName: (string, optional) the file name
                fileType: (string, optional) the file type of the image, i.e. "png", or
                mediaType: (string, optional) the media type of the image, i.e. "image/png"
              toggleAspectRatio: (boolean, optional) set :toggleAspectRatio="true" to show a button for toggling the canvas aspect ratio (Landscape/Portrait) on a non-square canvas. Default value: false.
              autoToggleAspectRatio: (boolean, optional) set :autoToggleAspectRatio="true" to enable automatic canvas aspect ratio change to match the selected picture's. Default value: false.
              changeOnClick: (boolean, optional) set :changeOnClick="true" to open image selector when user click on the image. Default value: true.
              aspectButtonClass: (string, optional) the class which will be applied to the 'Landscape/Portrait' button. Default value: 'btn btn-secondary button secondary'.
              zIndex: (number, optional) The base z-index value. In case of issues with your layout, change :zIndex="..." to a value that suits you better. Default value: 10000.
              alertOnError: (boolean, optional) Set :alertOnError="false" to disable displaying alerts on attemps to select file with wrong type or too big. Default value: true.
              customStrings: (object, optional) use this to provide one or more custom strings (see the example above). Here are the available strings and their default values:
            -->
            <picture-input
              ref="pictureInput"
              style="float:center !important; max-width: 300px"
              @change="onPictureAdded"
              :hideChangeButton="true"
              width="200"
              height="200"
              margin="16"
              size="3"
              accept="image/jpeg, image/png, image/heic"
              v-if="showImageUpload"
            ></picture-input>
          </v-col>
        </v-row>
        <v-row v-if="showImageUpload" class>
          <v-col class="text-center">
            <v-btn
              v-if="showImageUpload && status === 'ready'"
              large
              :block="MQ.smOrSmaller"
              class="mt-2"
              color="light"
              @click="showImageUpload = !showImageUpload"
            >{{ $tc('phrase.cancel') }}</v-btn>
            <v-btn
              v-if="showImageUpload && status !== 'ready'"
              large
              :block="MQ.smOrSmaller"
              class="mt-2"
              color="gray"
              :disabled="true"
            >
              <v-progress-circular indeterminate class="mx-2" />
              {{ $tc(`${status} ...`) }}
            </v-btn>
          </v-col>
        </v-row>
        <!-- / show upload box -->
      </v-container>
    </template>
  </span>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { NavModule, ProfileModule, ProfileUtils } from "@/store";
import { MediaQuery as MQ } from '@/MediaQuery';
import PictureInput from "vue-picture-input";
import { ParticipantPhotoRequest, User } from "@fgl/funfangle-sdk/dist/rest/profile";
import { ApiEndpoints, Constants } from "@/constants";
import { S3Upload } from "@/shared";
import { ApiService } from "@/shared/auth/auth-cognito";
// const short = require('short-uuid');

@Component({
  components: {
    // mdbCol,
    // mdbContainer,
    // mdbRow,
    PictureInput
  }
})
export default class PhotoUpload extends Vue {
  // bankStore: BankModule = getModule(BankModule, this.$store);
  navStore: NavModule = getModule(NavModule, this.$store);
  profileStore: ProfileModule = getModule(ProfileModule, this.$store);
  MQ = MQ;

  @Prop({ default: null })
  person!: User;

  @Prop()
  overrideEditable: boolean | undefined | null;

  errorMessage: string | null = null;
  // imageData: any = null;
  uploadStatus: { [key: string]: string } = {};
  showImageUpload = false;
  uploadStage: string | null = null;
  refreshCount = 0;

  get displayPhotoOption() {
    return this.navStore.displayPhotoOption;
  }

  get currentUser() {
    return this.person || this.navStore.selectedUser;
  }

  get currentUserId(): string {
    if (this.currentUser.userId === undefined) return 'standard';
    return this.currentUser.userId;
  }

  get imageUrl(): string {
    if (this.currentUser.imageUrl === undefined) return '';
    return this.currentUser.imageUrl + '?refresh=' + this.refreshCount;
  }

  get isEditable(): boolean {
    if (this.overrideEditable === true || this.overrideEditable === false) return this.overrideEditable;
    if (this.currentUser != null && this.currentUser !== undefined) {
      const utils = new ProfileUtils();
      return utils.isUserHeld(this.currentUser);
    }
    return false;
  }

  get showSetPhoto(): boolean {
    if (this.isEditable !== true) return false;
    if (
      this.currentUser &&
      (!this.currentUser.imageUrl ||
        this.currentUser.imageUrl === "" ||
        this.currentUser.imageUrl === "/img/campers/default.png")
    ) {
      return true;
    }
    return false;
  }

  get status(): string {
    if (this.currentUserId === undefined) return 'ready';
    return this.uploadStatus[this.currentUserId] || 'ready';
  }

  // finishUpload() {
  //   // console.log('Finish upload...')
  //   // let params = {
  //   //   type: this.imageData.type,
  //   //   data: this.imageData.base64Data,
  //   //   userId: this.navStore.selectedUserId,
  //   //   key: this.navStore.selectedUserId
  //   // };
  //   this.profileStore.uploadTempImage({
  //     data: this.imageData.base64Data,
  //     userId: this.currentUser.userId || 'UNSET',
  //     type: this.imageData.type
  //   }).then(() => {
  //       // console.log('upload response ', res)
  //       this.uploadStatus[this.currentUserId] = 'processing';
  //       this.imageData = null;
  //       this.showImageUpload = false;
  //     })
  //     .catch(() => {
  //       this.imageUploading = false;
  //       // console.log('Upload err:', err)
  //       alert(
  //         "There was a problem uploading the image. Please reload and try again"
  //       );
  //     });
  // }

  revealImageUploader() {
    if (this.isEditable === true) this.showImageUpload = !this.showImageUpload
  }

  // async blobToBase64Buffer(blob: Blob): Promise<Uint8Array> {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(blob);

  //     reader.onload = () => {
  //       const base64Str = reader.result as string;
  //       const base64EncodedData = base64Str.split(",")[1];
  //       const byteCharacters = atob(base64EncodedData);
  //       const byteNumbers = new Array(byteCharacters.length);

  //       for (let i = 0; i < byteCharacters.length; i++) {
  //         byteNumbers[i] = byteCharacters.charCodeAt(i);
  //       }

  //       const byteArray = new Uint8Array(byteNumbers);
  //       resolve(byteArray);
  //     };

  //     reader.onerror = (err) => {
  //       reject(err);
  //     };
  //   });
  // }

  // blobToFile(blob: Blob, fileName: string): File {
  //   // const lastModified = new Date(); // Use current date/time
  //   const fileOptions = { type: blob.type };

  //   return new File([blob], fileName, fileOptions);
  // }

  async onPictureAdded(/* base64: any */) {
    Vue.set(this.uploadStatus, this.currentUserId, 'uploading');
    // const base64Data = Buffer.from(
    //   base64.replace(/^data:image\/\w+;base64,/, ""),
    //   "base64"
    // );
    // const type = base64.split(";")[0].split("/")[1];
    // this.imageData = {
    //   type,
    //   base64Data
    // };
    // auto-upload

    // // if (!params || params === undefined) return Promise.reject(new Error('Params are undefined'));
    // // if (params.userId === undefined || params.userId === 'UNSET') return Promise.reject(new Error('User ID is undefined'));
    // // if (params.type === undefined || params.type === 'UNSET') return Promise.reject(new Error('Type is undefined'));
    // // if (!params.data || params.data === undefined) return Promise.reject(new Error('Data is undefined'));
    // console.log('file ', file);
    // console.log('component ', component);

    // console.log('onPictureAdded pictureInput', this.$refs.pictureInput);
    // console.log('onPictureAdded pictureInput.file', (this.$refs.pictureInput as any).file);
    // console.log('onPictureAdded pictureInput.image', (this.$refs.pictureInput as any).image);
    if (this.currentUser.userId === undefined) return Promise.reject(new Error('UserId is undefined'));
    if (this.$refs.pictureInput === undefined) return Promise.reject(new Error('PictureInput is undefined'));
    // console.log('pictureInput: ', this.$refs.pictureInput);
    let { fileName, fileType } = (this.$refs.pictureInput as any);
    if (!fileName || fileName === undefined) return Promise.reject(new Error('File name is undefined'));
    if (!fileType || fileType === undefined) return Promise.reject(new Error('File type is undefined'));
    // if (fileName) return;
    let fileExt = 'zzz';
    if (fileType === 'image/png' || /\.(png)$/i.test(fileName)) fileExt = 'png';
    if (fileType === 'image/jpeg' || /\.(jpg)$/i.test(fileName) || /\.(jpeg)$/i.test(fileName)) fileExt = 'jpg';
    if (fileType === 'image/heic' || /\.(heic)$/i.test(fileName)) fileExt = 'heic';
    if (fileExt === 'zzz') {
      window.alert('File must be PNG, JPG or HEIC');
      return Promise.reject(new Error('File must be PNG, JPG or HEIC'));
    }
    // ÷const translator = short();
    // const shortUID = translator.fromUUID(this.currentUser.userId);
    const organizationId = this.profileStore.currOrganization.organizationId || 'UNSET';
    const filename = `${Date.now()}_profile.${fileExt}`;
    const bucketKey = `${organizationId}/uploads/user/${this.currentUser.userId}/${filename}`;

    // let f = base64;
    // if (f instanceof Blob) {
    //   console.log('f is blob');
    //   f = this.blobToFile(f, filename);
    // } else if (f instanceof File) {
    //   console.log('f is file');
    // } else if (f instanceof String) {
    //   console.log('f is file');
    // } else {
    //   console.log(Object.prototype.toString.call(f));
    //   console.log('f is neither blob nor file');
    //   // return Promise.reject(new Error('File is neither blob nor file'));
    // }
    // const stri = base64.replace(/^data:image\/\w+;base64,/, "base64,")
    // const file = this.blobToBase64Buffer(new Blob([base64], { type: fileType }));

    let fileUploaded = false;
    this.uploadStage = 'uploading';
    const s3 = new S3Upload();
    // upload file
    // console.log('uploadToBucket ', Constants.idpRegion, Constants.importBucket, bucketKey, fileType, 'base64');
    await s3
      .uploadToBucket(Constants.idpRegion, Constants.importBucket, bucketKey, (this.$refs.pictureInput as any).file, fileType, 'base64')
      .then((res: any) => {
        if (res !== undefined) fileUploaded = true;
        // console.log('res ', res);
      })
      .catch((err: Error) => {
        this.uploadStage = null;
        this.errorMessage = err.message;
        // console.log('err1 ', err);
      });

    if (fileUploaded === false) {
      // this.files = [];
      this.uploadStage = null;
      Vue.set(this.uploadStatus, this.currentUserId, 'ready');
      if (this.errorMessage == null) this.errorMessage = 'Upload failed';
      // dang
      return;
    }
    Vue.set(this.uploadStatus, this.currentUserId, 'processing');

    const apiSvc = new ApiService();
    let processId: string | undefined;
    const photoRequest = new ParticipantPhotoRequest(this.currentUser.userId, filename, organizationId, this.profileStore.userId);
    await apiSvc
      .httpPost(`${ApiEndpoints.apiEndpoint}/v2/profile/participant/photo`, photoRequest)
      .then((r) => {
        if (r?.data?.item !== undefined) {
          processId = r?.data?.item?.processId;
        }
        // console.info('photoRequest processId=', processId, ' res=', r, 'req=', photoRequest);
      })
      .catch((err: Error) => {
        this.errorMessage = err.message;
        // console.error('photoRequest err=', err, 'req=', photoRequest);
      });
    // console.log('initiated photo process processId=', processId);

    if (processId === undefined) {
      Vue.set(this.uploadStatus, this.currentUserId, 'ready');
      this.uploadStage = null;
      if (this.errorMessage == null) this.errorMessage = 'Upload failed';
      // dang
      return;
    }

    let userDelta: User | undefined;
    this.errorMessage = null;
    let fileImported = false;
    let tryCount = 0;
    while (!fileImported && this.errorMessage == null && tryCount < 15000) { // Set to 15,000 for 15 min
      await new Promise(f => setTimeout(f, 500));
      await apiSvc
        .httpGet(`${ApiEndpoints.apiEndpoint}/v2/profile/status/processqueue?organizationId=${organizationId}&processId=${processId}`)
        .then((r) => {
          if (r.data.item?.status === 'FINISHED') {
            fileImported = true;
            if (r.data.item?.response?.user !== undefined) {
              userDelta = r.data.item.response.user;
            }
          }
          if (r.data.item?.status === 'ERROR') {
            fileImported = true;
            this.errorMessage = r.data.message;
          }
          // console.info('process status res=', r, 'user=', userDelta);
        })
        .catch((err: Error) => {
          this.errorMessage = err.message;
          // console.error(err);
        });
      tryCount += 1;
    }
    // console.log('uploaded process ended', 'user=', userDelta);

    // if (this.errorMessage == null) {
    //   console.log('errorMessage == null');
    //   Vue.set(this.uploadStatus, this.currentUserId, 'ready');
    //   return;
    // }
    if (fileImported === false) {
      // console.log('fileImported === false');
      // this.files = [];
      Vue.set(this.uploadStatus, this.currentUserId, 'ready');
      this.uploadStage = null;
      if (this.errorMessage == null) this.errorMessage = 'Upload failed';
      // dang
      return;
    }

    // TODO
    // if (res !== undefined && res.Location !== undefined) {
    // let child: User | undefined;
    // this.profileStore.authorizedUsers.forEach(user => {
    //   if (user.userId === this.currentUser.userId) {
    //     child = user;
    //   }
    // })
    // if (child !== undefined && userDelta !== undefined) {
    //   child.imageUrl150 = userDelta.imageUrl150;
    //   child.imageUrl70 = userDelta.imageUrl70;
    //   child.imageUrl = userDelta.imageUrl;
    //   this.profileStore.setCurrentPairedUser(child);
    // }

    // if (userDelta !== undefined) {
    //   this.profileStore.setCurrentPairedUserImages(userDelta);
    // }

    if (userDelta !== undefined) {
      this.currentUser.imageUrl150 = userDelta.imageUrl150;
      this.currentUser.imageUrl70 = userDelta.imageUrl70;
      this.currentUser.imageUrl = userDelta.imageUrl;
    }
    // console.log('uploadStatus ... ready');
    Vue.set(this.uploadStatus, this.currentUserId, 'ready');
    this.uploadStage = 'uploaded';
    // this.section = 'results';
    this.showImageUpload = false;
    this.refreshCount += 1;

    return Promise.resolve();
  }


  mounted() {
    if (
      this.currentUser &&
      (!this.currentUser.imageUrl ||
        this.currentUser.imageUrl === "")
    ) {
      this.showImageUpload = true;
    }
  }
}
</script>

<style>
</style>
