var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.showCustomMessage
    ? _c(
        "v-container",
        { staticClass: "p-0", attrs: { fluid: "" } },
        [
          _vm.currentPerson
            ? _c(
                "v-row",
                { staticClass: "margin-one" },
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-item-group",
                        {
                          staticClass: "mx-auto d-flex",
                          attrs: { vertical: "" },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mx-auto",
                              staticStyle: { "margin-left": "0" },
                              attrs: { color: "primary", large: "", block: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.openCarePackages(_vm.currentPerson)
                                },
                              },
                            },
                            [
                              _c("v-icon", { staticClass: "mx-2" }, [
                                _vm._v(_vm._s(_vm.routerIcons.SHOP_CART)),
                              ]),
                              !_vm.MQ.xs
                                ? [_vm._v(_vm._s(_vm.buttonText))]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }