<template>
  <div v-if="MediaQuery.smOrSmaller">
    <v-footer app fixed class="pa-0">
      <v-bottom-navigation grow color="blue">
        <v-btn :to="{ name: RouterNames.HOME }" text>
          <span class="d-flex flex-column align-center">
            <v-icon>{{ RouterIcons.HOME }}</v-icon>
            <span class="mt-1">{{ $tc('nav.home') }}</span>
          </span>
        </v-btn>

        <v-btn :to="{ name: RouterNames.CARE_PACKAGES }" text>
          <span class="d-flex flex-column align-center">
            <v-icon>{{ RouterIcons.CARE_PACKAGES }}</v-icon>
            <span class="mt-1">{{ $tc('nav.care.packages') }}</span>
          </span>
        </v-btn>

        <v-btn :to="{ name: RouterNames.CARE_ORDERS }" text>
          <span class="d-flex flex-column align-center">
            <v-icon>{{ RouterIcons.CARE_ORDERS }}</v-icon>
            <span class="mt-1">{{ $tc('nav.care.orders') }}</span>
          </span>
        </v-btn>

        <v-btn :to="{ name: RouterNames.SITEMAP }" text>
          <span class="d-flex flex-column align-center">
            <v-icon>{{ RouterIcons.SITEMAP }}</v-icon>
            <span class="mt-1">{{ $tc('nav.more') }}</span>
          </span>
        </v-btn>
      </v-bottom-navigation>
    </v-footer>
  </div>
</template>
<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { MediaQuery } from '@/MediaQuery';
import { RouterIcons } from '@/router/RouterIcons'
import { RouterNames } from '@/router/RouterNames'

@Component({
  components: {
    // mdbCol,
    // mdbContainer,
    // mdbIcon,
    // mdbRow
  }
})
export default class CareFooter extends Vue {
  MediaQuery = MediaQuery;
  RouterIcons = RouterIcons;
  RouterNames = RouterNames;
}
</script>
<style scoped>

</style>
