import axios from 'axios';
import { getModule } from 'vuex-module-decorators';
import { store } from '../../store/Store';
import { AuthModule } from '../auth/auth-cognito';

axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(err) {
    // const storeAuth: AuthModule = getModule(AuthModule, store);
    // storeAuth.setLastCallbackErr(err);
    // if error is ...
    const error = err.response;
    if (error !== undefined && error.status === 401) {
      // console.log('ApiClient axios.interceptors.response ERR on AXIOS', error.status, error.statusText);
      // reload ...
      // eslint-disable-next-line no-self-assign
      // window.location.href = window.location.href;
    }
    // if (error.status === 401 && error.config && !error.config.__isRetryRequest) {
    //   // // console.log('ERROR on AXIOS 401');
    //   // // request for a new token
    //   // let storeAuth: AuthModule = getModule(AuthModule, store);
    //   // return storeAuth.refreshToken().then(() => {
    //   //   // console.log('ERROR on AXIOS Refreshed Token ');
    //   //   // update the error config with new token
    //   //   error.config.__isRetryRequest = true;
    //   //   error.config.headers.token = storeAuth.userIdToken;
    //   //   axios.defaults.headers.common['Authorization'] = storeAuth.userIdToken;
    //   //   return axios(error.config);
    //   // });
    // }
    return err;
  }
);

export class ApiClient {
  public storeAuth: AuthModule = getModule(AuthModule, store);

  public async checkToken(): Promise<void> {
    if (!this || !this.storeAuth || this.storeAuth === undefined) return Promise.resolve();
    console.log('ApiClient checkToken: token expiration=', this.storeAuth.idTokenExpiresAt > Date.now(), this.storeAuth.idTokenExpiresAt, Date.now());
    if (this.storeAuth.idTokenExpiresAt > Date.now()) {
      await this.storeAuth.refreshToken().catch((e) => {
        // console.log('ApiClient checkToken: token expired, refreshToken', e.name, e.message);
      });
    }
    return Promise.resolve();
  }

  public async httpPost(url: string, data: any): Promise<any> {
    console.log('ApiClient httpPost', url);
    await this.checkToken().catch((e) => {
      console.log('ApiClient httpPost: checkToken failed', e.name, e.message);
    });
    if (this.storeAuth.userIdToken != null && this.storeAuth.userIdToken !== undefined) axios.defaults.headers.common['Authorization'] = this.storeAuth.userIdToken;
    return axios.post(url, data);
  }

  public async httpGet(url: string): Promise<any> {
    console.log('ApiClient httpGet', url);
    await this.checkToken().catch((e) => {
      console.log('ApiClient httpGet: checkToken failed', e.name, e.message);
    });
    if (this.storeAuth.userIdToken != null && this.storeAuth.userIdToken !== undefined) axios.defaults.headers.common['Authorization'] = this.storeAuth.userIdToken;
    return axios.get(url);
  }
}
