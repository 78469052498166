import { CognitoUserSession } from 'amazon-cognito-identity-js';
import Vue from 'vue';

export class AuthCookies extends Vue {
  public static REFERRAL_ID = 'fgl-referral-id-24p';
  public static REFRESH_TOKEN = 'fgl-refresh-token-24';
  public static ORG_ID = 'fgl-org-id-24';
  public static USER_ID = 'fgl-user-id-24p';
  public static USERNAME = 'fgl-username-24';

  public static getReferralId() {
    if (!this || this.$cookies === undefined) return;
    return this.$cookies.get(AuthCookies.REFERRAL_ID);
  }

  public static getRefreshToken() {
    if (!this || this.$cookies === undefined) return;
    return this.$cookies.get(AuthCookies.REFRESH_TOKEN);
  }

  public static getOrganizationId() {
    if (!this || this.$cookies === undefined) return;
    return this.$cookies.get(AuthCookies.ORG_ID);
  }

  public static getUserId() {
    if (!this || this.$cookies === undefined) return;
    return this.$cookies.get(AuthCookies.USER_ID);
  }

  public static getUsername() {
    if (!this || this.$cookies === undefined) return;
    return this.$cookies.get(AuthCookies.USERNAME);
  }

  public static resetCookies() {
    if (!this || this.$cookies === undefined) return;
    const hostname = window.location.hostname;
    this.$cookies.remove(AuthCookies.REFRESH_TOKEN, '/', hostname);
    this.$cookies.remove(AuthCookies.ORG_ID, '/', hostname);
    this.$cookies.remove(AuthCookies.USER_ID, '/', hostname);
    this.$cookies.remove(AuthCookies.USERNAME, '/', hostname);
  }

  public static setOrganizationIdCookie(organizationId: string): void {
    if (!this || this.$cookies === undefined) return;
    const hostname = window.location.hostname;
    const secure = location.protocol === 'http:' && (location.hostname === 'localhost' || /lvh\.me$/.test(location.hostname)) ? false : true;
    this.$cookies.set(AuthCookies.ORG_ID, organizationId, '90d', '/', hostname, secure);
  }

  public static setReferralId(id: string, expiration = '14d') {
    if (!this || this.$cookies === undefined) return;
    const hostname = window.location.hostname;
    const secure = location.protocol === 'http:' && (location.hostname === 'localhost' || /lvh\.me$/.test(location.hostname)) ? false : true;
    this.$cookies.set(AuthCookies.REFERRAL_ID, id, expiration, '/', hostname, secure);
  }

  public static setSessionCookie(session: CognitoUserSession, expiration = '14d'): void {
    if (!this || this.$cookies === undefined) return;
    const hostname = window.location.hostname;
    const secure = location.protocol === 'http:' && (location.hostname === 'localhost' || /lvh\.me$/.test(location.hostname)) ? false : true;
    this.$cookies.set(AuthCookies.REFRESH_TOKEN, session.getRefreshToken().getToken(), expiration, '/', hostname, secure);
  }

  public static setSessionTokenCookie(token: string, expiration = '14d'): void {
    if (!this || this.$cookies === undefined) return;
    const hostname = window.location.hostname;
    const secure = location.protocol === 'http:' && (location.hostname === 'localhost' || /lvh\.me$/.test(location.hostname)) ? false : true;
    this.$cookies.set(AuthCookies.REFRESH_TOKEN, token, expiration, '/', hostname, secure);
  }

  public static setUserIdCookie(userId: string): void {
    if (!this || this.$cookies === undefined) return;
    const hostname = window.location.hostname;
    const secure = location.protocol === 'http:' && (location.hostname === 'localhost' || /lvh\.me$/.test(location.hostname)) ? false : true;
    this.$cookies.set(AuthCookies.USER_ID, userId, '90d', '/', hostname, secure);
  }

  public static setUsernameCookie(username: string): void {
    if (!this || this.$cookies === undefined) return;
    const hostname = window.location.hostname;
    const secure = location.protocol === 'http:' && (location.hostname === 'localhost' || /lvh\.me$/.test(location.hostname)) ? false : true;
    this.$cookies.set(AuthCookies.USERNAME, username, '30d', '/', hostname, secure);
  }
}
