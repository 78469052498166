import Vue from 'vue'
import Router from 'vue-router'
import { store } from '@/store/Store';
import { getModule } from 'vuex-module-decorators';
import { NavModule, ProfileModule } from '@/store';
import { AuthModule } from '../shared/auth/auth-cognito/AuthModule';
import HomeView from '@/views/Home.vue'
import { RouterNames } from './RouterNames'
import { CookieUtils, WindowLocationUtils } from '@/shared';
import { StoreUtils } from '@/store';

Vue.use(Router)

const authStore = getModule(AuthModule, store);
const navStore = getModule(NavModule, store);
const profileStore = getModule(ProfileModule, store);

// const enableAuthBypassLocalhost = true

export interface ExternalUrl {
  route: string;
  queryParams: string[];
}

const allowedExternalUrls: ExternalUrl[] = [
  {
    route: '/remainingFunds/remainingFunds.html',
    queryParams: [
      'userId',
      'session',
      'type',
      'authUserId'
    ]
  },
  {
    route: '/seasonalFunds/seasonalFunds.html',
    queryParams: [
      'userId',
      'type'
    ]
  }
]

const basedomain = 'funfangle.com';
const consoles = new Set<string>([`console.${basedomain}`, `staging-console.${basedomain}`, `test-console.${basedomain}`, `dev-console.dev.${basedomain}`]);
let prefix = '';
if (consoles.has(WindowLocationUtils.getHostname())) {
  prefix = '/portal';
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: `${prefix}/`,
      name: RouterNames.HOME,
      component: HomeView,
      meta: { footerMenu: 'home' }
    },
    /* security routes */
    {
      path: `${prefix}/signin`,
      name: RouterNames.AUTH_LOGIN,
      component: () => import('@/shared/auth/Login.vue'),
      meta: { guest: true }
    },
    {
      path: `${prefix}/signout`,
      name: RouterNames.AUTH_LOGOUT,
      component: () => import('@/shared/auth/Logout.vue'),
      meta: { guest: true }
    },
    {
      path: `${prefix}/start`,
      name: RouterNames.AUTH_GETSTARTED,
      component: () => import('@/shared/auth/GetStarted.vue'),
      meta: { guest: true, topMenu: 'none', footMenu: 'none' }
    },
    {
      path: `${prefix}/activate/:userId/:parentNum`,
      name: RouterNames.AUTH_ACTIVATE,
      component: () => import(/* webpackChunkName: "activate" */ '@/shared/auth/Activate.vue'),
      meta: { guest: true }
    },
    {
      path: `${prefix}/activate/activate.html`,
      name: RouterNames.AUTH_ACTIVATE_QS,
      component: () => import(/* webpackChunkName: "activate" */ '@/shared/auth/Activate.vue'),
      meta: { guest: true }
    },
    {
      path: `${prefix}/auth/newpassword`,
      name: RouterNames.AUTH_NEWPASSWORD,
      component: () => import(/* webpackChunkName: "authx" */ '@/shared/auth/NewPassword.vue'),
      meta: { guest: true }
    },
    {
      path: `${prefix}/auth/denied`,
      name: RouterNames.AUTH_DENIED,
      component: () => import(/* webpackChunkName: "authx" */ '@/shared/auth/AccessDenied.vue'),
      meta: { guest: true }
    },
    {
      path: `${prefix}/auth/recovery`,
      name: RouterNames.AUTH_RECOVERY,
      component: () => import(/* webpackChunkName: "authx" */ '@/shared/auth/AccessRecovery.vue'),
      meta: { guest: true }
    },
    {
      path: `${prefix}/signup`,
      name: RouterNames.AUTH_CREATE,
      component: () => import(/* webpackChunkName: "authx" */ '@/shared/auth/NewAccount.vue'),
      meta: { guest: true }
    },
    {
      path: `${prefix}/auth/new`,
      name: 'authCreateOld1',
      redirect: { name: RouterNames.AUTH_CREATE }
    },
    {
      path: `${prefix}/auth/request/invite`,
      name: RouterNames.AUTH_INVITATION,
      component: () => import(/* webpackChunkName: "authx" */ '@/shared/auth/RequestInvitation.vue'),
      meta: { guest: true }
    },
    {
      path: `${prefix}/auth/verify`,
      name: RouterNames.AUTH_VERIFY,
      component: () => import(/* webpackChunkName: "authx" */ '@/shared/auth/AccessRecovery.vue'),
      meta: { guest: true }
    },
    {
      path: `${prefix}/auth/select/organization`,
      name: RouterNames.AUTH_SELECT_ORG,
      component: () => import(/* webpackChunkName: "authx" */ '@/shared/auth/SelectOrganization.vue')
    },
    {
      path: `/approve/generalwaiver/:organizationId/:userId`,
      name: RouterNames.APPROVE_WAIVER,
      component: () => import(/* webpackChunkName: "activate" */ '@/views/onboarding/WaiverApproval.vue'),
      meta: { guest: true, topMenu: 'none', footMenu: 'none' }
    },
    /* account routes */
    {
      path: `${prefix}/account`,
      name: RouterNames.ACCOUNT,
      component: () => import(/* webpackChunkName: "account" */ '@/views/account/AccountHome.vue'),
      meta: { footerMenu: 'home' }
    },
    {
      path: `${prefix}/account/activity`,
      name: RouterNames.ACCOUNT_ACTIVITY,
      component: () => import(/* webpackChunkName: "account" */ '@/views/account/Activity.vue'),
      meta: {
        footerMenu: 'account'
      }
    },
    {
      path: `${prefix}/account/codewords`,
      name: RouterNames.ACCOUNT_CODEWORDS,
      component: () => import(/* webpackChunkName: "account" */ '@/views/account/codewords/PersonCodewords.vue'),
      meta: { footerMenu: 'account' }
    },
    {
      path: `${prefix}/account/participant/list`,
      name: RouterNames.ACCOUNT_AUTHORIZEDUSER_LIST,
      component: () => import(/* webpackChunkName: "account" */ '@/views/account/authorizedusers/MainMenuParticipants.vue'),
      meta: {
        footerMenu: 'account'
      }
    },
    {
      path: `${prefix}/account/participant/new`,
      name: RouterNames.ACCOUNT_AUTHORIZEDUSER_ADD,
      component: () => import(/* webpackChunkName: "account" */ '@/views/account/authorizedusers/AuthorizedUser.vue'),
      meta: {
        footerMenu: 'account'
      }
    },
    {
      path: `${prefix}/account/pet/list`,
      name: RouterNames.ACCOUNT_PET_LIST,
      component: () => import(/* webpackChunkName: "account" */ '@/views/account/pets/PetList.vue'),
      meta: { footerMenu: 'account' }
    },
    {
      path: `${prefix}/account/pet/new`,
      name: RouterNames.ACCOUNT_PET_ADD,
      component: () => import(/* webpackChunkName: "account" */ '@/views/account/pets/AddPet.vue'),
      meta: { footerMenu: 'account' }
    },
    {
      path: `${prefix}/account/reservations`,
      name: RouterNames.ACCOUNT_BOOKINGS,
      component: () => import(/* webpackChunkName: "account" */ '@/views/account/bookings/MainMenu.vue'),
      meta: {
        backbar: false,
        footerMenu: 'account'
      }
    },
    {
      path: `${prefix}/account/communications/lowbalance`,
      name: RouterNames.ACCOUNT_COMMUNICATIONS_LOWBALANCE,
      component: () => import(/* webpackChunkName: "account" */ '@/views/account/communications/LowBalanceEditor.vue'),
      meta: {
        backbar: true,
        footerMenu: 'account'
      }
    },
    {
      path: `${prefix}/account/communications`,
      name: RouterNames.ACCOUNT_COMMUNICATIONS,
      component: () => import(/* webpackChunkName: "account" */ '@/views/account/communications/MainMenu.vue'),
      meta: {
        backbar: true,
        footerMenu: 'account'
      }
    },
    {
      path: `${prefix}/account/contributor`,
      name: RouterNames.ACCOUNT_CONTRIBUTOR,
      component: () => import(/* webpackChunkName: "account" */ '@/views/account/contributors/Contributor.vue'),
      meta: {
        backbar: false,
        footerMenu: 'account'
      }
    },
    {
      path: `${prefix}/account/contributors`,
      name: RouterNames.ACCOUNT_CONTRIBUTORS,
      component: () => import(/* webpackChunkName: "account" */ '@/views/account/contributors/Contributors.vue'),
      meta: {
        backbar: false,
        footerMenu: 'account'
      }
    },
    {
      path: `${prefix}/account/credentials`,
      name: RouterNames.ACCOUNT_CREDENTIALS,
      component: () => import(/* webpackChunkName: "account" */ '@/shared/account/credentials/MainMenu.vue'),
      meta: {
        backbar: true,
        footerMenu: 'account'
      }
    },
    // {
    //   path: '/account/guest/list',
    //   name: RouterNames.ACCOUNT_LODGING_GUESTS,
    //   component: () => import(/* webpackChunkName: "account" */ '@/views/account/lodgings/MainMenuStays.vue'),
    //   meta: {
    //     backbar: false,
    //     footerMenu: 'home'
    //   }
    // },
    {
      path: `${prefix}/account/lodging/stay/new`,
      name: RouterNames.ACCOUNT_LODGING_STAY_ADD,
      component: () => import(/* webpackChunkName: "account" */ '@/views/account/lodgings/AddStay.vue'),
      meta: {
        backbar: false,
        footerMenu: 'lodging'
      }
    },
    {
      path: `${prefix}/account/lodging/stays`,
      name: RouterNames.ACCOUNT_LODGING_STAYS,
      component: () => import(/* webpackChunkName: "account" */ '@/views/account/lodgings/MainMenuStays.vue'),
      meta: {
        backbar: false,
        footerMenu: 'lodging'
      }
    },
    {
      path: `${prefix}/account/lodging/units`,
      name: RouterNames.ACCOUNT_LODGING_UNITS,
      component: () => import(/* webpackChunkName: "account" */ '@/views/account/lodgings/MainMenuUnits.vue'),
      meta: {
        backbar: false,
        footerMenu: 'lodging'
      }
    },
    {
      path: `${prefix}/account/payment`,
      name: RouterNames.ACCOUNT_PAYMENTMETHODS,
      component: () => import(/* webpackChunkName: "account" */ '@/views/account/paymentmethods/MainMenu.vue'),
      meta: {
        footerMenu: 'account'
      }
    },
    {
      path: `${prefix}/account/payment/new`,
      name: RouterNames.ACCOUNT_PAYMENTMETHODS_ADD,
      component: () => import(/* webpackChunkName: "account" */ '@/views/account/paymentmethods/new/AddMethod.vue'),
      meta: {
        backbar: true,
        footerMenu: 'account'
      }
    },
    {
      path: `${prefix}/account/ticket/:mode/:ticketId`,
      name: RouterNames.ACCOUNT_TICKET_VIEWER,
      props: true,
      component: () => import(/* webpackChunkName: "account" */ '@/views/account/tickets/view/TicketViewer.vue'),
      meta: { footerMenu: 'bank' }
    },
    {
      path: `${prefix}/account/vehicle/list`,
      name: RouterNames.ACCOUNT_VEHICLE_LIST,
      component: () => import(/* webpackChunkName: "account" */ '@/views/account/vehicles/VehicleList.vue'),
      meta: { footerMenu: 'account' }
    },
    {
      path: `${prefix}/account/vehicle/new`,
      name: RouterNames.ACCOUNT_VEHICLE_ADD,
      component: () => import(/* webpackChunkName: "account" */ '@/views/account/vehicles/AddVehicle.vue'),
      meta: { footerMenu: 'account' }
    },
    /* attendance routes */
    {
      path: `${prefix}/attendance/download`,
      name: RouterNames.ATTENDANCE_DOWNLOAD,
      component: () => import(/* webpackChunkName: "account" */ '@/views/attendance/AttendanceDownload.vue'),
      meta: {
        footerMenu: 'home'
      }
    },

    /* bank routes */
    {
      path: `${prefix}/bank`,
      name: RouterNames.BANK,
      component: () => import(/* webpackChunkName: "bank" */ '@/views/bank/Accounts.vue'),
      meta: { footerMenu: 'bank' }
    },
    {
      path: `${prefix}/bank/account/:personId`,
      name: RouterNames.BANK_ACCOUNT,
      props: true,
      component: () => import(/* webpackChunkName: "bank" */ '@/views/bank/AccountDetails.vue'),
      meta: { footerMenu: 'bank' }
    },
    {
      path: `${prefix}/bank/budgets`,
      name: RouterNames.BANK_BUDGETS,
      component: () => import(/* webpackChunkName: "bank" */ '@/views/bank/Budgets.vue'),
      meta: {
        backbar: true,
        footerMenu: 'bank'
      }
    },
    {
      path: `${prefix}/bank/budget/:personId`,
      name: RouterNames.BANK_BUDGET,
      props: true,
      component: () => import(/* webpackChunkName: "bank" */ '@/components/budgets/BudgetWizard.vue'),
      meta: {
        backbar: true,
        footerMenu: 'bank'
      }
    },
    {
      path: `${prefix}/bank/deposits`,
      name: RouterNames.BANK_DEPOSITS,
      component: () => import(/* webpackChunkName: "bank" */ '@/views/bank/deposits/Deposits.vue'),
      meta: {
        backbar: true,
        footerMenu: 'none'
      }
    },
    {
      path: `${prefix}/offer/remaining`,
      name: 'offerRemaining',
      component: () => import(/* webpackChunkName: "bank" */ '@/views/bank/RemainingBalanceUA.vue'),
      meta: { guest: true }
    },
    {
      path: `${prefix}/bank/sitemap`,
      name: RouterNames.BANK_SITEMAP,
      component: () => import(/* webpackChunkName: "bank" */ '@/views/bank/Sitemap.vue'),
      meta: { footerMenu: 'bank' }
    },
    {
      path: `${prefix}/bank/transfers`,
      name: RouterNames.BANK_TRANSFERS,
      component: () => import(/* webpackChunkName: "bank" */ '@/views/bank/Transfers.vue'),
      meta: {
        backbar: true,
        footerMenu: 'bank'
      }
    },
    {
      path: `${prefix}/bank/transactions`,
      name: RouterNames.BANK_TRANSACTIONS,
      component: () => import(/* webpackChunkName: "bank" */ '@/views/bank/Transactions.vue'),
      meta: { footerMenu: 'bank' }
    },
    {
      path: `${prefix}/bank/remaining`,
      name: RouterNames.BANK_REMAINING,
      component: () => import(/* webpackChunkName: "bank" */ '@/views/bank/RemainingBalance.vue'),
      meta: { footerMenu: 'bank' }
    },
    {
      path: `${prefix}/calendar`,
      name: 'calendar',
      component: () => import(/* webpackChunkName: "calendar" */ '@/views/calendar/CalendarViewer.vue'),
      meta: { footerMenu: 'home' }
    },
    {
      path: `${prefix}/donate`,
      name: 'donate',
      component: () => import(/* webpackChunkName: "donate" */ '@/views/donate/MakeDonation.vue'),
      meta: { footerMenu: 'home' }
    },
    {
      path: `${prefix}/health`,
      name: RouterNames.HEALTH,
      component: () => import(/* webpackChunkName: "health" */ '@/views/health/HealthAccounts.vue'),
      meta: { footerMenu: 'health' }
    },
    {
      path: `${prefix}/health/detail/:personId`,
      name: RouterNames.HEALTH_DETAIL,
      component: () => import(/* webpackChunkName: "health" */ '@/views/health/HealthDetail.vue'),
      meta: { footerMenu: 'health' }
    },
    // {
    //   path: `${prefix}/health/account/:personId`,
    //   name: RouterNames.HEALTH_ACCOUNT,
    //   props: true,
    //   component: () => import(/* webpackChunkName: "health" */ '@/views/health/AccountDetails.vue'),
    //   meta: { footerMenu: 'health' }
    // },
    {
      path: `${prefix}/order/idcard`,
      name: 'poolPasses',
      component: () => import(/* webpackChunkName: "idcard" */ '@/views/idcard/IdCardMain.vue'),
      meta: { footerMenu: 'home' }
    },
    {
      path: `${prefix}/account/invoice/detail/:invoiceId`,
      name: RouterNames.INVOICE_DETAIL,
      props: true,
      component: () => import(/* webpackChunkName: "invoice" */ '@/views/account/invoice/InvoiceDetails.vue'),
      meta: { footerMenu: 'invoice' }
    },
    {
      path: `${prefix}/account/invoice/pay/:invoiceId`,
      name: RouterNames.INVOICE_MAKE_PAYMENT,
      props: true,
      component: () => import(/* webpackChunkName: "invoice" */ '@/views/account/invoice/PayInvoice.vue'),
      meta: { footerMenu: 'invoice' }
    },
    {
      path: `${prefix}/account/invoices`,
      name: RouterNames.INVOICE_LIST,
      props: true,
      component: () => import(/* webpackChunkName: "invoice" */ '@/views/account/invoice/InvoiceList.vue'),
      meta: { footerMenu: 'account' }
    },
    {
      path: `${prefix}/redirect`,
      name: RouterNames.REDIRECT,
      component: () => import(/* webpackChunkName: "account" */ '@/views/Redirect.vue')
    },
    {
      path: `${prefix}/onboarding`,
      name: RouterNames.ONBOARDING_MAIN,
      component: () => import(/* webpackChunkName: "account" */ '@/views/onboarding/OnboardingMain.vue'),
      meta: { footerMenu: 'care' }
    },
    {
      path: `${prefix}/receipt/transaction/:transactionId`,
      name: RouterNames.RECEIPT_TRANSACTION,
      component: () => import(/* webpackChunkName: "account" */ '@/views/receipt/Transaction.vue'),
      meta: { guest: true }
    },
    {
      path: `${prefix}/shop`,
      name: RouterNames.SHOP,
      component: () => import(/* webpackChunkName: "shop" */ '@/views/shop/MainCatalog.vue'),
      meta: { footerMenu: 'shop' }
    },
    {
      path: `${prefix}/shop/accesscontrol`,
      name: RouterNames.SHOP_ACCESS_CONTROL,
      component: () => import(/* webpackChunkName: "shop" */ '@/views/shop/AccessControlItems.vue'),
      meta: { footerMenu: 'shop' }
    },
    // {
    //   path: `${prefix}/shop/items/:category`,
    //   name: RouterNames.SHOP_BY_CATEGORY,
    //   component: () => import(/* webpackChunkName: "shop" */ '@/views/shop/CarePackageItems.vue'),
    //   meta: { footerMenu: 'shop' }
    // },
    {
      path: `${prefix}/shop/packages`,
      name: RouterNames.CARE_PACKAGES,
      component: () => import(/* webpackChunkName: "shop" */ '@/views/shop/CarePackageItems.vue'),
      meta: { footerMenu: 'care' }
    },
    {
      path: `${prefix}/shop/packages/orders`,
      name: RouterNames.CARE_ORDERS,
      component: () => import(/* webpackChunkName: "shop" */ '@/views/shop/CarePackageOrders.vue'),
      meta: { footerMenu: 'care' }
    },
    {
      path: `${prefix}/cart/view`,
      name: RouterNames.SHOP_CART,
      component: () => import(/* webpackChunkName: "shop" */ '@/views/shop/Cart.vue'),
      meta: { footerMenu: 'shop' }
    },
    {
      path: `${prefix}/cart/checkout`,
      name: RouterNames.SHOP_CHECKOUT,
      component: () => import(/* webpackChunkName: "shop" */ '@/views/shop/Checkout.vue'),
      meta: {}
    },
    {
      path: `${prefix}/entrypass`,
      name: RouterNames.ENTRY_PASS,
      component: () => import(/* webpackChunkName: "account" */ '@/views/events/EntryPass.vue'),
      meta: {
        footerMenu: 'home'
      }
    },
    {
      path: `${prefix}/shop/orders`,
      name: RouterNames.SHOP_ORDERS,
      component: () => import(/* webpackChunkName: "shop" */ '@/views/shop/CarePackageOrders.vue'),
      meta: { footerMenu: 'shop' }
    },
    // {
    //   path: '/about',
    //   name: RouterNames.ABOUT,
    //   component: () => import(/* webpackChunkName: "help" */ '@/views/help/About.vue'),
    //   meta: { footerMenu: 'home' }
    // },
    {
      path: `${prefix}/goto`,
      name: 'goto',
      component: () => import(/* webpackChunkName: "help" */ '@/views/help/GoTo.vue'),
      meta: { footerMenu: 'home' }
    },
    {
      path: `${prefix}/sitemap`,
      name: RouterNames.SITEMAP,
      component: () => import(/* webpackChunkName: "help" */ '@/views/Sitemap.vue'),
      meta: { footerMenu: 'home' }
    },
    {
      path: `${prefix}/sitemap/:category`,
      name: RouterNames.SITEMAP_CATEGORY,
      component: () => import(/* webpackChunkName: "help" */ '@/views/Sitemap.vue'),
      meta: { footerMenu: 'home' }
    },
    {
      path: `${prefix}/tbi`,
      name: RouterNames.NOT_IMPLEMENTED,
      component: () => import(/* webpackChunkName: "help" */ '@/views/NotImplemented.vue'),
      meta: {
        backbar: true,
        footerMenu: 'home'
      }
    },
    {
      path: `${prefix}/guide`,
      name: RouterNames.GUIDE,
      component: () => import(/* webpackChunkName: "help" */ '@/views/Guide.vue'),
      meta: { backbar: true, footerMenu: 'none' }
    },
    {
      path: `${prefix}/help`,
      name: RouterNames.HELP,
      component: () => import(/* webpackChunkName: "help" */ '@/views/help/HelpHome.vue'),
      meta: { footerMenu: 'home' }
    },
    {
      path: `${prefix}/reservation`,
      name: RouterNames.RESERVATION,
      component: () => import(/* webpackChunkName: "help" */ '@/views/events/MakeReservation.vue'),
      meta: { anonymous: true }
    },
    // {
    //   path: `${prefix}/reservation/clubhouse`,
    //   name: RouterNames.RESERVE_CLUBHOUSE,
    //   component: () => import(/* webpackChunkName: "help" */ '@/views/events/clubhouse/ReserveClubhouseMain.vue'),
    //   meta: { anonymous: true }
    // },
    {
      path: `${prefix}/reservation/court`,
      name: RouterNames.RESERVE_COURT,
      component: () => import(/* webpackChunkName: "help" */ '@/views/events/court/ReserveCourtMain.vue'),
      meta: { anonymous: true }
    },
    // {
    //   path: `${prefix}/reservation/resource`,
    //   name: RouterNames.RESERVE_RESOURCE,
    //   component: () => import(/* webpackChunkName: "help" */ '@/views/events/place/MakeResourceReservation.vue'),
    //   meta: { anonymous: true }
    // },
    // {
    //   path: `${prefix}/reservation/session`,
    //   name: RouterNames.RESERVE_SESSION,
    //   component: () => import(/* webpackChunkName: "help" */ '@/views/events/session/ReserveSessionMain.vue'),
    //   meta: { anonymous: true }
    // },
    {
      path: `${prefix}/e`,
      name: 'eventListings',
      component: () => import(/* webpackChunkName: "help" */ '@/views/events/EventListings.vue'),
      meta: { anonymous: true }
    },
    {
      path: `${prefix}/e/:eventSlug`,
      name: 'eventTicket',
      component: () => import(/* webpackChunkName: "help" */ '@/views/events/ticket/ReserveTicketMain.vue'),
      meta: { anonymous: true }
    },
    {
      path: '*',
      redirect: '/'
    }
  ]
})

/*
 * Process before each router
 */
router.beforeEach(async(to, from, next): Promise<void> => {
  // next();
  let containsIgnoredRoutes = false
  let match: ExternalUrl | undefined;
  allowedExternalUrls.forEach(externalUrl => {
    if (to.fullPath.indexOf(externalUrl.route) !== -1 || (to.redirectedFrom && to.redirectedFrom.indexOf(externalUrl.route) !== -1)) {
      match = externalUrl
      containsIgnoredRoutes = true
    }
  })
  if (containsIgnoredRoutes) {
    let redirectTo = `https://${WindowLocationUtils.getHost()}`
    if (match && match !== undefined) {
      redirectTo = `${redirectTo}${match.route}`
      match.queryParams.forEach((param) => {
        const value = getParameterByName(param)
        if (value) setCookie(param, value, 600)
      })
    }
    // console.log('router: redirect to ', redirectTo)
    window.location.href = redirectTo
  }

  let permittedAnonymous = false;
  if (/* to.name === 'eventListings' || */ to.name === 'eventTicket' || WindowLocationUtils.getHostname().indexOf('funfangle.live') !== -1) {
    permittedAnonymous = true;
  }

  // debug
  // console.log('route', to, from, next)
  // console.log('location', WindowLocationUtils.getHostname(), window.location)
  //
  // check if a "guest" path
  if (to.matched.some(record => record.meta.guest)) {
    // console.log('router: beforeEach guest ', to)
    // console.log('router: guest...')
    next()
  } else if (permittedAnonymous && to.matched.some(record => record.meta.anonymous)) {
    // console.log('router: beforeEach guest ', to)
    // console.log('router: permittedAnonymous...')
    next()
  } else if (!containsIgnoredRoutes) {
    // console.log('router: !containtsignoredroutes...')
    // check for token (jwt)
    // if not available, then attempt refresh
    // if still not available, then redirect to login
    // otherwise, pass-thru
    let idToken = authStore.userIdToken;
    if (!idToken || idToken === '' || idToken === undefined || idToken === 'undefined' || idToken === 'null') {
      // refresh the token
      await authStore
        .refreshToken()
        .then(async () => {
          // save token
          idToken = authStore.userIdToken;
        })
        .catch(() => {
          // if token refresh was not possible, then so be it. Logic below will redirect to login
        });
      // reload data if needed
      let aoe: string | undefined;
      if (to.query.aoe != null && to.query.aoe !== undefined && typeof to.query.aoe === 'string') {
        aoe = String(to.query.aoe)
      }
      // console.log('router: aoe=', aoe);
      if (to.query.rtn != null && to.query.rtn !== undefined && to.query.rtn !== '' && typeof to.query.rtn === 'string') {
        const rtn = JSON.parse(to.query.rtn);
        // console.log('router: rtn=', rtn);
        navStore.setReturnUrl(rtn);
      }
      if ((aoe !== undefined && typeof aoe === 'string') || (idToken && idToken !== '' && idToken !== undefined && idToken !== 'undefined' && idToken !== 'null')) {
        // console.log('routing: onboarding ', aoe || authStore.username);
        await new StoreUtils().onLoginActions(aoe || authStore.username).catch(() => {
        // await profileStore.fetchAllDetails(authStore.username).catch((err: Error) => {
          // something
        });
        // await profileStore.fetchUser();
        // check for onboarding
        // console.log('routing: onboarding ', profileStore.hasOnboardingRequired, to.name !== RouterNames.ONBOARDING_MAIN);
        if (profileStore.hasOnboardingRequired && to.name !== RouterNames.ONBOARDING_MAIN) {
          // console.log('routing: this.$router.push({ name: this.RouterNames.ONBOARDING_MAIN })');
          next({ name: RouterNames.ONBOARDING_MAIN });
          return;
        }
      }
    }

    // console.log('idToken ', idToken);
    if ((!idToken || idToken === '' || idToken === 'undefined' || idToken === 'null') && !containsIgnoredRoutes) {
      // redirect to login page
      // console.log('router: idToken not found. redirect to auth_login, query r:', to.name);
      next({ name: RouterNames.AUTH_LOGIN, query: { r: to.name } });
    } else {
      // user is logged in
      // so, now get user
      // check if logged in
      const username = authStore.username;
      // console.log('router: username ', username);
      if (!username || username === '') {
        // console.log('router: no username. redirect to auth ');
        next({
          name: RouterNames.AUTH_LOGIN,
          query: { r: to.name }
        });
      }

      // redirect if not an admin of some sort
      // if (profileStore.currUser === undefined || (profileStore.currUser.role !== 'organization-manager' && profileStore.currUser.role !== 'employee')) {
      //   window.location.href = profileStore.domainUrl || 'https://portal.funfangle.com';
      //   AuthCookies.resetCookies();
      //   authStore.init();
      //   return;
      // }

      // console.log('router: get user')
      // let user = JSON.parse(localStorage.getItem('user'));
      if (to.matched.some(record => record.meta.is_admin)) {
        //if (user.is_admin === 1) {
        // page requires admin and user admin so proceed
        // console.log('router.beforeEach next() #1')
        //  next();
        //} else {
        // console.log('router: user is not admin. deny.');
        next({ name: RouterNames.AUTH_DENIED });
        //}
      } else {
        // page does not require admin so proceed
        // console.log('router.beforeEach next() #2');
        next();
      }
    }
  }
  // console.log('routing: router.beforeEach end')
})

/**
 * Hook after each route.
 */
// router.afterEach((to, from, next) => {
//   // console.log('router:afterEach ', to, from)
// })

// helper functions
/* eslint-disable */
function setCookie(cname: string, cvalue: string, exseconds: number) {
  new CookieUtils().setCookie(cname, cvalue, exseconds);
}

function getParameterByName(name: string, url?: string) {
  if (!url) url = window.location.href
  name = name.replace(/[\[\]]/g, '\\$&')
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export default router
