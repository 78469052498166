<template>
  <v-container fluid class="p-0">
    <v-row v-if="(showButtons)">
      <v-col>
        <v-btn color="primary" large block @click.native.prevent="goEntryPass(personId)">Entry Pass</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { RouterUtils } from '@/router/RouterUtils'

// Define the props by using Vue's canonical way.
const EntryControlCardProps = Vue.extend({
  props: {
    'hideButtons': {
      type: Boolean,
      default: false
    }
  },
  mixins: [RouterUtils]
});

@Component({
  components: {
    // mdbBtn,
    // mdbIcon,
    // VBtn
  }
})
export default class EntryControlCard extends EntryControlCardProps {

  get personId () {
      return this.$store.state.currentPersonId
  }
//   get personReservations () {
//       if (this.reservations == null || this.reservations === undefined) {
//         return []
//       }
//       let rs = [];
//       for (let reservation of this.reservations as Booking[]) {
//         if (reservation.authUserId === this.$store.state.currentPersonId ||
//             ((reservation as any).sk !== undefined && (reservation as any).sk.indexOf(this.$store.state.currentPersonId) >= 0))
//         {
//           rs.push(reservation);
//         }
//       }
//       return rs;
//   }
//   // return count of contributors
//   get personReservationCount () {
//       return this.personReservations.length;
//   }
  // return whether to show the buttons or not
  get showButtons () {
    if (
      this.hideButtons === true
    ) {
      return false
    }
    return true
  }
  mounted() {}
}
</script>

<style>
</style>
