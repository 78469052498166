import { EventOccurance } from '@fgl/funfangle-sdk/dist/rest/event';
import { DateTime } from 'luxon';

export class DateUtils {

  public static formatDayHash(dt: DateTime): string {
    return `${dt.year}${dt.month.toString().padStart(2, '0')}${dt.day.toString().padStart(2, '0')}`;
  }

  public static formatEpochStartDate(se: EventOccurance, timeZone = 'America/New_York', locale = 'en-US'): string {
    const startSeconds = se.startSeconds || 0;
    const endSeconds = se.endSeconds || 0;

    const startDT = DateTime.fromSeconds(startSeconds, {})
      .setZone(timeZone || 'America/New_York')
      .setLocale(locale || 'en-US');
    const endDT = DateTime.fromSeconds(endSeconds, {})
      .setZone(timeZone || 'America/New_York')
      .setLocale(locale || 'en-US');

    if (((se.endSeconds || 0) - (se.startSeconds || 0)) > 84600) {
    return `${startDT.weekdayShort}, ${startDT.monthShort} ${startDT.day} - ${endDT.weekdayShort}, ${endDT.monthShort} ${endDT.day}`;
  }

    return `${startDT.weekdayShort}, ${startDT.monthShort} ${startDT.day}`;
  }

  public static formatWeekHash(dt: DateTime): string {
    return `${dt.year}${dt.weekNumber.toString().padStart(2, '0')}`;
  }

  public mbdDateToDateTime(dateValue: string | undefined, timeValue?: string, timeZone = 'America/New_York'): DateTime | undefined {
    const iso = this.mdbDateToISO(dateValue, timeValue);
    if (iso === undefined) return undefined;
    const dt = DateTime.fromISO(iso, { zone: timeZone });
    return dt;
  }

  public mdbDateToISO(dateValue: string | undefined, timeValue?: string): string | undefined {
    if (dateValue === undefined) return undefined;
    const datePieces: string[] = dateValue.split('-');
    if (datePieces.length !== 3) return undefined;
    const year = Number(datePieces[0]);
    const mon = Number(datePieces[1]);
    const day = Number(datePieces[2]);
    let timeStr = timeValue;
    if (timeStr === undefined) timeStr = '00:00:00';
    return `${year}-${String(mon).padStart(2, '0')}-${String(day).padStart(2, '0')}T${timeStr}`;
    // const dt = DateTime.fromISO(`${year}-${String(mon).padStart(2, '0')}-${String(day).padStart(2, '0')}T00:00`, {
    //   zone: this.profileStore.currOrganization.timeZone || 'America/New_York'
    // });
  }
}
