var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.compatibleBrowser
    ? _c(
        "div",
        { class: _vm.appClass, attrs: { "data-app": "" } },
        [
          _c(
            "v-app",
            [
              _c(
                "div",
                { class: _vm.contentClass, attrs: { id: "content" } },
                [_c("router-view")],
                1
              ),
              _vm.showMenus && !_vm.showBackbar
                ? _c(
                    "v-app-bar",
                    {
                      class: _vm.navbarStyle,
                      attrs: {
                        color: "info",
                        absolute: "",
                        fixed: "",
                        "elevate-on-scroll": "",
                        dense: "",
                        dark: "",
                      },
                    },
                    [
                      _c("v-app-bar-nav-icon", {
                        attrs: { "x-large": "" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.handleSideNavToggle.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      }),
                      _c("v-toolbar-title", [_vm._v(_vm._s(_vm.navBarTitle))]),
                      _c("v-spacer"),
                      _vm.showCart()
                        ? [
                            _c(
                              "router-link",
                              {
                                staticClass: "mx-1",
                                attrs: {
                                  to: { name: _vm.RouterNames.SHOP_CART },
                                },
                              },
                              [
                                _c("v-icon", { staticClass: "m-1" }, [
                                  _vm._v(_vm._s(_vm.RouterIcons.SHOP_CART)),
                                ]),
                                _c("v-badge", { attrs: { color: "indigo" } }, [
                                  _c(
                                    "span",
                                    { attrs: { slot: "badge" }, slot: "badge" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.cartStore.totalQuantity)
                                      ),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
              _vm.showMenus && _vm.showBackbar
                ? _c(
                    "v-app-bar",
                    {
                      class: _vm.navbarStyle,
                      attrs: {
                        color: "info",
                        absolute: "",
                        dense: "",
                        dark: "",
                      },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "info", elevation: "0" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.goBack()
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { "x-large": "" } }, [
                            _vm._v("mdi-arrow-left"),
                          ]),
                          _vm._v(" " + _vm._s(_vm.$tc("phrase.back")) + " "),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showAssumedUserWarningBar
                ? _c(
                    "v-app-bar",
                    {
                      class: _vm.disclaimerClass,
                      attrs: {
                        absolute: "",
                        color: "amber lighten-2",
                        dense: "",
                      },
                    },
                    [
                      _c("v-toolbar-title", [
                        _vm._v(
                          "Viewing account: " + _vm._s(_vm.profileStore.email)
                        ),
                      ]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { "x-large": "", icon: "" },
                          nativeOn: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.exitAssumedUser()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-close-circle")])],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.showMenus && !_vm.isSideNavHidden
            ? _c(
                "v-navigation-drawer",
                {
                  attrs: {
                    absolute: "",
                    color: "grey darken-3",
                    dark: "",
                    "mobile-breakpoint": 992,
                    width: 240,
                  },
                  model: {
                    value: _vm.toggleSideNav,
                    callback: function ($$v) {
                      _vm.toggleSideNav = $$v
                    },
                    expression: "toggleSideNav",
                  },
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: { name: _vm.RouterNames.HOME } } },
                    [
                      _c(
                        "div",
                        { staticClass: "mx-5 mt-3" },
                        [
                          _c("v-img", {
                            attrs: { src: _vm.logoSidebar, contain: "" },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("v-divider"),
                  _c(
                    "v-list",
                    { attrs: { dense: "" } },
                    [
                      _c("span", {
                        staticClass: "tour-dock-bottom",
                        attrs: { id: "top-tour" },
                      }),
                      _vm.isHomeEnabled
                        ? _c(
                            "v-list-item",
                            {
                              attrs: { link: "" },
                              on: {
                                click: function ($event) {
                                  _vm.goHome()
                                  _vm.handleSideNavToggle()
                                },
                              },
                            },
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c("v-icon", [
                                    _vm._v(_vm._s(_vm.RouterIcons.HOME)),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(_vm.$tc("nav.home"))),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isFeatureSpendingAccountsEnabled && _vm.isHomeRoute()
                        ? _c(
                            "router-link",
                            {
                              attrs: {
                                to: { name: _vm.RouterNames.BANK_DEPOSITS },
                              },
                            },
                            [
                              _c(
                                "v-list-item",
                                { attrs: { link: "" } },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _c("v-icon", [
                                        _vm._v(
                                          _vm._s(_vm.RouterIcons.BANK_DEPOSITS)
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v(
                                          _vm._s(_vm.$tc("nav.bank.deposit"))
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isFeatureEventReservationsEnabled
                        ? _c(
                            "router-link",
                            {
                              attrs: {
                                to: { name: _vm.RouterNames.RESERVATION },
                              },
                            },
                            [
                              _c(
                                "v-list-item",
                                { attrs: { link: "" } },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _c("v-icon", [
                                        _vm._v(
                                          _vm._s(_vm.RouterIcons.RESERVATION)
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("Make a Reservation"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("v-divider"),
                      _vm.isFeatureSpendingAccountsEnabled
                        ? _c(
                            "router-link",
                            { attrs: { to: { name: _vm.RouterNames.BANK } } },
                            [
                              _c(
                                "v-list-item",
                                { attrs: { link: "" } },
                                [
                                  _c("span", {
                                    staticClass: "tour-dock",
                                    attrs: { id: "bank" },
                                  }),
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _c("v-icon", [
                                        _vm._v(_vm._s(_vm.RouterIcons.BANK)),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v(_vm._s(_vm.$tc("nav.bank"))),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isBankRoute()
                        ? _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list",
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: { name: _vm.RouterNames.BANK },
                                      },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.handleSideNavToggle()
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-list-item",
                                        { attrs: { link: "" } },
                                        [
                                          _c("span", {
                                            staticClass: "tour-dock",
                                            attrs: { id: "deposits" },
                                          }),
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              _c("v-icon", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.RouterIcons
                                                      .BANK_DEPOSITS
                                                  )
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$tc("nav.bank.deposits")
                                                  )
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.allowBankTransfers
                                    ? _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: _vm.RouterNames
                                                .BANK_TRANSFERS,
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-list-item",
                                            { attrs: { link: "" } },
                                            [
                                              _c("span", {
                                                staticClass: "tour-dock",
                                                attrs: { id: "transfers" },
                                              }),
                                              _c(
                                                "v-list-item-icon",
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.RouterIcons
                                                          .BANK_TRANSFERS
                                                      )
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c("v-list-item-title", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$tc(
                                                          "nav.bank.transfers"
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.navStore.displayTransactionItems
                                    ? _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: _vm.RouterNames
                                                .BANK_TRANSACTIONS,
                                            },
                                          },
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.handleSideNavToggle()
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-list-item",
                                            { attrs: { link: "" } },
                                            [
                                              _c("span", {
                                                staticClass: "tour-dock",
                                                attrs: { id: "transactions" },
                                              }),
                                              _c(
                                                "v-list-item-icon",
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.RouterIcons
                                                          .BANK_TRANSACTIONS
                                                      )
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c("v-list-item-title", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$tc(
                                                          "nav.bank.transactions"
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isFeatureCarePackageEnabled
                        ? _c(
                            "router-link",
                            {
                              attrs: {
                                to: { name: _vm.RouterNames.CARE_PACKAGES },
                              },
                            },
                            [
                              _c(
                                "v-list-item",
                                { attrs: { link: "" } },
                                [
                                  _c("span", {
                                    staticClass: "tour-dock",
                                    attrs: { id: "carepackages" },
                                  }),
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _c("v-icon", [
                                        _vm._v(
                                          _vm._s(_vm.RouterIcons.CARE_PACKAGES)
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v(_vm._s(_vm.$tc("nav.care"))),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isFeatureDonateEnabled
                        ? _c(
                            "router-link",
                            { attrs: { to: { name: _vm.RouterNames.DONATE } } },
                            [
                              _c(
                                "v-list-item",
                                { attrs: { link: "" } },
                                [
                                  _c("span", {
                                    staticClass: "tour-dock",
                                    attrs: { id: "carepackages" },
                                  }),
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _c("v-icon", [
                                        _vm._v(_vm._s(_vm.RouterIcons.DONATE)),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("Donate"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isFeatureHealthEnabled
                        ? _c(
                            "router-link",
                            { attrs: { to: { name: _vm.RouterNames.HEALTH } } },
                            [
                              _c(
                                "v-list-item",
                                { attrs: { link: "" } },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _c("v-icon", [
                                        _vm._v(_vm._s(_vm.RouterIcons.HEALTH)),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("Health"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isFeatureEventEntryControlEnabled
                        ? _c(
                            "router-link",
                            {
                              attrs: {
                                to: { name: _vm.RouterNames.ENTRY_PASS },
                              },
                            },
                            [
                              _c(
                                "v-list-item",
                                { attrs: { link: "" } },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-card-account-details"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("Entry Pass"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("v-divider"),
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.$tc("sidenav.help.title"))),
                                ]),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.isLoggedIn()
                        ? _c(
                            "router-link",
                            {
                              attrs: { to: { name: _vm.RouterNames.ACCOUNT } },
                            },
                            [
                              _c(
                                "v-list-item",
                                { attrs: { link: "" } },
                                [
                                  _c("span", {
                                    staticClass: "tour-dock",
                                    attrs: { id: "account" },
                                  }),
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _c("v-icon", [
                                        _vm._v(_vm._s(_vm.RouterIcons.ACCOUNT)),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v(_vm._s(_vm.$tc("nav.account"))),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isAccountRoute()
                        ? _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list",
                                [
                                  _vm.isAccountCredentialsRoute()
                                    ? _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: _vm.RouterNames
                                                .ACCOUNT_CREDENTIALS,
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-list-item",
                                            { attrs: { link: "" } },
                                            [
                                              _c("span", {
                                                staticClass: "tour-dock",
                                                attrs: { id: "security" },
                                              }),
                                              _c(
                                                "v-list-item-icon",
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.RouterIcons
                                                          .ACCOUNT_CREDENTIALS
                                                      )
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c("v-list-item-title", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$tc(
                                                          "nav.account.credentials"
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.isFeatureAttendanceEnabled
                                    ? _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: _vm.RouterNames
                                                .ACCOUNT_PAYMENTMETHODS,
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-list-item",
                                            { attrs: { link: "" } },
                                            [
                                              _c("span", {
                                                staticClass: "tour-dock",
                                                attrs: { id: "payments" },
                                              }),
                                              _c(
                                                "v-list-item-icon",
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.RouterIcons
                                                          .ACCOUNT_PAYMENTMETHODS
                                                      )
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c("v-list-item-title", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$tc(
                                                          "nav.account.paymentMethods"
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.isLoggedIn() &&
                                  _vm.isAccountLodgingsRoute()
                                    ? _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: _vm.RouterNames
                                                .ACCOUNT_LODGING_STAYS,
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-list-item",
                                            { attrs: { link: "" } },
                                            [
                                              _c(
                                                "v-list-item-icon",
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.RouterIcons
                                                          .ACCOUNT_LODGING_STAYS
                                                      )
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c("v-list-item-title", [
                                                    _vm._v(
                                                      _vm._s(_vm.lingo.renters)
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.isLoggedIn() &&
                                  _vm.isAccountLodgingsRoute()
                                    ? _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: _vm.RouterNames
                                                .ACCOUNT_LODGING_UNITS,
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-list-item",
                                            { attrs: { link: "" } },
                                            [
                                              _c(
                                                "v-list-item-icon",
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.RouterIcons
                                                          .ACCOUNT_LODGING_UNITS
                                                      )
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c("v-list-item-title", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$tc("Properties")
                                                      )
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.isFeatureSpendingAccountsEnabled
                                    ? _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: _vm.RouterNames
                                                .ACCOUNT_ACTIVITY,
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-list-item",
                                            { attrs: { link: "" } },
                                            [
                                              _c(
                                                "v-list-item-icon",
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.RouterIcons
                                                          .ACCOUNT_ACTIVITY
                                                      )
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c("v-list-item-title", [
                                                    _vm._v("Activity"),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isLoggedIn() && _vm.isFeatureCarePackageEnabled
                        ? _c(
                            "router-link",
                            {
                              attrs: {
                                to: { name: _vm.RouterNames.SHOP_CART },
                              },
                            },
                            [
                              _c(
                                "v-list-item",
                                { attrs: { link: "" } },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _c("v-icon", [
                                        _vm._v(
                                          _vm._s(_vm.RouterIcons.SHOP_CART)
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c(
                                        "v-list-item-title",
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$tc("nav.shop.cart")) +
                                              " "
                                          ),
                                          _vm.cartStore.totalQuantity > 0
                                            ? [
                                                _c(
                                                  "v-badge",
                                                  {
                                                    attrs: { color: "purple" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        attrs: {
                                                          slot: "badge",
                                                        },
                                                        slot: "badge",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.cartStore
                                                                .totalQuantity
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isLoggedIn() && _vm.isFeatureEventReservationsEnabled
                        ? _c(
                            "router-link",
                            {
                              attrs: {
                                to: { name: _vm.RouterNames.ACCOUNT_BOOKINGS },
                              },
                            },
                            [
                              _c(
                                "v-list-item",
                                { attrs: { link: "" } },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _c("v-icon", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.RouterIcons.ACCOUNT_BOOKINGS
                                          )
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("Reservations"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._l(_vm.orgLinks, function (link, index) {
                        return _c(
                          "a",
                          {
                            key: index,
                            attrs: { href: link.link, target: "_blank" },
                          },
                          [
                            _c(
                              "v-list-item",
                              { attrs: { link: "" } },
                              [
                                _c(
                                  "v-list-item-icon",
                                  [
                                    _c("v-icon", [
                                      _vm._v(_vm._s(link.iconName)),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v(_vm._s(link.name)),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      _vm.isAdmin()
                        ? _c(
                            "a",
                            {
                              attrs: { href: "https://console.funfangle.com" },
                            },
                            [
                              _c(
                                "v-list-item",
                                { attrs: { link: "" } },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [_c("v-icon", [_vm._v("mdi-web")])],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("Admin Console"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.isLoggedIn()
                        ? _c(
                            "router-link",
                            {
                              attrs: {
                                to: { name: _vm.RouterNames.AUTH_LOGIN },
                              },
                            },
                            [
                              _c(
                                "v-list-item",
                                { attrs: { link: "" } },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _c("v-icon", [
                                        _vm._v(
                                          _vm._s(_vm.RouterIcons.AUTH_LOGIN)
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v(_vm._s(_vm.$tc("nav.signin"))),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isLoggedIn()
                        ? _c(
                            "router-link",
                            {
                              attrs: {
                                to: { name: _vm.RouterNames.AUTH_LOGOUT },
                              },
                            },
                            [
                              _c(
                                "v-list-item",
                                { attrs: { link: "" } },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _c("v-icon", [
                                        _vm._v(
                                          _vm._s(_vm.RouterIcons.AUTH_LOGOUT)
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v(_vm._s(_vm.$tc("nav.signout"))),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.showMenus && _vm.$route.meta.footerMenu === "home"
            ? _c("home-footer-menu", { ref: "homefooter" })
            : _vm._e(),
          _vm.showMenus && _vm.$route.meta.footerMenu === "account"
            ? _c("account-footer-menu", { ref: "accountfooter" })
            : _vm._e(),
          _vm.showMenus && _vm.$route.meta.footerMenu === "bank"
            ? _c("bank-footer-menu", { ref: "bankfooter" })
            : _vm._e(),
          _vm.showMenus && _vm.$route.meta.footerMenu === "care"
            ? _c("care-package-footer-menu", { ref: "carefooter" })
            : _vm._e(),
          _vm.showMenus && _vm.$route.meta.footerMenu === "event"
            ? _c("event-footer-menu", { ref: "eventfooter" })
            : _vm._e(),
          _vm.showMenus && _vm.$route.meta.footerMenu === "invoice"
            ? _c("invoice-footer-menu", { ref: "invoicefooter" })
            : _vm._e(),
          _vm.showMenus && _vm.$route.meta.footerMenu === "lodging"
            ? _c("lodging-footer-menu", { ref: "lodgingfooter" })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }