<template>
  <section v-if="isCard">
    <v-card
      no-body
      style="margin: 6px;"
      @click="togglePerson(person)"
      v-bind:key="person.userId"
      lg="6"
      md="6"
      sm="6"
      class="mb-lg-1 m-2 person-card"
    >
    <v-row>
      <v-col>
        <v-img
          v-if="displayPhotoOption()"
          slot="aside"
          :src="person.imageUrl70"
          contain
          height="70"
          alt="Photo"
        />
      </v-col>
      <v-col>
        <h5 class="mb-0">{{ person.firstName }}</h5>
        <v-container class="pl-0" v-if="isFeatureSpendingAccountsEnabled">
          <v-row>
            <v-col>
              {{ $n(getUserContributionByCamper(person.userId) || 0, 'currency') }}
              <template
                v-if="getAuthUserScheduledAllocationsBalance(person.userId) > 0"
              >
                <span style="color: #bebebe">
                  plus
                  <a
                    @click="goBankAccountPage(currentPersonId)"
                  >{{ $n(getAuthUserScheduledAllocationsBalance(person.userId), 'currency') }}</a> future
                </span>
              </template>
            </v-col>
          </v-row>
      </v-container>
      </v-col>
    </v-row>


    </v-card>
  </section>
  <section v-else>
    <a @click="togglePerson(person)" v-bind:key="person.userId">
      <v-container style="border:1px solid #F0F8FF" class="p-2">
        <v-row>
          <v-col>
            <v-img
              v-if="displayPhotoOption()"
              slot="aside"
              :src="person.imageUrl70"
              contain
              height="70"
              alt="Photo"
            />
          </v-col>
          <v-col>
            <v-container>
              <v-row>
                <h5>{{ person.firstName }}</h5>
              </v-row>
              <v-row v-if="isFeatureSpendingAccountsEnabled">
                <v-col>
                  {{ $n(getUserContributionByCamper(person.userId) || 0, 'currency') }}
                  <template
                    v-if="getAuthUserScheduledAllocationsBalance(person.userId) > 0"
                  >
                    <span style="color: #bebebe">
                      plus
                      <a
                        @click="goBankAccountPage(currentPersonId)"
                      >{{ $n(getAuthUserScheduledAllocationsBalance(person.userId), 'currency') }}</a> future
                    </span>
                  </template>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>


      </v-container>
    </a>
  </section>
</template>

<script lang="ts">
import { Vue, Component, Emit } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { BankModule, NavModule, ProfileModule } from "@/store";
import { User } from "@fgl/funfangle-sdk/dist/rest/profile";

// Define the props by using Vue's canonical way.
const AuthorizedPersonMinimizedProps = Vue.extend({
  props: {
    person: Object,
    isCard: {
      type: Boolean,
      default: true
    }
  },
  mixins: []
});

@Component({
  components: {}
})
export default class AuthorizedPersonMinimized extends AuthorizedPersonMinimizedProps {
  bankStore: BankModule = getModule(BankModule, this.$store);
  navStore: NavModule = getModule(NavModule, this.$store);
  profileStore: ProfileModule = getModule(ProfileModule, this.$store);

  // computed: {
  displayPhotoOption() {
    // see if the 'route' is configured to show the backbar
    if (
      this.profileStore.currOrganization !== undefined &&
      this.profileStore.currOrganization !== undefined &&
      this.profileStore.currOrganization.portalSettings !== undefined &&
      this.profileStore.currOrganization.portalSettings.displayPhotoOption === false
    ) {
      return false;
    }
    return true;
  }

  get currentPersonId(): string {
    return this.navStore.selectedUserId;
  }
  get isFeatureSpendingAccountsEnabled(): boolean {
    return this.navStore.isFeatureSpendingAccountsEnabled;
  }

  getUserContributionByCamper(userId: string): number {
    if (!this.bankStore.userContributionByCamper || this.bankStore.userContributionByCamper === undefined) return 0;
    return this.bankStore.userContributionByCamper[userId] || 0;
  }

  getAuthUserScheduledAllocationsBalance(authUserId: string) {
    // get scheduled allocations for user
    let scheduledAllocations = this.bankStore.scheduledAllocations.filter((item: any) => {
      return item.authUserId === authUserId;
    });
    let bal = 0.0;
    for (var i in scheduledAllocations) {
      var allocation = scheduledAllocations[i];
      if (allocation.status === "pending") {
        bal = bal + allocation.amount;
      }
      if (allocation.status === "reversed") {
        bal = bal - allocation.amount;
      }
    }
    return bal;
  }

  @Emit('togglePerson')
  togglePerson(person: User) {
    // console.log('ap-minimized togglePerson ', person);
    return person
  }

}
</script>

<style scoped>
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
</style>
