<template>
  <div :class="appClass" v-if="compatibleBrowser" data-app>
    <v-app>
      <!-- Content -->
      <div :class="contentClass" id="content">
        <router-view></router-view>
      </div>
      <!-- Navbar -->
      <v-app-bar
        v-if="showMenus && !showBackbar"
        color="info"
        absolute
        fixed
        elevate-on-scroll
        dense
        dark
        :class="navbarStyle"
      >
        <v-app-bar-nav-icon @click.native="handleSideNavToggle" x-large></v-app-bar-nav-icon>
        <v-toolbar-title>{{ navBarTitle }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <template v-if="showCart()">
          <router-link class="mx-1" :to="{ name: RouterNames.SHOP_CART }">
            <v-icon class="m-1">{{ RouterIcons.SHOP_CART }}</v-icon>
            <v-badge color="indigo">
              <span slot="badge">{{ cartStore.totalQuantity }}</span>
            </v-badge>
          </router-link>
        </template>
      </v-app-bar>
      <!--/.Navbar-->
      <!--Backbar-->
      <v-app-bar
        v-if="showMenus && showBackbar"
        color="info"
        absolute
        dense
        dark
        :class="navbarStyle"
      >
        <v-btn color="info" @click.native="goBack()" elevation="0">
          <v-icon x-large>mdi-arrow-left</v-icon>
          {{ $tc('phrase.back') }}
        </v-btn>
      </v-app-bar>
      <!--/.Navbar-->
      <!-- Warning bar -->
      <v-app-bar
        :class="disclaimerClass"
        absolute
        v-if="showAssumedUserWarningBar"
        color="amber lighten-2"
        dense
      >
        <v-toolbar-title>Viewing account: {{ profileStore.email }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn x-large icon @click.native.stop="exitAssumedUser()">
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </v-app-bar>
    </v-app>

    <!--SideNav-->
    <v-navigation-drawer
      v-model="toggleSideNav"
      v-if="showMenus && !isSideNavHidden"
      absolute
      color="grey darken-3"
      dark
      :mobile-breakpoint="992"
      :width="240"
    >
      <!-- logo links to home -->
      <router-link :to="{ name: RouterNames.HOME }">
        <div class="mx-5 mt-3">
          <v-img :src="logoSidebar" contain></v-img>
        </div>
      </router-link>
      <!-- <li
        v-if="(navStore.urlFacebook !== '') || (navStore.urlPinterest === '') || (navStore.urlGooglePlus === '') || (navStore.urlInstagram === '') || (navStore.urlTwitter === '')"
      >
        <ul class="social">
          <li v-if="navStore.urlFacebook !== ''">
            <a :href="navStore.urlFacebook" class="icons-sm fb-ic">
              <v-icon icon="facebook" />
            </a>
          </li>
          <li v-if="navStore.urlPinterest !== ''">
            <a :href="navStore.urlPinterest" class="icons-sm pin-ic">
              <v-icon icon="pinterest" />
            </a>
          </li>
          <li v-if="navStore.urlGooglePlus !== ''">
            <a :href="navStore.urlGooglePlus" class="icons-sm gplus-ic">
              <v-icon icon="google-plus" />
            </a>
          </li>
          <li v-if="navStore.urlTwitter !== ''">
            <a :href="navStore.urlTwitter" class="icons-sm tw-ic">
              <v-icon icon="twitter" />
            </a>
          </li>
          <li v-if="navStore.urlInstagram !== ''">
            <a :href="navStore.urlInstagram" class="icons-sm tw-ic">
              <v-icon icon="instagram" />
            </a>
          </li>
        </ul>
      </li> -->
      <v-divider></v-divider>
      <v-list dense>
        <!-- home -->
        <span class="tour-dock-bottom" id="top-tour"></span>
        <v-list-item v-if="isHomeEnabled" link @click="goHome(); handleSideNavToggle()">
          <v-list-item-icon>
            <v-icon>{{ RouterIcons.HOME }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $tc('nav.home') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- bank  -->
        <router-link
          :to="{ name: RouterNames.BANK_DEPOSITS }"
          v-if="isFeatureSpendingAccountsEnabled && isHomeRoute()"
        >
          <v-list-item link >
            <v-list-item-icon>
              <v-icon>{{ RouterIcons.BANK_DEPOSITS }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $tc('nav.bank.deposit') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>
<!--
        <span class="tour-dock-bottom" id="top-tour"></span>
          <li v-if="isFeatureDonateEnabled && isHomeRoute()">
            <span class="tour-dock" id="donate"></span>
            <router-link
              :to="{ name: RouterNames.DONATE }"
              class="collapsible-header ripple-parent menu-text"
              @click.native="handleSideNavToggle()"
            >
              <v-icon :icon="RouterIcons.DONATE" />
              Donate
            </router-link>
          </li>
          <li v-if="isFeatureSessionRegistrationEnabled && isHomeRoute()">
            <span class="tour-dock" id="register"></span>
            <router-link
              :to="{ name: RouterNames.RESERVATION }"
              class="collapsible-header ripple-parent menu-text"
              @click.native="handleSideNavToggle()"
            >
              <v-icon :icon="RouterIcons.ATTENDANCE" />Register
            </router-link>
          </li>
          -->
        <router-link
          :to="{ name: RouterNames.RESERVATION }"
          v-if="isFeatureEventReservationsEnabled"
        >
          <v-list-item link >
            <v-list-item-icon>
              <v-icon>{{ RouterIcons.RESERVATION }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Make a Reservation</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>
        <!--
          <li v-if="isFeatureEventEntryControlEnabled">
            <router-link
              :to="{ name: RouterNames.ENTRY_PASS }"
              class="collapsible-header ripple-parent menu-text"
              @click.native="handleSideNavToggle()"
            >
              <v-icon icon="address-card" />Entry Pass
            </router-link>
          </li>
          <li v-if="isFeaturePlaceOrderEnabled">
            <router-link
              :to="{ name: 'poolPasses' }"
              class="collapsible-header ripple-parent menu-text"
              @click.native="handleSideNavToggle()"
            >
              <v-icon icon="id-card" />Place Order
            </router-link>
          </li>
          <li v-if="isFeatureIdCardEnabled">
            <router-link
              :to="{ name: 'poolPasses' }"
              class="collapsible-header ripple-parent menu-text"
              @click.native="handleSideNavToggle()"
            >
              <v-icon icon="id-card" />Pool Passes
            </router-link>
          </li>
        </ul>
      </li>
      <li>
        <ul class="collapsible">
          <li v-if="isFeatureSpendingAccountsEnabled" class>
    -->

        <v-divider></v-divider>

        <router-link
          :to="{ name: RouterNames.BANK }"
          v-if="isFeatureSpendingAccountsEnabled"
        >
          <v-list-item link >
            <span class="tour-dock" id="bank"></span>
            <v-list-item-icon>
              <v-icon>{{ RouterIcons.BANK }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $tc('nav.bank') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>
        <!-- bank submenu -->
        <v-list-item  v-if="isBankRoute()">
          <v-list>
            <!-- deposits -->
            <router-link
              :to="{ name: RouterNames.BANK }"
              @click.native="handleSideNavToggle()"
            >
              <v-list-item link >
                <span class="tour-dock" id="deposits"></span>
                <v-list-item-icon>
                  <v-icon>{{ RouterIcons.BANK_DEPOSITS }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $tc('nav.bank.deposits') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </router-link>
            <!-- transfers -->
            <router-link
              :to="{ name: RouterNames.BANK_TRANSFERS }"
              v-if="allowBankTransfers"
            >
              <v-list-item link >
                <span class="tour-dock" id="transfers"></span>
                <v-list-item-icon>
                  <v-icon>{{ RouterIcons.BANK_TRANSFERS }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $tc('nav.bank.transfers') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </router-link>
            <!-- transactions -->
            <router-link
              :to="{ name: RouterNames.BANK_TRANSACTIONS }"
              v-if="navStore.displayTransactionItems"
              @click.native="handleSideNavToggle()"
            >
              <v-list-item link >
                <span class="tour-dock" id="transactions"></span>
                <v-list-item-icon>
                  <v-icon>{{ RouterIcons.BANK_TRANSACTIONS }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $tc('nav.bank.transactions') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </router-link>
          </v-list>
          <!-- end bank submenu -->
        </v-list-item>
        <!-- Care packages -->
        <!-- <v-list-item v-if="isFeatureCarePackageEnabled">
          <v-list> -->
        <router-link
          :to="{ name: RouterNames.CARE_PACKAGES }"
          v-if="isFeatureCarePackageEnabled"
        >
          <v-list-item link >
            <span class="tour-dock" id="carepackages"></span>
            <v-list-item-icon>
              <v-icon>{{ RouterIcons.CARE_PACKAGES }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $tc('nav.care') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>
            <!-- </v-list> -->
            <!-- TODO: CareOrders?? -->
            <!-- <ul class="side-nav-submenu" v-if="isCarePackageRoute()">
              <li>
                <router-link
                  :to="{ name: RouterNames.CARE_ORDERS }"
                  @click.native="handleSideNavToggle()"
                >
                  <v-icon :icon="RouterIcons.CARE_ORDERS" />
                  {{ $tc('nav.care.orders') }}
                </router-link>
              </li>
            </ul> -->

            <!-- end care-package submenu -->
        <!-- </v-list-item> -->
        <!-- Donate -->
        <router-link
          :to="{ name: RouterNames.DONATE }"
          v-if="isFeatureDonateEnabled"
        >
          <v-list-item link >
            <span class="tour-dock" id="carepackages"></span>
            <v-list-item-icon>
              <v-icon>{{ RouterIcons.DONATE }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Donate</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>
        <!-- attendance -->
        <!-- <v-list-item v-if="isFeatureAttendanceEnabled">
          <v-list>
        <router-link
          :to="{ name: RouterNames.ATTENDANCE_DOWNLOAD }"
          v-if="isFeatureAttendanceEnabled"
        >
          <v-list-item link >
            <v-list-item-icon>
              <v-icon>{{ RouterIcons.ATTENDANCE }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $tc('nav.attendance') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>
          </v-list>
        </v-list-item> -->
        <!-- attendance submenu -->
        <!-- <v-list-item v-if="isAttendanceRoute() && isFeatureAttendanceEnabled">
          <v-list>
            <router-link
              :to="{ name: RouterNames.ATTENDANCE_DOWNLOAD }"
            >
              <v-list-item link >
                <v-list-item-icon>
                  <v-icon>{{ RouterIcons.ATTENDANCE_DOWNLOAD }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $tc('nav.attendance.download') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </router-link>
          </v-list>
        </v-list-item> -->
        <!-- reservations -->
        <router-link
          :to="{ name: RouterNames.HEALTH }"
          v-if="isFeatureHealthEnabled"
        >
          <v-list-item link >
            <v-list-item-icon>
              <v-icon>{{ RouterIcons.HEALTH }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Health</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>

        <!-- entry pass -->
        <router-link
          :to="{ name: RouterNames.ENTRY_PASS }"
          v-if="isFeatureEventEntryControlEnabled"
        >
          <v-list-item link >
            <v-list-item-icon>
              <v-icon>mdi-card-account-details</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Entry Pass</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>
        <!-- Place Order -->
        <!-- TODO: Make it work!! -->
        <!-- <router-link
          :to="{ name: 'poolPasses' }"
          v-if="isFeaturePlaceOrderEnabled"
        >
          <v-list-item link >
            <v-list-item-icon>
              <v-icon>mdi-card-account-details</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Place Order</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link> -->
        <!-- ID card -->
        <!-- TODO: Make it work!! -->
        <!-- <router-link
          :to="{ name: 'poolPasses' }"
          v-if="isFeatureIdCardEnabled"
        >
          <v-list-item link >
            <v-list-item-icon>
              <v-icon>mdi-card-account-details</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Pool Passes</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link> -->

        <!-- Menu divider -->
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title><strong>{{ $tc('sidenav.help.title') }}</strong></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- Account -->
        <router-link
          :to="{ name: RouterNames.ACCOUNT }"
          v-if="isLoggedIn()"
        >
          <v-list-item link >
            <span class="tour-dock" id="account"></span>
            <v-list-item-icon>
              <v-icon>{{ RouterIcons.ACCOUNT }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $tc('nav.account') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>
        <!-- account submenu -->
        <v-list-item v-if="isAccountRoute()">
          <v-list >
            <!-- credentials -->
            <router-link
              :to="{ name: RouterNames.ACCOUNT_CREDENTIALS }"
              v-if="isAccountCredentialsRoute()"
            >
              <v-list-item link >
                <span class="tour-dock" id="security"></span>
                <v-list-item-icon>
                  <v-icon>{{ RouterIcons.ACCOUNT_CREDENTIALS }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $tc('nav.account.credentials') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </router-link>
            <!-- payment methods -->
            <router-link
              :to="{ name: RouterNames.ACCOUNT_PAYMENTMETHODS }"
              v-if="isFeatureAttendanceEnabled"
            >
              <v-list-item link >
                <span class="tour-dock" id="payments"></span>
                <v-list-item-icon>
                  <v-icon>{{ RouterIcons.ACCOUNT_PAYMENTMETHODS }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $tc('nav.account.paymentMethods') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </router-link>
            <!-- lodging stays -->
            <router-link
              :to="{ name: RouterNames.ACCOUNT_LODGING_STAYS }"
              v-if="isLoggedIn() && (isAccountLodgingsRoute())"
            >
              <v-list-item link >
                <v-list-item-icon>
                  <v-icon>{{ RouterIcons.ACCOUNT_LODGING_STAYS }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ lingo.renters }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </router-link>
            <!-- lodging units / properties -->
            <router-link
              :to="{ name: RouterNames.ACCOUNT_LODGING_UNITS }"
              v-if="isLoggedIn() && (isAccountLodgingsRoute())"
            >
              <v-list-item link >
                <v-list-item-icon>
                  <v-icon>{{ RouterIcons.ACCOUNT_LODGING_UNITS }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $tc('Properties') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </router-link>
            <!-- account activity -->
            <router-link
              :to="{ name: RouterNames.ACCOUNT_ACTIVITY }"
              v-if="isFeatureSpendingAccountsEnabled"
            >
              <v-list-item link >
                <v-list-item-icon>
                  <v-icon>{{ RouterIcons.ACCOUNT_ACTIVITY }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Activity</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </router-link>
          </v-list>
          <!-- end account submenu -->
        </v-list-item>
        <!-- cart -->
        <router-link
          :to="{ name: RouterNames.SHOP_CART }"
          v-if="isLoggedIn() && isFeatureCarePackageEnabled"
        >
          <v-list-item link >
            <v-list-item-icon>
              <v-icon>{{ RouterIcons.SHOP_CART }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $tc('nav.shop.cart') }}
                <template v-if="cartStore.totalQuantity > 0">
                  <v-badge color="purple">
                    <span slot="badge">
                      {{ cartStore.totalQuantity }}
                    </span>
                  </v-badge>
                </template>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>
        <!-- Reservations -->
        <router-link
          :to="{ name: RouterNames.ACCOUNT_BOOKINGS }"
          v-if="isLoggedIn() && isFeatureEventReservationsEnabled"
        >
          <v-list-item link >
            <v-list-item-icon>
              <v-icon>{{ RouterIcons.ACCOUNT_BOOKINGS }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Reservations</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>

        <!-- <router-link
          :to="{ name: RouterNames.ACCOUNT_ACTIVITY }"
          v-if="true"
         >
              <v-list-item link >
                <v-list-item-icon>
                  <v-icon>{{ RouterIcons.ACCOUNT_ACTIVITY }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Activity</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </router-link>
          </v-list>        org links -->
        <a v-for="(link, index) in orgLinks" :href="link.link" target="_blank" :key="index">
          <v-list-item link >
            <v-list-item-icon>
              <v-icon>{{ link.iconName }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ link.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </a>
        <!-- admin -->
        <a
          href="https://console.funfangle.com"
          v-if="isAdmin()"
        >
          <v-list-item link >
            <v-list-item-icon>
              <v-icon>mdi-web</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Admin Console</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </a>
        <!-- login -->
        <router-link
          :to="{ name: RouterNames.AUTH_LOGIN }"
          v-if="!isLoggedIn()"
        >
          <v-list-item link >
            <v-list-item-icon>
              <v-icon>{{ RouterIcons.AUTH_LOGIN }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $tc('nav.signin') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>
        <!-- logout -->
        <router-link
          :to="{ name: RouterNames.AUTH_LOGOUT }"
          v-if="isLoggedIn()"
        >
          <v-list-item link >
            <v-list-item-icon>
              <v-icon>{{ RouterIcons.AUTH_LOGOUT }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $tc('nav.signout') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>

      </v-list>

    </v-navigation-drawer>
    <!-- /SideNav -->
    <!-- Footer -->
    <!-- TODO Add 40px margin-bottom when footer is shown -->
    <home-footer-menu v-if="showMenus && $route.meta.footerMenu === 'home'" ref="homefooter" />
    <account-footer-menu v-if="showMenus && $route.meta.footerMenu === 'account'" ref="accountfooter" />
    <bank-footer-menu v-if="showMenus && $route.meta.footerMenu === 'bank'" ref="bankfooter" />
    <care-package-footer-menu
      v-if="showMenus && $route.meta.footerMenu === 'care'"
      ref="carefooter"
    />
    <event-footer-menu v-if="showMenus && $route.meta.footerMenu === 'event'" ref="eventfooter" />
    <invoice-footer-menu v-if="showMenus && $route.meta.footerMenu === 'invoice'" ref="invoicefooter" />
    <lodging-footer-menu v-if="showMenus && $route.meta.footerMenu === 'lodging'" ref="lodgingfooter" />
    <!-- /Footer -->
  </div>
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator';
import { MediaQuery as MQ } from "@/MediaQuery";
import { getModule } from "vuex-module-decorators";
import { AuthModule } from '@/shared/auth/auth-cognito';
import { CartModule, LingoModule, ProfileModule, NavModule, StatusModule, StoreUtils } from "@/store";
import { Watch } from "vue-property-decorator";
import HomeFooterMenu from "@/components/footer/HomeFooter.vue";
import AccountFooterMenu from "@/components/footer/AccountFooter.vue";
import BankFooterMenu from "@/components/footer/BankFooter.vue";
import CarePackageFooterMenu from "@/components/footer/CarePackageFooter.vue";
import EventFooterMenu from "@/components/footer/EventFooter.vue";
import InvoiceFooterMenu from "@/components/footer/InvoiceFooter.vue";
import LodgingFooterMenu from "@/components/footer/LodgingFooter.vue";
import { RouterIcons } from "@/router/RouterIcons";
import { RouterNames } from "@/router/RouterNames";
import { RouterUtils } from "@/router/RouterUtils";
import { OrganizationLink } from '@fgl/funfangle-sdk/dist/rest/profile';

@Component({
  components: {
    // mdbSideNav,
    // mdbIcon,
    HomeFooterMenu,
    AccountFooterMenu,
    BankFooterMenu,
    CarePackageFooterMenu,
    EventFooterMenu,
    InvoiceFooterMenu,
    LodgingFooterMenu
  }
})
export default class WebApp extends Mixins(RouterUtils) {
  authStore: AuthModule = getModule(AuthModule, this.$store);
  cartStore: CartModule = getModule(CartModule, this.$store);
  navStore: NavModule = getModule(NavModule, this.$store);
  lingo: LingoModule = getModule(LingoModule, this.$store);
  profileStore: ProfileModule = getModule(ProfileModule, this.$store);
  statusStore: StatusModule = getModule(StatusModule, this.$store);
  compatibleBrowser = true;
  // RouterIcons: RouterIcons;
  // RouterNames: RouterNames;
  RouterIcons = RouterIcons;
  RouterNames = RouterNames;
  showMenus = false;
  showAssumedUserWarningBar = false;
  toggleSideNav = false;
  appClass = "app-nav-hidden";
  contentClass = "content-no-topbar";
  disclaimerClass = "no-topbar";
  navbarStyle = '';
  isLoading = true;

  //     ...mapGetters("cognito", [
  //       "getJWT",
  //       "getTokens",
  //       "getRedirectAfterLogin"
  //     ]),
  //     ...mapGetters("bank", [
  //       "getOrgLinks"
  //     ]),
  //     ...mapActions("bank", [
  //       "setTourViewed"
  //     ]),
  //     ...mapActions("cognito", ["getCurrentUser", "refreshToken"]),
  //     ...mapMutations(["setCurrentOrgId"]),
  //     ...mapMutations("cognito", ["setJWT", "initAuthStore"]),
  //     ...mapMutations("user", [
  //       "initUser",
  //       "setViewedTutorial",
  //       "setEmail",
  //       "setIsParent2"
  //     ]),
  // handle the "Back" link being clicked
  // handlePrevStep(index: number): void {
  //   let step = this.$tours["theTour"].steps[index - 1];
  //   if (step.route) {
  //     this.$router.push({
  //       name: step.route
  //     });
  //   }
  //   // give routing & side menu enough time to readjust
  //   setTimeout(() => {
  //     this.$tours["theTour"].previousStep();
  //   }, 100);
  // }
  // handleNextStep(index: number): void {
  //   let step = this.$tours["theTour"].steps[index + 1];
  //   if (step.route) {
  //     this.$router.push({
  //       name: step.route
  //     });
  //   }
  //   // give routing & side menu enough time to readjust
  //   setTimeout(() => {
  //     this.$tours["theTour"].nextStep();
  //   }, 100);
  // }
  // async handleFinishTour(): Promise<void> {
  //   this.profileStore.setViewedTutorial(true);
  //   await this.setTourViewed();
  //   this.$tours["theTour"].stop();
  //   this.goHome();
  // }
  // launchTour(): void {
  //   this.goHome();
  //   this.$tours["theTour"].start();
  // }

  exitAssumedUser(): void {
    const utils = new StoreUtils();
    utils.exitAssumedUser();
  }

  goBack(): void {
    var alreadyBackOne = false;
    var item = this.navStore.popBackbarStack();
    if (item === undefined) return;
    // TODO: create type for response
    item.then((response: any) => {
      // console.log("goback", item, response);
      // go to the route name
      if (response !== undefined) {
        this.$router.replace({
          name: response.name,
          params: response.params,
          query: response.query
        });
        alreadyBackOne = true;
      }
      // otherwise, just go back once in historys
      else if (!alreadyBackOne && this.$route.name !== "home") {
        this.$router.go(-1);
      }
    });
  }
  // handle the sidenav being toggled so it appears/disappears
  handleSideNavToggle(): void {
    this.toggleSideNav = !this.toggleSideNav;
    this.appClass = this.toggleSideNav && MQ.mdOrBigger ? "app-nav-visible" : "app-nav-hidden";
  }
  /**
   * Is this function dead code?
   * How is it called after a user logs in?
   * Maybe it's for a returning user when they are already logged in and credentials cached...
   * This dupliates code in Login.vue
   */
  // async initializeStoreData(email: string, isParent2: boolean): Promise<void> {
  //   this.profileStore.setEmail(email);
  //   this.profileStore.setIsParent2(isParent2);
  //   await this.fetchOrganizations({ email, isParent2 });
  //   // can pass actual organizationId when org is selected
  //   await this.profileStore.fetchUserDetails();
  //   // initialize org
  //   // this.setCurrentOrgId(this.profileStore.organizationId)
  //   let promises = [];

  //   this.fetchOrganization({
  //     organizationId: this.profileStore.organizationId
  //   });
  //   this.fetchGeneralLingo({ orgType: "camp" });
  //   promises.push(
  //     this.fetchAccountsContributingTo({
  //       userId: this.profileStore.userId,
  //       organizationId: this.profileStore.organizationId
  //     })
  //   );
  //   promises.push(this.fetchUserPairs());
  //   await Promise.all(promises);
  //   this.fetchAuthorizedPersons({
  //     authorizedUsers: this.getAuthorizedActivePersons()
  //   });
  //   await this.fetchUserTransactions({ userId: this.profileStore.userId });
  // }
  // setupTokenRefresh(email: string) {
  //   this.refreshToken({
  //     email,
  //     tokens: this.getTokens()
  //   })
  //     .then(session => {
  //       localStorage.setItem("jwt", this.getIdToken());
  //     })
  //     .catch(err => {
  //       // will stop refreshing when logged out
  //       // console.log("Caught err, clear interval:", err);
  //       if (err.code === "NetworkError") {
  //         // console.log("Network Error: ", err.message);
  //       }
  //       clearInterval(this.refreshInterval);
  //     });
  //   // set the tokens to refresh ever 5 minutes
  //   this.refreshInterval = setInterval(() => {
  //     this.refreshToken({
  //       email,
  //       tokens: this.getTokens()
  //     })
  //       .then(session => {
  //         localStorage.setItem("jwt", this.getIdToken());
  //       })
  //       .catch(err => {
  //         // will stop refreshing when logged out
  //         // console.log("Caught err, clear interval:", err);
  //         clearInterval(this.refreshInterval);
  //         // send user to logout screen if networkerror code returned
  //         if (err && err.code === "NetworkError") {
  //           // console.log("redirect to logout");
  //           this.goLogout();
  //         }
  //       });
  //   }, 60 * 5000);
  // }
  // determine if it's an account route
  isAccountRoute(): boolean {
    if (window.location.pathname.indexOf("/account") === 0) {
      return true;
    }
    return false;
  }
  // determine if it's an account route
  isAccountBookingsRoute(): boolean {
    if (window.location.pathname.indexOf("/account/reservations") === 0) {
      return true;
    }
    return false;
  }
  // determine if it's an account route
  isAccountCredentialsRoute(): boolean {
    if (window.location.pathname.indexOf("/account/credentials") === 0) {
      return true;
    }
    return false;
  }
  // determine if it's an account route
  isAccountLodgingsRoute(): boolean {
    if (window.location.pathname.indexOf("/account/lodging") === 0) {
      return true;
    }
    return false;
  }
  isAccountPaymentsRoute(): boolean {
    if (window.location.pathname.indexOf("/account/payment") === 0) {
      return true;
    }
    return false;
  }
  isAdmin(): boolean {
    return this.profileStore.isRoleAdmin || this.profileStore.isRoleEmployee;
  }
  isAttendanceRoute(): boolean {
    if (window.location.pathname.indexOf("/attendance") === 0) {
      return true;
    }
    return false;
  }
  isBankRoute(): boolean {
    if (window.location.pathname.indexOf("/bank") === 0) {
      return true;
    }
    return false;
  }
  isCarePackageRoute(): boolean {
    if (window.location.pathname.indexOf("/packages") === 0) {
      return true;
    }
    return false;
  }
  isHomeRoute(): boolean {
    if (window.location.pathname === '/') return true;
    if (this.isAccountRoute()) return true;
    return false;
  }
  // getCognitoUser() {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       const user = await this.getCurrentUser();
  //       resolve(user);
  //     } catch (err) {
  //       resolve(null);
  //     }
  //   });
  // }
  isLoggedIn(): boolean {
    return this.authStore.isLoggedIn;
  }
  showCart() {
    if (
      window.location.pathname.indexOf("/care") === 0 ||
      window.location.pathname.indexOf("/shop") === 0
    ) {
      return true;
    }
    return false;
  }
  // transitionBeforeEnter(el: any) {
  //   this.elHeight = el.scrollHeight;
  // }
  // transitionEnter(el: any) {
  //   el.style.height = this.elHeight + "px";
  // }
  // transitionBeforeLeave(el: any) {
  //   el.style.height = 0;
  // }
  updateCSS(): void {
    let padTopMenu = false;
    let padLeftSideMenu = false;
    if (
      this.isLoggedIn() === false ||
      this.$route.query.tb === '0' ||
      window.location.pathname.indexOf("/activate") === 0 ||
      window.location.pathname.indexOf("/approve/waiver") === 0 ||
      window.location.pathname.indexOf("/auth") === 0 ||
      window.location.pathname.indexOf("/login") === 0 ||
      window.location.pathname.indexOf("/logout") === 0 ||
      window.location.pathname.indexOf("/offer") === 0 ||
      window.location.pathname.indexOf("/onboarding") === 0 ||
      window.location.pathname.indexOf("/receipt") === 0 ||
      window.location.pathname.indexOf("/redirect") === 0 ||
      window.location.pathname.indexOf("/signin") === 0 ||
      window.location.pathname.indexOf("/signout") === 0 ||
      window.location.pathname.indexOf("/signup") === 0 ||
      window.location.pathname.indexOf("/start") === 0
    ) {
      this.showMenus = false;
      padLeftSideMenu = false; // no padding on left for side nav
      padTopMenu = false; // no padding on top for top nav
    } else if (this.navStore.isSideNavHidden) {
      this.showMenus = true;
      padLeftSideMenu = false;
      padTopMenu = true;
    } else {
      this.showMenus = true;
      padLeftSideMenu = MQ.mdOrBigger;
      padTopMenu = true;
    }
    //
    if (padLeftSideMenu) {
      this.appClass = "app-nav-visible";
      this.toggleSideNav = true;
    } else {
      this.appClass = "app-nav-hidden";
    }
    //
    if (padTopMenu && (this.profileStore.isAssumedUser || window.location !== window.top?.location)) {
      this.contentClass = "content-two-topbars";
      this.navbarStyle = "mt-11";
      this.disclaimerClass = 'first-topbar';
      this.showAssumedUserWarningBar = true;
    } else if (!padTopMenu && (this.profileStore.isAssumedUser || window.location !== window.top?.location)) {
      this.contentClass = "content-single-topbar";
      this.disclaimerClass = 'first-topbar';
      this.showAssumedUserWarningBar = true;
    } else if (padTopMenu) {
      this.contentClass = "content-single-topbar";
      this.navbarStyle = "mt-0";
    } else {
      this.contentClass = "content-no-topbar";
    }

    // console.log('updateCSS side-menu=', this.showMenus && !this.isSideNavHidden, 'showMenus=', this.showMenus, '!isSideNavHidden=', !this.isSideNavHidden);
    // console.log('updateCSS app-class=', this.appClass, this.toggleSideNav);
  }

  /**
   * Getters
   */

  get allowBankTransfers(): boolean {
    return this.navStore.allowBankTransfers;
  }
  get isFeatureAttendanceEnabled(): boolean {
    return this.navStore.featureAttendanceEnabled
  }
  get isFeatureCarePackageEnabled(): boolean {
    return this.navStore.featureCarePackagesEnabled
  }
  get isFeatureContactEnabled(): boolean {
    return this.navStore.featureContactEnabled
  }
  get isFeatureDonateEnabled(): boolean {
    return this.navStore.featureDonateEnabled
  }
  get isFeatureEmailEnabled(): boolean {
    return this.navStore.featureEmailEnabled
  }
  get isFeatureEventEntryControlEnabled(): boolean {
    return this.navStore.featureEventEntryControlEnabled;
  }
  get isFeatureEventReservationsEnabled(): boolean {
    return this.navStore.featureEventReservationsEnabled
  }
  get isFeatureHealthEnabled(): boolean {
    return this.navStore.featureHealthEnabled;
  }
  get isFeatureNotificationsEnabled(): boolean {
    return this.navStore.featureNotificationsEnabled
  }
  get isFeaturePlaceOrderEnabled(): boolean {
    return this.profileStore.organizationId === 'prod-dup-34a2-4361-9a62-27db40b8e0f0'; // || this.profileStore.organizationId === '0b9c4c63-34a2-4361-9a62-27db40b8e0f0';
  }
  get isFeatureIdCardEnabled(): boolean {
    return this.navStore.featureIdCardEnabled;
  }
  get isFeatureSessionRegistrationEnabled(): boolean {
    return this.navStore.featureSessionRegistrationEnabled;
  }
  get isFeatureSpendingAccountsEnabled(): boolean {
    return this.navStore.featureSpendingAccountsEnabled
  }
  get isHomeEnabled(): boolean {
    return this.navStore.homeEnabled;
  }
  get isSideNavHidden(): boolean {
    return this.navStore.hideSideNav
  }
  get navBarTitle(): string {
    return this.navStore.navBarTitle;
  }
  get logoSidebar(): string {
    return this.navStore.logoSidebar;
  }
  get orgLinks(): OrganizationLink[] {
    if (this.profileStore.currOrganization !== undefined &&
        this.profileStore.currOrganization.links !== undefined) {
      return this.profileStore.currOrganization.links;
    }
    return [];
  }
  // return whether or not to show the backbar (instead of the navbar)
  get showBackbar(): boolean {
    // never show when a desktop sized display
    if (MQ.lg) {
      return false;
    }
    // see if the 'route' is configured to show the backbar
    if (this.$route.meta.backbar) {
      return true;
    }
    // vuex/nav wants to see it
    return this.navStore.isBackbarOverride;
  }
  get tourSteps() {
    // See documentation: https://github.com/pulsardev/vue-tour
    var steps = [];
    steps.push({
      target: "#top-tour",
      content: "Welcome! Please click Next to be guided through the site!",
      route: RouterNames.HOME
    });
    // add bank step
    if (this.navStore.isFeatureSpendingAccountsEnabled) {
      steps.push({
        target: "#bank",
        content: "View your accounts",
        route: RouterNames.BANK
      });
      steps.push({
        target: "#deposits",
        content: "Make deposits into your accounts",
        route: RouterNames.BANK_DEPOSITS
      });
      if (this.navStore.allowBankTransfers) {
        steps.push({
          target: "#transfers",
          content: "Transfer funds between your accounts",
          route: RouterNames.BANK_TRANSFERS
        });
      }
      steps.push({
        target: "#transactions",
        content: "View transactions on your accounts",
        route: RouterNames.BANK_TRANSACTIONS
      });
    }
    // add care package step
    if (this.navStore.isFeatureCarePackageEnabled) {
      steps.push({
        target: "#carepackages",
        content: "View Care Packages",
        route: RouterNames.CARE_PACKAGES
      });
    }
    steps.push({
      target: "#account",
      content: "Manage your account, payments, and contributors",
      route: RouterNames.ACCOUNT
    });
    steps.push({
      target: "#security",
      content: "Update your info and security credentials",
      route: RouterNames.ACCOUNT_CREDENTIALS
    });
    // steps.push({
    //   target: "#payments",
    //   content: "Add and manage your payment methods",
    //   route: RouterNames.ACCOUNT_PAYMENTMETHODS
    // });
    steps.push({
      target: "#contributors",
      content: "Add and manage who can contribute funds to your accounts",
      route: RouterNames.ACCOUNT_CONTRIBUTORS
    });

    steps.push({
      target: "#tour",
      content: "Click here to re-launch this tour again at anytime",
      route: RouterNames.HOME
    });

    return steps;
  }

  get webProcessor(): string | undefined {
    if (this.profileStore.currOrganization === undefined) return undefined;
    if (this.profileStore.currOrganization.processorSettings === undefined) return undefined;
    return this.profileStore.currOrganization.processorSettings.webProcessor
  }

  created() {
    // initialize the css
    this.updateCSS();
  }
  async mounted() {
    // check for incompatible browsers
    this.compatibleBrowser =
      // typeof Object["__defineSetter__"] === "function" &&
      !!String.prototype.includes && // check if supports prototype
      window.navigator.userAgent.indexOf("Edge") <= -1; // check that it's not edge (not compatible with square)
    // redirect if incompatible
    if (!this.compatibleBrowser) {
      // console.log("Incompatible browser, please switch or upgrade!");
      window.location.href = "/update-browser.html";
      return;
    }

    // this.$root.$on("launchTour", () => {
    //   this.launchTour();
    // });

    // TODO offline login for PWA
    // check if localhost dev
    /*
    if (
      window.location.hostname === 'localhost' &&
      localStorage.getItem('jwt') === '09aZ'
    ) {
      this.createDevUserDetails()
      this.setJWT(localStorage.getItem('jwt'))
    }
    */
    // // check if logged in
    // const user = await this.getCognitoUser();
    // if (!user || user.username === "") {
    //   // console.log('no user. set cognito vuex to signout')
    //   localStorage.setItem("jwt", null);
    //   localStorage.setItem("user", null);
    //   this.initAuthStore();
    //   this.initUser();
    // } else {
    //   // console.log("cognito user ", user);
    //   let isParent2 = user.isParent2;
    //   // populate the state w/ data
    //   // will need to update this when we add multiple organization logic for logging in
    //   this.setupTokenRefresh(user.username);
    //   this.initializeStoreData(user.username, isParent2);
    // }
    // load the maintenance messages
    // this.watchForMaintenance(30000);
    // reset backbar stack
    this.navStore.resetBackbarStack();
    // this.$tours['theTour'].start()
    this.isLoading = false;
  }
  // Take action whenever the route object changes
  // update css on route.name change
  @Watch("$route", { immediate: true, deep: true })
  onRouteNameChange(newRoute: any, oldRoute: any) {
    if (oldRoute === undefined || newRoute === undefined || oldRoute.name !== newRoute.name) {
      this.updateCSS();
    }
  }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<!--  background: url("/dynamic/logos/main-logo.png") no-repeat center center; -->
<style scoped>
.navbar .dropdown-menu .side-nav-submenu a:hover {
  color: inherit !important;
}
.view {
  background-size: cover;
  height: 100%;
}
.collapsible {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.collapsible a {
  font-size: 1rem;
}
.collapsible-header {
  position: relative;
}
.collapse-item {
  overflow: hidden;
  height: 0;
  padding: 0;
  transition: height 0.3s;
}
.collapse-item a {
  padding-left: 47px;
  line-height: 36px;
  background-color: rgba(0, 0, 0, 0.15);
}
.side-nav-submenu a {
  color: #fff;
  font-weight: 300;
  font-size: 0.8rem;
  height: 36px;
  line-height: 36px;
  padding-left: 35px;
  background-color: gray;
}
.fa-angle-down {
  float: right;
}
.icon-div {
  width: 49%;
  display: inline-block;
}
.rotated {
  transform: rotate(180deg);
}
.navbar i {
  cursor: pointer;
  color: white;
}

.no-topbar {
  top: -50px;
}

.first-topbar {
  top: 0px;
}

.second-topbar {
  top: 50px;
}

.content-no-topbar {
  margin-top: 10px;
  margin-left: 15px;
  margin-bottom: 40px;
  margin-right: 15px;
}

.content-single-topbar {
  margin-top: 65px;
  margin-left: 15px;
  margin-right: 15px;
}

.content-two-topbars {
  margin-top: 130px;
  margin-left: 15px;
  margin-right: 15px;
}

@media (min-width: 993px) {
  .app-nav-hidden {
    padding-left: 0px;
  }
}

@media (max-width: 680px) {
  .content-single-topbar {
    margin-bottom: 45px;
  }
  .content-two-topbars {
    margin-bottom: 45px;
  }
}

@media (min-width: 993px) {
  .app-nav-visible {
    padding-left: 240px;
  }
}
</style>
