export class RouterNames {
  static HOME = 'home'
  static AUTH_LOGIN = 'authLogin'
  static AUTH_LOGOUT = 'authLogout'
  static AUTH_DENIED = 'authDenied'
  static AUTH_ACTIVATE = 'authActivate'
  static AUTH_ACTIVATE_QS = 'authActivateQS'
  static AUTH_CREATE = 'authCreate'
  static AUTH_GETSTARTED = 'authGetStarted'
  static AUTH_INVITATION = 'authInvitation'
  static AUTH_NEWPASSWORD = 'authNewPassword'
  static AUTH_RECOVERY = 'authRecovery'
  static AUTH_SELECT_ORG = 'authSelectOrganization'
  static AUTH_VERIFY = 'authVerify'
  static AUTH_SIGNOUT = 'authSignOut'
  static ABOUT = 'about'
  static ACCOUNT = 'account'
  static ACCOUNT_ACTIVITY = 'accountActivity'
  static ACCOUNT_ALERTS = 'accountAlerts'
  static ACCOUNT_AUTHORIZEDUSER_ADD = 'accountAuthorizedUserAdd'
  static ACCOUNT_AUTHORIZEDUSER_LIST = 'accountAuthorizedUserList'
  static ACCOUNT_BOOKINGS = 'accountBookings'
  static ACCOUNT_CODEWORDS = 'accountCodewords'
  static ACCOUNT_COMMUNICATIONS = 'accountCommunications'
  static ACCOUNT_COMMUNICATIONS_LOWBALANCE = 'accountCommunicateLowBalance'
  static ACCOUNT_CONTRIBUTOR = 'accountContributor'
  static ACCOUNT_CONTRIBUTORS = 'accountContributors'
  static ACCOUNT_CREDENTIALS = 'accountCredentials'
  static ACCOUNT_LODGING_GUESTS = 'accountLodgingGuests'
  static ACCOUNT_LODGING_MANAGERS = 'accountLodgingManagers'
  static ACCOUNT_LODGING_STAY_ADD = 'accountLodgingStayAdd'
  static ACCOUNT_LODGING_STAYS = 'accountLodgingStays'
  static ACCOUNT_LODGING_UNITS = 'accountLodgingUnits'
  static ACCOUNT_PAYMENTMETHODS = 'accountPaymentMethods'
  static ACCOUNT_PAYMENTMETHODS_ADD = 'accountPaymentMethodsAdd'
  static ACCOUNT_PAYMENTMETHODS_EDIT = 'accountPaymentMethodsEdit'
  static ACCOUNT_PAYMENTMETHODS_DELETE = 'accountPaymentMethodsDelete'
  static ACCOUNT_PET_ADD = 'accountPetAdd'
  static ACCOUNT_PET_LIST = 'accountPetList'
  static ACCOUNT_TICKETS = 'accountTickets'
  static ACCOUNT_TICKET_VIEWER = 'accountTicketView'
  static ACCOUNT_VEHICLE_ADD = 'accountVehicleAdd'
  static ACCOUNT_VEHICLE_LIST = 'accountVehicleList'
  static ACTIVITY_BOOKIT = 'activityBookit'
  static APPROVE_WAIVER = 'approveWaiver'
  static ATTENDANCE = 'attendance'
  static ATTENDANCE_DOWNLOAD = 'attendanceDownload'
  static BANK = 'bank'
  static BANK_ACCOUNT = 'bankAccount'
  static BANK_BUDGET = 'bankBudget'
  static BANK_BUDGETS = 'bankBudgets'
  static BANK_DEPOSITS = 'bankDeposits'
  static BANK_REMAINING = 'bankRemaining'
  static BANK_SITEMAP = 'bankSitemap'
  static BANK_TRANSFERS = 'bankTransfers'
  static BANK_TRANSACTIONS = 'bankTransactions'
  static CARE_PACKAGES = 'carePackages'
  static CARE_ORDERS = 'careOrders'
  static DONATE = 'donate';
  static ENTRY_PASS = 'entryPass';
  static GUIDE = 'guide'
  static HEALTH = 'health'
  static HEALTH_DETAIL = 'healthDetail'
  static INVOICE_DETAIL = 'invoiceDetail';
  static INVOICE_LIST = 'invoiceList';
  static INVOICE_MAKE_PAYMENT = 'invoiceMakePayment';
  static ONBOARDING_MAIN = 'onboardingMain';
  static RECEIPT_TRANSACTION = 'receiptTransaction';
  static REDIRECT = 'redirect';
  static RESERVE_CLUBHOUSE = 'reserveClubhouse';
  static RESERVE_COURT = 'reserveCourt';
  static RESERVE_RESOURCE = 'reserveResource';
  static RESERVE_SESSION = 'reserveSession';
  static RESERVATION = 'reservation'
  static SHOP = 'shop'
  static SHOP_ACCESS_CONTROL = 'shopAccessControl'
  static SHOP_BY_CATEGORY = 'shopCategory'
  static SHOP_CART = 'shopCart'
  static SHOP_CHECKOUT = 'shopCheckout'
  static SHOP_ORDERS = 'shopOrders'
  static SITEMAP = 'sitemap'
  static SITEMAP_CATEGORY = 'sitemapCategory'
  static NOT_IMPLEMENTED = 'notImplemented'
  static HELP = 'help'
}
