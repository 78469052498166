var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { ref: "activityTable", staticClass: "mb-5" },
    [
      _vm.displayTransactionItems
        ? [
            _c("v-data-table", {
              attrs: {
                items: _vm.bankStore.transactions,
                headers: _vm.vTransactionFields,
                "items-per-page": _vm.perPage,
                "sort-by": _vm.sortBy,
                "sort-desc": _vm.sortDesc,
              },
              on: {
                "update:sortBy": function ($event) {
                  _vm.sortBy = $event
                },
                "update:sort-by": function ($event) {
                  _vm.sortBy = $event
                },
                "update:sortDesc": function ($event) {
                  _vm.sortDesc = $event
                },
                "update:sort-desc": function ($event) {
                  _vm.sortDesc = $event
                },
              },
            }),
          ]
        : _vm._e(),
      [
        _c(
          "v-container",
          [
            _c(
              "v-row",
              [
                _c(
                  "v-card",
                  { attrs: { "min-width": "300px" } },
                  [
                    _c("v-card-title", [_vm._v("Today")]),
                    _c("v-card-text", [
                      _vm._v("$" + _vm._s(_vm.spendingToday)),
                    ]),
                  ],
                  1
                ),
                _c(
                  "v-card",
                  { attrs: { "min-width": "300px" } },
                  [
                    _c("v-card-title", [_vm._v("Last Seven Days")]),
                    _c("v-card-text", [
                      _vm._v("$" + _vm._s(_vm.spendingPastWeek)),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }