import { ApiEndpoints } from '../constants';
import { store } from '@/store/Store';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { ApiService } from '@/shared/auth/auth-cognito';
import { getModule } from 'vuex-module-decorators';
import { ProfileModule } from './ProfileModule';
import { Session } from '@fgl/funfangle-sdk/dist/rest/session';
import { User } from '@fgl/funfangle-sdk/dist/rest/profile';

const apiSvc = new ApiService();

// export interface CodewordParams {
//   organizationId?: string;
//   userId?: string;
//   codeword?: string;
//   codehint?: string;
// }

@Module({ dynamic: true, store, name: 'reservation' })
export class ReservationModule extends VuexModule {
  profileStore: ProfileModule = getModule(ProfileModule);

  participants: User[] = [];
  session: Session | null = null;

  // selectedRowItem: TaskTicketTableItem | null = null;
  // selectedRowIndex: number | null = null;
  // // imported logs
  // tickets: TaskTicket[] = [];
  // toggleView = 'user';

  // @Action
  // public async updateCodeword(params: CodewordParams = {}): Promise<any> {
  //   if (params.organizationId === undefined) params.organizationId = this.profileStore.organizationId || undefined;
  //   if (params.userId === undefined) return Promise.reject(new Error('UserId is undefined'));
  //   const url = `${ApiEndpoints.apiEndpoint}/v3/profile/person/codeword`
  //   const data = {
  //     organizationId: params.organizationId,
  //     personId: params.userId,
  //     codeword: params.codeword,
  //     codehint: params.codehint
  //   }

  //   return new Promise((resolve, reject) => {
  //     apiSvc.httpPost(url, data)
  //       .then(res => {
  //         resolve(res)
  //       })
  //       .catch(err => {
  //         // console.log('Got error getting lingo:', err)
  //         reject(err)
  //       })
  //   })
  // }

  @Mutation
  public setParticipants(participants: User[] | undefined | null): void {
    this.participants = participants || [];
  }

  @Mutation
  public setSession(session: Session | undefined | null): void {
    this.session = session || null;
  }

  // @Mutation
  // public setOrganization(org: Organization): void {
  //   this.selectedOrganization = org;
  // }

}
