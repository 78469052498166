<template>
    <v-container v-if="!showCustomMessage">
      <v-row @click.prevent.stop="toggleActiveItem(0)" class="margin-one">
        <v-col href="#" class="d-flex justify-space-between align-center">
          Available Balance
          <span>
            {{ $n(balance, 'currency') }}
            <template v-if="scheduledAllocationsBalance > 0 && $route.name !== 'bankAccount'">*</template>
          </span>
        </v-col>
      </v-row>
      <v-row class="margin-one">
        <v-col
          v-if="scheduledAllocationsBalance > 0 && $route.name !== 'bankAccount'"
          style="color: #bebebe"
        >
          *
          <a
            @click="goBankAccountPage(currentPersonId)"
          >Future funds of {{ $n(scheduledAllocationsBalance, 'currency') }} to be available.</a>
        </v-col>
      </v-row>
      <v-row v-if="activeItem >= -1 || showButtons">
        <v-col v-if="false">
            <v-btn
              class="my-1 mx-auto"
              large
              block
              @click.prevent.stop="goBankDepositsPage(currentPersonId)"
              color="primary"
              style="margin-left: 0"
            >
              <v-icon class="mx-2">{{ routerIcons.BANK_DEPOSITS }}</v-icon>
              Deposit
            </v-btn>
            <v-btn
              class="my-1 mx-auto"
              large
              block
              @click.prevent.stop="goBankTransfersPage(currentPersonId)"
              v-if="allowBankTransfers"
            >
              <v-icon class="mx-2">{{ routerIcons.BANK_TRANSFERS }}</v-icon>
              Transfer
            </v-btn>
            <v-btn
              class="my-1 mx-auto"
              large
              block
              @click.prevent.stop="goBankBudgetWizardPage(currentPersonId)"
              v-if="allowBankDailyLimit"
              color="primary"
            >
              <v-icon class="mx-2"> {{ routerIcons.BANK_BUDGETS }}</v-icon>
              Budget
            </v-btn>
        </v-col>
        <v-col v-if="true">
            <v-btn
              large
              block
              @click.prevent.stop="goBankDepositsPage(currentPersonId)"
              class="my-1 mx-auto"
              color="primary"
            >
              <v-icon class="mx-2">{{ routerIcons.BANK_DEPOSITS }}</v-icon>
              Deposit
            </v-btn>
            <v-btn
              large
              block
              @click.prevent.stop="goBankAccountPage(currentPersonId)"
              class="my-1 mx-auto"
              outlined
              color="primary"
              v-if="(balance + scheduledAllocationsBalance) > 0 && MQ.mdOrBigger && showManageFundsButton"
            >
              <v-icon class="mx-2">{{ routerIcons.BANK_ACCOUNT }}</v-icon>
              Manage Funds
            </v-btn>
        </v-col>
      </v-row>
    </v-container>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { BankModule, BankPrivileges, NavModule } from "@/store";
import { RouterIcons } from '@/router/RouterIcons'
import { RouterUtils } from '@/router/RouterUtils'
import { MediaQuery as MQ } from '@/MediaQuery';

// Define the props by using Vue's canonical way.
const AvailableBalanceCardProps = Vue.extend({
  props: {
    balance: {
      type: Number,
      default: 0
    },
    hideButtons: {
      type: Boolean,
      default: false
    },
    buttonsLayout: {
      type: String,
      default: "vertical-group-all"
    }
  },
  mixins: [RouterUtils]
});

@Component({
  components: {
    // mdbIcon
  }
})
export default class AvailableBalanceCard extends AvailableBalanceCardProps {
  bankStore: BankModule = getModule(BankModule, this.$store);
  bankPrivileges = new BankPrivileges();
  navStore: NavModule = getModule(NavModule, this.$store);
  // profileStore: ProfileModule = getModule(ProfileModule, this.$store);
  MQ = MQ;

  customMessage = ""

  // return which card is active
  get activeItem() {
    return this.$store.state.bank.activeItem;
  }
  get allowBankDailyLimit(): boolean {
    return this.bankPrivileges.dailyLimitChange(this.currentPerson);
  }
  get allowBankTransfers(): boolean {
    return this.bankPrivileges.transfer(this.currentPerson);
  }
  get routerIcons(): RouterIcons {
    return RouterIcons;
  }
  get scheduledAllocationsBalance() {
    // get scheduled allocations for user
    let scheduledAllocations = this.bankStore.scheduledAllocations.filter(item => {
      return item.authUserId === this.navStore.selectedUserId;
    });
    let bal = 0.0;
    for (var i in scheduledAllocations) {
      var allocation = scheduledAllocations[i];
      if (allocation.status === "pending") {
        bal = bal + allocation.amount;
      }
      if (allocation.status === "reversed") {
        bal = bal - allocation.amount;
      }
    }
    return bal;
  }
  // return whether to show the buttons or not
  get showButtons() {
    if (
      this.hideButtons === true ||
      (this.hideButtons as unknown as any) === "" ||
      (this.hideButtons as unknown as any) === "true"
    ) {
      return false;
    }
    return true;
  }
  get showDepositButton() {
    if (this.balance > 0) {
      return false;
    }
    return true;
  }
  get showManageFundsButton() {
    return this.$route.name !== 'bankAccount';
  }
  get showTransferButton() {
    return !this.showDepositButton && this.navStore.allowBankTransfers;
  }
  get showCustomMessage() {
    return false;
    // return this.navStore.displayCustomMessage;
  }
  get currentPerson() {
    return this.navStore.selectedUser;
  }
  get currentPersonId() {
    return this.navStore.selectedUserId;
  }

  toggleActiveItem(index: number): void {
    this.navStore.toggleActiveItem(index);
  }

  // methods: {
  //   ...mapGetters([
  //     "",
  //     "",
  //     "getCustomMessage"
  //   ]),
  //   ...mapMutations(["setCustomMessage"]),
  //   ...mapActions(["updateCustomMessage"]),
  //   ...mapGetters("bank", [
  //     "displayCustomMessage"
  //   ]),
  //   ...mapMutations("bank", ["", "toggleCustomMessage"]),
  // async sendCustomMessage(customMessage: string): Promise<void> {
  //   const authorizedUserId = this.navStore.selectedUserId;
  //   const data = {
  //     customMessage,
  //     authorizedUserId
  //   };
  //   try {
  //     // send POST to API
  //     await this.updateCustomMessage(data);
  //     // set store
  //     this.setCustomMessage(customMessage);
  //     // go back to buttons screen
  //     this.toggleCustomMessage();
  //   } catch (err) {
  //     // console.log("error in updating custom note:", err);
  //   }
  // }
  // updateMessage(val: string): void {
  //   this.customMessage = val;
  // }

  // @Watch("currentPerson")
  // onCurrentPersonChange(newVal: string /* , oldVal */) {
  //   let { customMessage } = newVal;
  //   this.customMessage = customMessage;
  // }

  mounted() {
    // const storeMessage = this.getCustomMessage();
    // this.customMessage = storeMessage ? storeMessage : "";
  }
}
</script>

<style>
#custom-note-input {
  margin-top: 10px;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
  font-size: 14px;
}

#custom-note-container {
  position: relative;
}

.custom-note-close-btn {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.custom-note-close-btn-icon {
  font-size: 20px;
}

.margin-one {
  margin: 1px;
}
</style>
