import { ApiEndpoints } from '../constants';
import { store } from '@/store/Store';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { ApiService } from '@/shared/auth/auth-cognito';
import { getModule } from 'vuex-module-decorators';
import { ProfileModule } from './ProfileModule';

const apiSvc = new ApiService();

export interface CodewordParams {
  organizationId?: string;
  userId?: string;
  codeword?: string;
  codehint?: string;
}

@Module({ dynamic: true, store, name: 'profilePeople' })
export class ProfilePeopleModule extends VuexModule {
  profileStore: ProfileModule = getModule(ProfileModule);
  // selectedRowBody: TaskTicketTableRow | null = null;
  // selectedRowItem: TaskTicketTableItem | null = null;
  // selectedRowIndex: number | null = null;
  // // imported logs
  // tickets: TaskTicket[] = [];
  // toggleView = 'user';

  @Action
  async fetchCodewords(params: { accountId: string, organizationId?: string | null }): Promise<{
    codehint?: string;
    codeword?: string;
    personId?: string;
    organizationId?: string;
  }[]> {
    const organizationId = params.organizationId || this.profileStore.organizationId;
    const url = `${ApiEndpoints.apiEndpoint}/v3/profile/person/codewords?account_id=${params.accountId}&orgid=${organizationId}`;
    const res = await apiSvc.httpGet(url).catch(err => {
      return Promise.reject(err);
    });
    if (res && res !== undefined && res.data !== undefined && res.data.items !== undefined) {
      return Promise.resolve(res.data.items);
    }
    return Promise.resolve([]);
  }

  @Action
  public async updateCodeword(params: CodewordParams = {}): Promise<any> {
    if (params.organizationId === undefined) params.organizationId = this.profileStore.organizationId || undefined;
    if (params.userId === undefined) return Promise.reject(new Error('UserId is undefined'));
    const url = `${ApiEndpoints.apiEndpoint}/v3/profile/person/codeword`
    const data = {
      organizationId: params.organizationId,
      personId: params.userId,
      codeword: params.codeword,
      codehint: params.codehint
    }

    return new Promise((resolve, reject) => {
      apiSvc.httpPost(url, data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          // console.log('Got error getting lingo:', err)
          reject(err)
        })
    })
  }

  /**
   * Get the user indicated by the user index
   */
  // get importLogs(): User {
  //   return this.users[this.userIndex];
  // }

  // @Mutation
  // public setSelectedRowBody(row: TaskTicketTableRow): void {
  //   this.selectedRowBody = row;
  // }

}
