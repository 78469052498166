var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.showCustomMessage
    ? _c(
        "v-container",
        [
          _c(
            "v-row",
            {
              staticClass: "margin-one",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                  return _vm.toggleActiveItem(0)
                },
              },
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "d-flex justify-space-between align-center",
                  attrs: { href: "#" },
                },
                [
                  _vm._v(" Available Balance "),
                  _c(
                    "span",
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$n(_vm.balance, "currency")) + " "
                      ),
                      _vm.scheduledAllocationsBalance > 0 &&
                      _vm.$route.name !== "bankAccount"
                        ? [_vm._v("*")]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "margin-one" },
            [
              _vm.scheduledAllocationsBalance > 0 &&
              _vm.$route.name !== "bankAccount"
                ? _c("v-col", { staticStyle: { color: "#bebebe" } }, [
                    _vm._v(" * "),
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.goBankAccountPage(_vm.currentPersonId)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "Future funds of " +
                            _vm._s(
                              _vm.$n(
                                _vm.scheduledAllocationsBalance,
                                "currency"
                              )
                            ) +
                            " to be available."
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm.activeItem >= -1 || _vm.showButtons
            ? _c(
                "v-row",
                [
                  false
                    ? _c(
                        "v-col",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "my-1 mx-auto",
                              staticStyle: { "margin-left": "0" },
                              attrs: { large: "", block: "", color: "primary" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  $event.stopPropagation()
                                  return _vm.goBankDepositsPage(
                                    _vm.currentPersonId
                                  )
                                },
                              },
                            },
                            [
                              _c("v-icon", { staticClass: "mx-2" }, [
                                _vm._v(_vm._s(_vm.routerIcons.BANK_DEPOSITS)),
                              ]),
                              _vm._v(" Deposit "),
                            ],
                            1
                          ),
                          _vm.allowBankTransfers
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "my-1 mx-auto",
                                  attrs: { large: "", block: "" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      return _vm.goBankTransfersPage(
                                        _vm.currentPersonId
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", { staticClass: "mx-2" }, [
                                    _vm._v(
                                      _vm._s(_vm.routerIcons.BANK_TRANSFERS)
                                    ),
                                  ]),
                                  _vm._v(" Transfer "),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.allowBankDailyLimit
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "my-1 mx-auto",
                                  attrs: {
                                    large: "",
                                    block: "",
                                    color: "primary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      return _vm.goBankBudgetWizardPage(
                                        _vm.currentPersonId
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", { staticClass: "mx-2" }, [
                                    _vm._v(
                                      " " + _vm._s(_vm.routerIcons.BANK_BUDGETS)
                                    ),
                                  ]),
                                  _vm._v(" Budget "),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  true
                    ? _c(
                        "v-col",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "my-1 mx-auto",
                              attrs: { large: "", block: "", color: "primary" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  $event.stopPropagation()
                                  return _vm.goBankDepositsPage(
                                    _vm.currentPersonId
                                  )
                                },
                              },
                            },
                            [
                              _c("v-icon", { staticClass: "mx-2" }, [
                                _vm._v(_vm._s(_vm.routerIcons.BANK_DEPOSITS)),
                              ]),
                              _vm._v(" Deposit "),
                            ],
                            1
                          ),
                          _vm.balance + _vm.scheduledAllocationsBalance > 0 &&
                          _vm.MQ.mdOrBigger &&
                          _vm.showManageFundsButton
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "my-1 mx-auto",
                                  attrs: {
                                    large: "",
                                    block: "",
                                    outlined: "",
                                    color: "primary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      return _vm.goBankAccountPage(
                                        _vm.currentPersonId
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", { staticClass: "mx-2" }, [
                                    _vm._v(
                                      _vm._s(_vm.routerIcons.BANK_ACCOUNT)
                                    ),
                                  ]),
                                  _vm._v(" Manage Funds "),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }