// import { Module as Mod } from 'vuex';
// import axios from 'axios';
import {
  CognitoUserSession,
  CognitoUser,
  CognitoRefreshToken,
  AuthenticationDetails,
  CognitoUserAttribute,
  ICognitoUserData,
  ISignUpResult,
  ICognitoUserAttributeData,
  IAuthenticationDetailsData
} from 'amazon-cognito-identity-js';
import { CognitoPoolModule } from './CognitoPoolModule';
import { store } from '@/store/Store';
import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import * as callbackTypes from './callback-types';
import { RouterNames } from '@/router/RouterNames';
import { AuthCookies } from './AuthCookies';

const defaultUser = {
  username: '',
  attributes: {},
  tokens: {
    IdToken: undefined,
    RefreshToken: undefined,
    AccessToken: undefined
  },
  cognitoUserSession: undefined,
  cognitoUser: undefined
};

export interface ChangePasswordParams {
  oldPassword: string;
  newPassword: string;
}

export interface ConfirmPasswordParams {
  username: string;
  code: string;
  newPassword: string;
}

export interface ConfirmRegistrationParams {
  username: string;
  code: string;
}

export interface RefreshTokenParams {
  email: string;
  refreshToken: string;
}

export interface SignupParams {
  userInfo: UserType;
  password: string;
}

interface ObjectType {
  [key: string]: any;
}

// interface NameValueType {
//   Name: string;
//   Value: string;
// }

interface UserType {
  username: string;
  attributes: ObjectType;
  tokens: {
    IdToken?: string;
    AccessToken?: string;
    RefreshToken?: string;
  };
  cognitoUserSession?: CognitoUserSession;
  cognitoUser?: CognitoUser;
}

@Module({ dynamic: true, store, name: 'auth', namespaced: true })
export class AuthModule extends VuexModule {
  user: UserType = defaultUser;
  lastCallbackErr: any = null;
  // public routerNames = require('@/router/RouterNames');
  routerNames = RouterNames;
  redirectAfterLogin: string = RouterNames.HOME;
  requiredAttributes: {} = {};
  poolModule = getModule(CognitoPoolModule, store);
  idTokenExpiresAt: number = Date.now();

  get accessToken() {
    try {
      // console.log('get id token', state.user.tokens.IdToken)
      // console.log('get id token', state.user.cognitoUserSession.idToken)
      return this.user.tokens.AccessToken;
    } catch (e) {
      return null;
    }
  }

  /*
  get redirectAfterLogin() {
    // TODO revise with way to ensure the redirect name is found in the RouterUtils file
    if (this.redirectAfterLogin === undefined || this.redirectAfterLogin == null || this.redirectAfterLogin === '') {
      return RouterNames.HOME;
    }
    return this.redirectAfterLogin;
  }
  */

  get cognitoUserSession() {
    // TODO is this still used?
    return this.user.cognitoUserSession;
  }

  get tokens() {
    try {
      return this.user.tokens;
    } catch (e) {
      return null;
    }
  }

  get isLoggedIn() {
    if (this.user.username && this.user.username !== undefined) {
      return true;
    }
    return false;
  }

  get jwt() {
    try {
      // console.log('get id token', state.user.tokens.IdToken)
      // console.log('get id token', state.user.cognitoUserSession.idToken)
      return this.user.tokens.IdToken;
    } catch (e) {
      return null;
    }
  }

  get username(): string {
    const uname = this.user.username || AuthCookies.getUsername();
    // console.log('get username', uname);
    return uname;
  }

  get userAttributes() {
    return this.user.attributes;
  }

  get userIdToken() {
    try {
      // console.log('get id token', state.user.tokens.IdToken)
      // console.log('get id token', state.user.cognitoUserSession.idToken)
      return this.user.tokens.IdToken;
    } catch (e) {
      return null;
    }
  }

  get userRefreshToken() {
    try {
      const refreshToken = this.user.tokens.RefreshToken || AuthCookies.getRefreshToken();
      // console.log('get userRefreshToken', refreshToken);
      return refreshToken;
    } catch (e) {
      return null;
    }
  }

  @Mutation
  clearCookies() {
    AuthCookies.resetCookies();
  }

  @Mutation
  init() {
    this.user = defaultUser;
    this.lastCallbackErr = null;
    this.redirectAfterLogin = RouterNames.HOME;
    this.requiredAttributes = {};
  }

  @Mutation
  setAuthenticate(user: UserType) {
    this.user = user;
  }

  @Mutation
  resetUser() {
    this.user = defaultUser;
  }

  // SET ATTRIBUTES
  @Mutation
  setAttributes(attributes: {}) {
    this.user.attributes = attributes;
  }

  // SET COGNITO USER
  @Mutation
  setCognitoUser(cognitoUser: CognitoUser | undefined) {
    this.user.cognitoUser = cognitoUser;
  }

  // SET COGNITO_USER_SESSION
  @Mutation
  setCognitoUserSession(cognitoUserSession: CognitoUserSession | undefined) {
    if (cognitoUserSession === undefined) {
      this.user.tokens = {
        IdToken: undefined,
        AccessToken: undefined,
        RefreshToken: undefined
      };
      this.user.cognitoUserSession = undefined;
      return;
    }
    // save tokens
    this.user.tokens = {
      IdToken: cognitoUserSession.getIdToken().getJwtToken(),
      AccessToken: cognitoUserSession.getAccessToken().getJwtToken(),
      RefreshToken: cognitoUserSession.getRefreshToken().getToken()
    };
    // reset timer to now + 30min
    this.idTokenExpiresAt = Date.now() + 30 * 60 * 60 * 1000;
    // save session
    this.user.cognitoUserSession = cognitoUserSession;
    // save refresh token
    AuthCookies.setSessionCookie(cognitoUserSession);
    // const name = 'refresh-token';
    // const value = cognitoUserSession.getRefreshToken().getToken();
    // const days = 21;
    // let expires = '';
    // if (days) {
    //   var date = new Date();
    //   date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    //   expires = '; expires=' + date.toUTCString();
    // }
    // const host = window.location.hostname;
    // document.cookie = name + '=' + (value || '') + expires + '; SameSite=Strict; Secure; Domain=' + host + '; path=/';
  }

  // SET LAST_CALLBACK_ERR
  @Mutation
  setLastCallbackErr(err: any) {
    this.lastCallbackErr = err;
  }

  // SET ORG ID
  @Mutation
  setOrganizationId(organizationId: string) {
    AuthCookies.setOrganizationIdCookie(organizationId);
  }

  // SET REDIRECT_AFTER_LOGIN
  @Mutation
  setRedirectAfterLogin(redirectAfterLogin: string | (string | null)[]) {
    if (!(redirectAfterLogin instanceof Array) && redirectAfterLogin && redirectAfterLogin !== undefined && redirectAfterLogin !== '') {
      this.redirectAfterLogin = redirectAfterLogin;
    } else {
      this.redirectAfterLogin = RouterNames.HOME;
    }
  }

  // SET REQUIRED_ATTRIBUTES
  @Mutation
  setRequiredAttributes(requiredAttributes: {}) {
    this.requiredAttributes = requiredAttributes;
  }

  // SET USER_ATTRIBUTES
  @Mutation
  setUserAttributes(attributes: {}) {
    this.user.attributes = attributes;
  }

  // SET USER ID
  @Mutation
  setUserId(userId: string) {
    AuthCookies.setUserIdCookie(userId);
  }

  // SET USERNAME
  @Mutation
  setUsername(username: string) {
    if (this.user === undefined) this.user = defaultUser;
    this.user.username = username;
    AuthCookies.setUsernameCookie(username);
    // console.log('username', this.user.username);
  }

  @Mutation
  setJWT(jwt: string) {
    this.user.tokens.IdToken = jwt;
    // reset timer to now + 30min
    this.idTokenExpiresAt = Date.now() + 30 * 60 * 60 * 1000;
  }

  // --- actions ---
  /*
   * Methods
   * authenticateUser
   * completeNewPasswordChallenge
   * confirmRegistration
   * getCurrentUser
   * changePassword
   * updateAttributes
   * signOut
   */

  @Action({ rawError: true })
  async fetchCurrentUserSession(): Promise<any> {
    return new Promise((resolve, reject) => {
      const poolModule = getModule(CognitoPoolModule);
      const cognitoUser = poolModule.cognitoUserPool.getCurrentUser();
      if (!cognitoUser) {
        reject(new Error('Cannot retrieve the current user'));
        return;
      }
      cognitoUser.getSession((err: Error | null, session: CognitoUserSession | null) => {
        if (err || session == null) {
          reject(err);
          return;
        }
        // Call AUTHENTICATE because it's utterly the same
        // console.log('fetchCurrentUserSession setUsername');
        this.setUsername(cognitoUser.getUsername());
        this.setCognitoUserSession(session);

        // cognitoUser.getUserAttributes((err: Error | undefined, attributes: CognitoUserAttribute[] | undefined) => {
        //   //let isParent2 = false;
        //   if (!err && attributes !== undefined) {
        //     const role = attributes.find(attribute => attribute.getName() === 'custom:account-role');
        //     //if (role !== undefined && role.getValue() === 'true') isParent2 = true;
        //   }
        //   //this.setIsParent2(isParent2);
        //   resolve(this.user);
        // });

        resolve(this.user);
      });
    });
  }

  @Action({ rawError: true })
  public async refreshToken(): Promise<CognitoUserSession> {
    const thatUsername = this.username;
    const thatRefreshToken = this.userRefreshToken;
    //console.log('thatUsername', thatUsername);

    // Check values
    if (!thatUsername || thatUsername === undefined || thatUsername === '') {
      return Promise.reject(new Error('No username available. Please login.'));
    }
    if (!thatRefreshToken || thatRefreshToken === undefined || thatRefreshToken === '') {
      return Promise.reject(new Error('No refresh-token available. Please login.'));
    }
    // create params and attempt refresh
    const params: RefreshTokenParams = {
      email: thatUsername,
      refreshToken: thatRefreshToken
    };
    return this.refreshTokenWithParams(params);
  }

  @Action({ rawError: true })
  public async refreshTokenWithParams(params: RefreshTokenParams): Promise<CognitoUserSession> {
    const poolModule = getModule(CognitoPoolModule);

    const userData = {
      Username: params.email,
      Pool: poolModule.cognitoUserPool
    };

    const cognitoUser = new CognitoUser(userData);

    const token = new CognitoRefreshToken({ RefreshToken: params.refreshToken });
    return new Promise((resolve, reject) => {
      cognitoUser.refreshSession(token, (err, session) => {
        if (!err) {
          // console.log('refreshTokenWithParams setUsername');
          this.setUsername(params.email);
          this.setCognitoUser(cognitoUser);
          this.setCognitoUserSession(session);
          resolve(session);
        } else {
          reject(err);
        }
      });
    });
  }

  // Authenticate User
  @Action({ rawError: true })
  public authenticateUser(authenticationData: IAuthenticationDetailsData): Promise<any> {
    // console.log('authenticationdata ', authenticationData);
    // formulate parameters for cognito call
    if (!authenticationData || authenticationData === undefined) {
      return Promise.reject(new Error('AuthenticationData is undefined'));
    }
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    const userData: ICognitoUserData = {
      Username: authenticationData.Username,
      Pool: this.poolModule.cognitoUserPool
    };
    const cognitoUser = new CognitoUser(userData);
    /**
     * Parameters in AWS SDK for authenticateUser
     * https://github.com/aws-amplify/amplify-js/blob/master/packages/amazon-cognito-identity-js/src/CognitoUser.js
     * param {AuthenticationDetails} authDetails Contains the authentication data
     * param {object} callback Result callback map.
     * param {onFailure} callback.onFailure Called on any error.
     * param {newPasswordRequired} callback.newPasswordRequired new
     *        password and any required attributes are required to continue
     * param {mfaRequired} callback.mfaRequired MFA code
     *        required to continue.
     * param {customChallenge} callback.customChallenge Custom challenge
     *        response required to continue.
     * param {authSuccess} callback.onSuccess Called on success with the new session.
     * returns {void}
     */
    return new Promise((resolve, reject) =>
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (session, userConfirmationNecessary) => {
          this.setUsername(cognitoUser.getUsername());
          this.setCognitoUserSession(session);
          // cognitoUser.getUserAttributes((err, attributes) => {
          //   if (!err && attributes !== undefined) {
          //     const role = attributes.find(attribute => attribute.getName() === 'custom:account-role');
          //   }
          //   console.log('AuthModule.authenticateUser onSuccess getUserAttributes', attributes)
          // });
          resolve({ cognitoUser, userConfirmationNecessary });
        },
        customChallenge: function(challengeParameters) {
          // console.log('customChallenge', challengeParameters)
          reject(new Error('Not able to handle custom challange'));
        },
        mfaRequired: function(codeDeliveryDetails) {
          // console.log('mfaRequired', codeDeliveryDetails)
          const verificationCode = prompt('Please input verification code', '');
          if (verificationCode) {
            cognitoUser.sendMFACode(verificationCode, this);
          }
        },
        mfaSetup: function(challengeName, challengeParameters) {
          // console.log('mfaSetup', challengeName, challengeParameters)
          reject(new Error(' Not able to process mfaSetup ' + challengeName));
        },
        totpRequired(challengeName, challengeParameters) {
          // console.log('totpRequired', challengeName, challengeParameters)
          reject(new Error(' Not able to process totpRequired ' + challengeName));
        },
        newPasswordRequired(userAttributes, requiredAttributes) {
          // console.log('newpasswordrequired');
          // console.log(' email: ', cognitoUser.getUsername());
          // save state
          const authMod = getModule(AuthModule);
          authMod.setCognitoUser(cognitoUser);
          // console.log('newPasswordRequired setUsername');
          authMod.setUsername(cognitoUser.getUsername());
          authMod.setUserAttributes(userAttributes);
          authMod.setRequiredAttributes(requiredAttributes);
          // cognitoUser.setCognitoUser(cognitoUser);
          // create err object
          const err = {
            message: 'New password required',
            type: callbackTypes.NEWPASSWORD,
            userAttributes: userAttributes,
            requiredAttributes: requiredAttributes
          };
          reject(err);
        },
        onFailure: function(err) {
          if (err && err !== undefined && (err.code === 'RESET_REQUIRED' || err.code === 'PasswordResetRequiredException')) {
            err.message =
              'Password has be reset. Please check your email for a verification code. ' +
              'Then click the Enter Verification Code link below to reset your password. ' +
              'If you did not receive a code, then click Forgot Password below to request a new one.';
          } else {
            // console.warn('failure to authenticate user', err);
          }
          reject(err);
        }
      })
    );
  }

  @Action({ rawError: true })
  signUp(params: SignupParams) {
    /* userInfo: { username, password, attributes } */
    const userAttributes = Object.keys(params.userInfo.attributes || {}).map(
      key =>
        new CognitoUserAttribute({
          Name: key,
          Value: params.userInfo.attributes[key]
        })
    );
    return new Promise((resolve, reject) => {
      const poolModule = getModule(CognitoPoolModule);
      const validationData: CognitoUserAttribute[] = [];
      poolModule.cognitoUserPool.signUp(params.userInfo.username, params.password, userAttributes, validationData, (err?: Error, data?: ISignUpResult) => {
        if (!err && data !== undefined) {
          // console.log('signup setUsername');
          this.setUsername(data.user.getUsername());
          //this.setAuthenticate(
          //  username: data.user.getUsername(),
          //  tokens: null, // no session yet
          //  attributes: {}
          //);
          if (data !== undefined) {
            resolve({ userConfirmationNecessary: !data.userConfirmed });
            return;
          }
        }
        reject(err);
      });
    });
  }

  // User was signed up by an admin and must provide new
  // password and required attributes, if any, to complete
  // authentication.
  @Action({ rawError: true })
  completeNewPasswordChallenge(password: string) {
    // console.log('new password is ', password)
    // create user data object
    const cognitoUser = this.user.cognitoUser;
    // console.log('cognitoUser is ', cognitoUser)
    if (cognitoUser === undefined) {
      return Promise.reject('Cognito user is not initialized');
    }
    let userAttributes = this.user.attributes;
    // console.log('userAttributes is ', userAttributes)
    if (userAttributes == null || userAttributes === undefined) {
      userAttributes = {};
    } else {
      // the api doesn't accept this field back
      delete userAttributes.email_verified;
      delete userAttributes.email;
    }
    // get password
    const newPassword = password;
    // update password
    // console.log('new passowrd challenge', newPassword, userAttributes, cognitoUser, userAttributes)
    return new Promise((resolve, reject) =>
      cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, {
        onSuccess: (session: CognitoUserSession) => {
          // console.log('completeNewPasswordChallenge success', session)
          // ('completeNewPasswordChallenge setUsername');
          this.setUsername(cognitoUser.getUsername());
          this.setCognitoUser(cognitoUser);
          this.setCognitoUserSession(session);
          resolve(true);
        },
        onFailure: function(err: Error) {
          // console.warn('completeNewPasswordChallenge onFailure', err);
          reject(err);
        },
        customChallenge: function(challengeParameters: any) {
          // console.log('completeNewPasswordChallenge customChallenge', challengeParameters)
          reject(new Error('Not able to handle custom challange'));
        },
        mfaRequired: function(codeDeliveryDetails: any) {
          // console.log('mfaRequired', codeDeliveryDetails)
          const confirmationCode = prompt('Please input verification code', '');
          if (confirmationCode) {
            cognitoUser.sendMFACode(confirmationCode, this);
          }
        },
        mfaSetup: function(challengeName: any, challengeParameters: any) {
          // console.log('mfaSetup', challengeName, challengeParameters)
          reject(new Error(' Not able to process mfaSetup ' + challengeName));
        }
      })
    );
  }

  @Action({ rawError: true })
  confirmRegistration(params: ConfirmRegistrationParams): Promise<void> {
    const poolModule = getModule(CognitoPoolModule);
    const cognitoUser = new CognitoUser({
      Pool: poolModule.cognitoUserPool,
      Username: params.username
    });
    return new Promise((resolve, reject): void => {
      cognitoUser.confirmRegistration(params.code, true, err => {
        if (!err) {
          resolve();
          return;
        }
        reject(err);
      });
    });
  }

  @Action({ rawError: true })
  resendConfirmationCode(username: string): Promise<void> {
    const poolModule = getModule(CognitoPoolModule);
    const cognitoUser = new CognitoUser({
      Pool: poolModule.cognitoUserPool,
      Username: username
    });
    return new Promise((resolve, reject) => {
      cognitoUser.resendConfirmationCode(err => {
        if (!err) {
          resolve();
          return;
        }
        reject(err);
      });
    });
  }

  @Action({ rawError: true })
  forgotPassword(username: string): Promise<void> {
    const poolModule = getModule(CognitoPoolModule);
    const cognitoUser = new CognitoUser({
      Pool: poolModule.cognitoUserPool,
      Username: username
    });
    return new Promise((resolve, reject) =>
      cognitoUser.forgotPassword({
        onSuccess() {
          resolve();
        },
        onFailure(err) {
          reject(err);
        }
      })
    );
  }

  /**
   * This is used to initiate a forgot password request
   * @param {object} commit Vuex commit function pointer.
   * @param {object} payload Parameter payload.
   * @param {string} payload.username Parameter for username.
   * @param {string} payload.code Parameter for verification code.
   * @param {string} payload.newPassword Parameter for new password.
   * @returns {void}
   */
  @Action({ rawError: true })
  confirmPassword(params: ConfirmPasswordParams): Promise<void> {
    const poolModule = getModule(CognitoPoolModule);
    const cognitoUser = new CognitoUser({
      Pool: poolModule.cognitoUserPool,
      Username: params.username
    });
    return new Promise((resolve, reject) => {
      cognitoUser.confirmPassword(params.code, params.newPassword, {
        onFailure(err) {
          reject(err);
        },
        onSuccess() {
          resolve();
        }
      });
    });
  }

  /**
   * This is used by an authenticated user to change the current password
   * @param {object} state Vuex state object.
   * @param {object} payload Parameter payload.
   * @param {string} payload.oldPassword The current password.
   * @param {string} payload.newPassword The requested new password.
   * @returns {Promise}
   */
  @Action({ rawError: true })
  async changePassword(params: ChangePasswordParams, username?: string): Promise<void> {
    // Make sure the user is authenticated
    if (this.user === null || (this.user && this.user.tokens === null)) {
      Promise.reject(new Error('User is unauthenticated'));
      return;
    }
    const usrname = username || this.username;
    const authenticationData: IAuthenticationDetailsData = {
      Username: usrname,
      Password: params.oldPassword
    }
    const res = await this.authenticateUser(authenticationData).catch((e: Error) => {
      return Promise.reject(e);
    })
    if (!res || res === undefined) return Promise.reject(new Error('No response from authentication'));
    const { cognitoUser } = res;

    return new Promise((resolve, reject) => {
      cognitoUser.changePassword(params.oldPassword, params.newPassword, (err: Error) => {
        if (!err) {
          resolve();
          return;
        }
        reject(err);
      });
      reject(new Error('Unexpected result'));
    });
  }

  // Only for authenticated users
  @Action({ rawError: true })
  updateAttributes(attributes: ObjectType) {
    return new Promise((resolve, reject) => {
      // Make sure the user is authenticated
      if (this.user === null || (this.user && this.user.tokens === null)) {
        reject(new Error('User is unauthenticated'));
        return;
      }
      const poolModule = getModule(CognitoPoolModule);
      const cognitoUser = new CognitoUser({
        Pool: poolModule.cognitoUserPool,
        Username: this.user.username
      });
      // Restore session without making an additional call to API
      // cognitoUser.signInUserSession = cognitoUser.getCognitoUserSession(this.user.tokens);
      const userSession: CognitoUserSession | null = cognitoUser.getSignInUserSession();
      if (userSession) {
        const cognitoUserAttributes: ICognitoUserAttributeData[] = Object.keys(attributes || {}).map((value: string) => {
          const attr: ICognitoUserAttributeData = {
            Name: value,
            Value: attributes[value]
          };
          return attr;
        });
        cognitoUser.updateAttributes(cognitoUserAttributes, (err: Error | undefined, s: string | undefined) => {
          if (!err) {
            return resolve(s);
          }
          return reject(err);
        });
      }
    });
  }

  // Only for authenticated users
  @Action({ rawError: true })
  fetchUserAttributes() {
    return new Promise((resolve, reject) => {
      // Make sure the user is authenticated
      if (this.user === null || (this.user && this.user.tokens === null)) {
        reject(new Error('User is unauthenticated'));
        return;
      }
      const poolModule = getModule(CognitoPoolModule);
      const cognitoUser = new CognitoUser({
        Pool: poolModule.cognitoUserPool,
        Username: this.user.username
      });
      // Restore session without making an additional call to API
      // cognitoUser.signInUserSession = cognitoUser.getCognitoUserSession(this.user.tokens);
      const userSession: CognitoUserSession | null = cognitoUser.getSignInUserSession();
      if (userSession) {
        this.setCognitoUserSession(userSession);
        cognitoUser.getUserAttributes((err, attributes) => {
          if (err) {
            reject(err);
            return;
          }
          const attributesMap = (attributes || []).reduce((previousValue: ObjectType, currentValue: CognitoUserAttribute, currentIndex: number, array: CognitoUserAttribute[]) => {
            previousValue[currentValue.getName()] = currentValue.getValue();
            return previousValue;
          }, {});
          this.setAttributes(attributesMap);
          resolve(attributesMap);
        });
      }
    });
  }

  /**
   * This is used by an authenticated user to signout of their session
   * @param {object} commit Vuex commit function.
   * @param {object} state Vuex state object.
   * @returns {Promise}
   */
  @Action({ rawError: true })
  signOut(): Promise<void> {
    return new Promise((resolve, reject) => {
      // Make sure the user is authenticated
      if (this.user === null || (this.user && this.user.tokens === null)) {
        reject(new Error('User is unauthenticated'));
        return;
      }
      const poolModule = getModule(CognitoPoolModule);
      const cognitoUser = new CognitoUser({
        Pool: poolModule.cognitoUserPool,
        Username: this.user.username
      });
      cognitoUser.signOut();
      this.init();
      resolve();
    });
  }
}
