<template>
  <section ref="activityTable" class="mb-5">
    <template v-if="displayTransactionItems">
      <v-data-table
        :items="bankStore.transactions"
        :headers="vTransactionFields"
        :items-per-page="perPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
      >
      </v-data-table>
    </template>
    <template >
      <v-container>
        <v-row>
          <v-card
            min-width="300px">
            <v-card-title>Today</v-card-title>
            <v-card-text>${{ spendingToday }}</v-card-text>
          </v-card>
          <v-card
            min-width="300px">
            <v-card-title>Last Seven Days</v-card-title>
            <v-card-text>${{ spendingPastWeek }}</v-card-text>
          </v-card>
        </v-row>
      </v-container>
    </template>
  </section>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { BankModule, NavModule, ProfileModule } from "@/store";
import { MediaQuery as MQ } from '@/MediaQuery';
import { DateTime } from "luxon";

// Define the props by using Vue's canonical way.
const ActivityComponentProps = Vue.extend({
  props: {
    transactionsFilter: String
  },
  mixins: []
});

@Component({
  components: {
    // mdbCard, mdbCardGroup, mdbCardBody, mdbCardTitle, mdbCardText, mdbContainer, mdbInput, mdbRow
  }
})
export default class ActivityComponent extends ActivityComponentProps {
  bankStore: BankModule = getModule(BankModule, this.$store);
  navStore: NavModule = getModule(NavModule, this.$store);
  profileStore: ProfileModule = getModule(ProfileModule, this.$store);
  MQ = MQ;

  defaultPerPage = 10;
  perPage = 10;
  currentPage = 0;
  sortBy = 'timeStamp';
  sortDirection = 'asc';
  sortDesc = true;
  totalRowsFiltered = -1;

  get totalRows () {
    if (this.totalRowsFiltered >= 0) {
      return this.totalRowsFiltered
    }
    return this.bankStore.transactions.length
  }
  /**
   * Fields for the transactions
   */
  get transactionFields () {
      // assemble
      var fields = []
      fields.push({ key: 'date', sortable: true })
      fields.push({ key: 'userFirstName', label: 'First Name' })
      fields.push({ key: 'locationName', label: 'Location' })
      if (this.displayTransactionItems) { fields.push({ key: 'items' }) }
      fields.push({ key: 'type' })
      fields.push({ key: 'amount' })
      // return
      return fields
  }

  /**
   * Remaking getter for Vuetify
   * Fields for the transactions
   */
   get vTransactionFields () {
      // assemble
      var fields = []
      fields.push({ value: 'date', text: 'Date' })
      fields.push({ value: 'userFirstName', text: 'First Name' })
      fields.push({ value: 'locationName', text: 'Location' })
      if (this.displayTransactionItems) { fields.push({ value: 'items', text: 'Items' }) }
      fields.push({ value: 'type', text: 'Type' })
      fields.push({ value: 'amount', text: 'Amount' })
      // return
      return fields
  }

  get displayTransactionItems() {
    return false || this.navStore.displayTransactionItems;
  }

  get spendingToday(): string {
    if (this.bankStore.transactions === undefined) return '0.00'
    let midnightDT = DateTime.local().setZone(this.navStore.timeZone).startOf('day');
    let balance = 0;
    for (const t of this.bankStore.transactions) {
      // console.log(t.type, t.timeStamp, midnightDT.valueOf() / 1000, t.timeStamp > midnightDT.valueOf() / 1000)
      if (t.type === 'transaction' && t.timeStamp > midnightDT.valueOf() / 1000) {
        balance += Math.abs(t.amount);
      }
    }
    return balance.toFixed(2);
  }

  get spendingPastWeek(): string {
    if (this.bankStore.transactions === undefined) return '0.00'
    let midnightDT = DateTime.local().setZone(this.navStore.timeZone).minus({days: 6}).startOf('day')
    let balance = 0;
    for (const t of this.bankStore.transactions) {
      if (t.type === 'transaction' && t.timeStamp > midnightDT.valueOf() / 1000) {
        balance += Math.abs(t.amount);
      }
    }
    return balance.toFixed(2);
  }

  // methods: {
  //   ...mapGetters('bank', [
  //     'getTransactions',
  //     'getOrganization'
  //   ]),
  onFiltered (filteredItems: any[]) {
    // Trigger pagination to update the number of buttons/pages due to filtering
    this.totalRowsFiltered = filteredItems.length
    this.currentPage = 1
  }
  // infiniti scrolling
  scroll () {
    window.onscroll = () => {
      let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight
      if (bottomOfWindow) {
        // add rows to the bottom when we hit the bottom of the page
        if (this.totalRows > this.perPage) {
          this.perPage = this.perPage + this.defaultPerPage
        }
        /*
        axios.get(`https://randomuser.me/api/`)
          .then(response => {
            person.push(response.data.results[0]);
          })
        */
        // console.log('activitycomponent bottomofwindow')
      }
    }
  }
    /*
    bottomOfWindow () {
      // console.log('table height: ' + this.$refs.activityTable.clientHeight)
      // console.log('window scrollTop: ' + document.documentElement.scrollTop)
      // console.log('window innnerHeight: ' + window.innerHeight)
      // console.log('window offsetHeight: ' + document.documentElement.offsetHeight)
      // console.log('window bottom: ' + document.documentElement.scrollTop + window.innerHeight)
      return (document.documentElement.scrollTop + window.innerHeight) <= document.documentElement.offsetHeight
    }
    */

  // Lifecyle Routines //
  created () {}
  mounted () {
    // for small windows
    if (MQ.sm) {
      // trigger infiniti scrolling
      this.scroll()
    // for other larger sizes
    } else {
      // adjust table height to window
      // TODO
      /*
      while (!this.bottomOfWindow() && (this.perPage > 5)) {
        // console.log('trim per page ' + this.perPage)
        this.perPage = this.perPage - 1
      }
      */
    }
    // TODO
    /*
    window.onresize = () => {
      // console.log('table height: ' + this.$refs.activityTable.clientHeight)
      // console.log('window scrollTop: ' + document.documentElement.scrollTop)
      // console.log('window innnerHeight: ' + window.innerHeight)
      // console.log('window offsetHeight: ' + document.documentElement.offsetHeight)
      // console.log('window bottom: ' + bottomOfWindow)
    }
    */
  }
}
</script>

<style>
.v-data-table .v-data-table-header tr th {
  font-size: 18px !important;
}
</style>
