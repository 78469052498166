<template>
  <div v-if="MediaQuery.smOrSmaller">
  <v-footer app fixed padless>
    <v-bottom-navigation grow color="primary">
      <v-btn @click="goHome()">
        <span>{{ $tc('nav.home') }}</span>
        <v-icon>{{ RouterIcons.HOME }}</v-icon>
      </v-btn>

      <v-btn :to="{ name: RouterNames.SITEMAP }">
        <span>{{ $tc('nav.more') }}</span>
        <v-icon>{{ RouterIcons.SITEMAP }}</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </v-footer>
  </div>
</template>
<script lang="ts">
import { Component, Mixins, } from "vue-property-decorator";
import { MediaQuery } from '@/MediaQuery';
import { RouterIcons } from '@/router/RouterIcons'
import { RouterNames } from '@/router/RouterNames'
import { RouterUtils } from '@/router/RouterUtils';

@Component({
  components: {
    // mdbCol,
    // mdbContainer,
    // mdbIcon,
    // mdbRow
  }
})
export default class HomeFooter extends Mixins(RouterUtils) {
  MediaQuery = MediaQuery;
  RouterIcons = RouterIcons;
  RouterNames = RouterNames;
}
</script>
<style scoped>

</style>
