var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "p-0", attrs: { fluid: "" } },
    [
      _vm.personReservationCount > 0
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "d-flex justify-space-between align-center",
                  attrs: { cols: "6", href: "#" },
                },
                [
                  _vm._v(" Reservations "),
                  _vm.currentUser
                    ? _c(
                        "v-chip",
                        { staticClass: "ml-2", attrs: { small: "" } },
                        [_vm._v(" " + _vm._s(_vm.personReservationCount) + " ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "v-btn-toggle",
                    { staticClass: "d-flex mx-auto", attrs: { vertical: "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", outlined: "", block: "" },
                          on: {
                            click: function ($event) {
                              return _vm.goActivityReservationPage(_vm.personId)
                            },
                          },
                        },
                        [
                          _vm._v(" New "),
                          _c("v-icon", { attrs: { right: "" } }, [
                            _vm._v("mdi-calendar"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-btn-toggle",
                    { staticClass: "d-flex mx-auto", attrs: { vertical: "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", block: "" },
                          on: {
                            click: function ($event) {
                              return _vm.goActivityReservationPage(_vm.personId)
                            },
                          },
                        },
                        [
                          _vm._v(" Make Reservaton "),
                          _c("v-icon", { attrs: { right: "" } }, [
                            _vm._v("mdi-calendar"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }