<template>
  <span style="width:100%">
    <!-- show loading spinner -->
    <v-row v-if="isLoading && (!allPersons || allPersons.length <= 0)">
      <v-col>
        <v-progress-circular indeterminate></v-progress-circular>
      </v-col>
    </v-row>
    <!-- start portion where no contributors have been entered -->
    <template v-if="navStore.allowCreateAuthorizedUsers && !isLoading && allPersons.length === 0">
      <section class="m-3">
        <h2 class="h1-responsive font-weight-bold my-2">{{ $tc('nav.home') }}</h2>
      </section>
      <v-container>
        <v-row class="my-3">
          <v-col>To get started, add a {{ lingoAuthUserSingularLC }}</v-col>
        </v-row>
        <v-row>
          <v-col>
            <template v-if="MQ.smOrSmaller">
              <v-btn x-large block color="amber" class="add-button mt-0" @click="openAddAuthorizedUser()">
                <v-icon>mdi-plus</v-icon>
                Add {{ lingoAuthUserSingularCC }}
              </v-btn>
            </template>
            <template v-if="MQ.mdOrBigger">
              <v-btn x-large color="amber" class="mx-auto add-button mt-0" @click="openAddAuthorizedUser()">
                <v-icon>mdi-plus</v-icon>
                Add {{ lingoAuthUserSingularCC }}
              </v-btn>
            </template>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <!-- // end portion where no contributors have been entered -->
    <!-- start portion where one or more contributors are in the system -->
    <template v-if="!navStore.allowCreateAuthorizedUsers || (!isLoading && allPersons.length > 0)">
      <div class="float-right" v-if="navStore.allowCreateAuthorizedUsers">
        <v-btn large class="add-button mt-0" color="light-blue" @click="openAddAuthorizedUser()">
          <v-icon>mdi-plus</v-icon>
          {{ (MQ.smOrSmaller) ? '' : `Add ${lingoAuthUserSingularCC}` }}
        </v-btn>
      </div>
      <h3 id="title" class="mb-5">{{ $tc('nav.home') }}</h3>
      <!-- Attendance App Section: Start -->
      <v-row v-if="navStore.displayParentAppLinks">
        <v-col>
          <v-card class="card-body" width="22rem">
            <v-card-title>
              <b>Parent App</b>
            </v-card-title>
            <v-card-text>Download the app to complete checkout...</v-card-text>
            <div class="flex-row ml-2">
              <a
                target="_new"
                href="https://apps.apple.com/us/app/funfangle/id1444462507?mt=8"
                style="display:inline-block;overflow:hidden;background:url(/img/icons/apple-appstore.svg) no-repeat;width:135px;height:40px;"
              ></a>
            </div>
            <div class="flex-row">
              <a
                target="_new"
                href="https://play.google.com/store/apps/details?id=com.funfangle.parent"
              >
                <img
                  alt="Get FunFangle on Google Play"
                  src="/img/icons/google-play-badge.png"
                  height="60px"
                />
              </a>
            </div>
          </v-card>
        </v-col>
        <!-- <v-col>
          <v-card class="card-body" style="width: 22rem; margin-top: 1rem;">
            <v-card-title>
              <b>Camper Photos</b>
            </v-card-title>
            <v-card-text>Photos are required to use the app for checkout. Click on the silhouette below to upload a photo of your camper. Scroll down to see the silhouettes.</v-card-text>
          </v-card>
        </v-col> -->
      </v-row>
      <!-- /Attendance App Section: End -->
      <!-- Reservations App Section: Start -->
      <v-row v-if="navStore.isFeatureEventReservationsEnabled">
        <v-col>
          <!-- <v-card class="card-body" style="width: 22rem; margin-top: 1rem;">
              <v-card-title><b>Parent App</b></v-card-title>
              <v-card-text>Download the app to complete checkout...</v-card-text>
              <div class="flex-row">
                <a target="_new" href='https://play.google.com/store/apps/details?id=com.funfangle.parent&hl=en&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Get FunFangle on Google Play' src='/img/icons/google-play-badge.png' height="60px"/></a>
              </div>
              <div class="flex-row ml-2">
                <a target="_new" href="https://apps.apple.com/us/app/funfangle/id1444462507?mt=8" style="display:inline-block;overflow:hidden;background:url(/img/icons/apple-appstore.svg) no-repeat;width:135px;height:40px;"></a>
              </div>
          </v-card>-->
        </v-col>
        <v-col>
          <!-- <v-card class="card-body" style="width: 22rem; margin-top: 1rem;">
              <v-card-title><b>Camper Photos</b></v-card-title>
              <v-card-text>Photos are required to use the app for checkout. Click on the silhouette below to upload a photo of your camper. Scroll down to see the silhouettes.</v-card-text>
          </v-card>-->
        </v-col>
      </v-row>
      <!-- /Reservations App Section: End -->
      <!-- Accounts: Start -->
      <!-- Accounts Section by Size: smaller form factors -->
      <template v-if="MQ.smOrSmaller">
        <v-row v-if="isLoading && (!allPersons || allPersons.length <= 0)">
          <v-col>
            <v-progress-circular indeterminate></v-progress-circular>
          </v-col>
        </v-row>
        <v-container :key="person.userId" v-for="(person) in allPersons">
          <!-- minimized (unopened) person -->
          <template v-if="selectedUserId !== person.userId || !navStore.userOpened">
            <v-row @click="togglePerson(person)" :id="getSafeUserId(person.userId)">
              <!-- TODO Frank: What is the naming convention for the image resizing. Is it like below? -->
              <v-col v-if="displayPhotoOption" class="px-0 py-1 mt-1">
                <v-img :src="person.imageUrl70" contain height="70" alt="Photo" />
              </v-col>
              <v-col class="px-0 mt-0" cols="8" v-if="person && person.userId">
                <v-container class="mt-0 p-0">
                  <v-row>
                    <v-col class="pr-1 pb-0" cols="10">
                      <h5>{{ person.firstName }} {{ person.lastName }}</h5>
                    </v-col>
                    <v-col class="px-1">
                      <v-icon>mdi-dots-horizontal</v-icon>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col v-if="navStore.isFeatureSpendingAccountsEnabled">
                      {{ $n(userContributionsByCamper[person.userId] || 0, 'currency') }}
                      <template
                        v-if="getAuthUserScheduledAllocationsBalance(person.userId) > 0"
                      >
                        <span style="color: #bebebe">
                          plus
                          <a
                            @click="goBankAccountPage(selectedUserId)"
                          >{{ $n(getAuthUserScheduledAllocationsBalance(person.userId), 'currency') }}</a> future
                        </span>
                      </template>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </template>
          <!-- maximized (opened) person -->
          <template v-if="selectedUserId === person.userId && navStore.userOpened">
            <v-card :id="getSafeUserId(person.userId)">
              <v-card-title @click="togglePerson(person)">
                {{ person.firstName }} {{ person.lastName }}
                <v-spacer></v-spacer>
                <a
                  href="/account"
                  @click.prevent.stop="goAccountPage()"
                >
                  <v-icon>mdi-pencil</v-icon>
                </a>
              </v-card-title>
              <v-divider v-if="navStore.isFeatureSpendingAccountsEnabled"></v-divider>

              <!-- if not registered, and registration required -->
              <v-list v-if="mustFirstReserve(currentUser)">
                <v-list-item
                  v-if="navStore.isFeatureReservationsEnabled"
                  @click.prevent.stop="goReservationsPage()"
                  href="/register"
                  class="text-center"
                >
                  <v-btn x-large :block="!MQ.mdOrBigger" color="amber" class="mx-auto add-button mt-0" @click="goReservationsPage()">
                    <v-icon>mdi-plus</v-icon>
                    Register
                  </v-btn>
                </v-list-item>
              </v-list>
              <!-- if registered, or registration not required -->
              <v-list v-else>
                <v-list-item
                  v-if="navStore.isFeatureSpendingAccountsEnabled && currentUser && currentUser.userId"
                  @click.prevent.stop="goBankAccountPage(person.userId)"
                  href="/bank"
                  class="d-flex justify-content-between align-items-center"
                >
                  <available-balance-card
                    :balance="userContributionsByCamper[currentUser.userId] || 0"
                    buttonsLayout="only-manage-funds"
                  />
                </v-list-item>

                <v-divider v-if="navStore.isFeatureHealthEnabled"></v-divider>
                <v-list-item
                  v-if="navStore.isFeatureHealthEnabled && currentUser && currentUser.userId"
                  class="d-flex justify-content-between align-items-center"
                >
                  <health-profile-card />
                </v-list-item>
                <v-divider v-if="navStore.isFeatureCarePackageEnabled"></v-divider>
                <v-list-item
                  v-if="navStore.isFeatureCarePackageEnabled && person && person.userId"
                  href="/care"
                  @click.prevent.stop="goCarePackagesPage()"
                  class="d-flex justify-content-between align-items-center"
                >
                  <!-- TODO: Why did this need the userId param?? @click.prevent.stop="goCarePackagesPage(person.userId)" -->
                  <!-- {{ $tc('nav.care') }} -->
                  <!--<v-badge color="primary" pill>2</v-badge>-->
                  <care-package-card />
                </v-list-item>

                <v-divider v-if="navStore.isFeatureEventReservationsEnabled"></v-divider>
                <v-list-item v-if="navStore.isFeatureEventReservationsEnabled">
                  <event-reservations-card
                    :reservations="reservations"
                    :showReservations="showReservations"
                    :currentUser="currentUser"
                  />
                </v-list-item>

                <v-divider v-if="navStore.featureEventEntryControlEnabled"></v-divider>
                <v-list-item v-if="navStore.featureEventEntryControlEnabled">
                  <entry-control-card />
                </v-list-item>
                <!--
                TODO: Contributors
                <v-list-group-item
                  v-if="navStore.isFeatureSpendingAccountsEnabled"
                  @click.prevent.stop="goContributorsPage(person.userId)"
                  href="/contributors"
                  class="d-flex justify-content-between align-items-center"
                >
                  {{ $tc('nav.bank.contributors') }}
                  <v-badge color="primary" pill>{{currentContributors.length}}</v-badge>
                </v-list-group-item> -->
              </v-list>
              <profile-photo :person="currentUser" />
            </v-card>
          </template>
        </v-container>
      </template>
      <!--
        ** Accounts (continued) by Size: Section for medium to large form factors
      -->
      <template v-if="MQ.mdOrBigger">
        <!-- TODO: Change back from 1000 to 9 -->
        <v-row v-if="allPersons.length <= 10000 && !((allPersons.length <= 1) && (navStore.userOpened))">
          <v-col
            cols="12"
            sm="6"
            v-bind:key="person.userId"
            v-for="(person) in allPersons"
          >
            <AuthorizedPersonMinimized
              :person="person"
              @togglePerson="togglePerson"
              :id="getSafeUserId(person.userId)"
              buttons="less"
              :isCard="selectedUserId === person.userId && navStore.userOpened"
            />
          </v-col>
        </v-row>
        <!-- carousel of authorized persons -->
        <!-- TODO: Figure out for MdbVue 6.x -->
        <!-- <v-row fluid v-if="allPersons.length > 9">
          <v-col>
            <authorized-persons-carousel
              :persons="allPersons"
              :userOpened="navStore.userOpened"
              @togglePerson="togglePerson"
            />
          </v-col>
        </v-row> -->
        <v-row v-if="navStore.userOpened && currentUser != null">
          <v-col>
            <h3 class="mt-2">{{ currentUser.firstName }} {{ currentUser.lastName }}</h3>
          </v-col>
          <v-col v-if="(allPersons.length >= 1)">
            <div class="float-right mt-4">
              <a href="#" @click.prevent="togglePerson(currentUser)">(close)</a>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <template v-if="navStore.userOpened && currentUser != null">
              <v-card no-body class="mt-2">
                <v-row>
                  <v-col md="6" lg="5">
                    <profile-photo :person="currentUser" />
                  </v-col>
                  <v-col md="6" lg="7">
                    <!-- if not registered, and registration required -->
                    <v-row v-if="mustFirstReserve(currentUser)">
                      <v-col cols="12" sm="12" md="12" lg="6">
                        <v-card class="mb-lg-1 m-3 mr-2">
                          <v-card-text>
                              <v-alert class="info-light-bg" :block="MQ.smOrSmaller">
                                <v-icon class="mr-2">mdi-information</v-icon>
                                Currently not registered.
                              </v-alert>
                              <v-btn x-large color="amber" class="mx-auto add-button mt-0" @click="goReservationsPage()" :block="MQ.smOrSmaller">
                                <v-icon>mdi-plus</v-icon>
                                <!-- TODO: Convert Attendance Icon to Vuetify <v-icon :icon="RouterIcons.ATTENDANCE" class="mx-1" /> -->
                                Register
                              </v-btn>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                    <!-- if registered, or registration not required -->
                    <v-row v-else>
                      <v-col md="12" lg="6" v-if="currentUser && currentUser.userId && (navStore.isFeatureSpendingAccountsEnabled || navStore.isFeatureHealthEnabled || navStore.isFeatureCarePackageEnabled)">
                        <v-card v-if="navStore.isFeatureSpendingAccountsEnabled" class="mb-lg-1 m-3">
                          <v-card-text>
                            <available-balance-card
                              :balance="userContributionsByCamper[currentUser.userId] || 0"
                              buttonsLayout="only-manage-funds"
                            />
                          </v-card-text>
                        </v-card>
                        <v-card v-if="navStore.isFeatureHealthEnabled" class="mb-lg-1 m-3">
                          <v-card-text>
                            <health-profile-card />
                          </v-card-text>
                        </v-card>
                        <v-card v-if="navStore.isFeatureCarePackageEnabled" class="mb-lg-1 m-3">
                          <v-card-text>
                            <care-package-card />
                          </v-card-text>
                        </v-card>
                      </v-col>
                      <v-col md="12" lg="6" v-if="navStore.isFeatureSpendingAccountsEnabled">
                        <!-- TODO <v-card class="mb-lg-1 m-3"><escrow-balance-card hideButtons :balance="currentUser.escrowBalance" /></v-card> -->
                        <!-- only show the contributor card if you are the account holder -->
                        <v-card
                          class="mb-lg-1 m-3"
                          v-if="currentUser.accountHolder === userId"
                        >
                          <v-card-text>
                            <contributors-card
                              :contributors="currentContributors"
                              :showContributors="MQ.lg"
                            />
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </template>
          </v-col>
        </v-row>
      </template>
      <!-- commenting this section out because it will never be displayed -->
      <!-- disable prior layout -->
      <!-- <template v-if="false === true"> -->
        <!-- Accounts (continued) by Size: larger form factors -->
        <!-- <template v-if="MQ.lg"> -->
          <!-- <v-container fluid v-if="allPersons.length > 0"> -->
            <!-- carousel of authorized persons -->
            <!-- <template> -->
              <!-- TODO: Figure out for MdbVue 6.x -->
              <!-- <authorized-persons-carousel
                :persons="allPersons"
                :userOpened="navStore.userOpened"
                @togglePerson="togglePerson"
              /> -->
              <!-- <v-col
                lg="4"
                md="6"
                class="mb-lg-1 mb-4"
                v-bind:key="person.userId"
                v-for="(person) in allPersons"
              >
                <AuthorizedPersonMinimized
                  :person="person"
                  @togglePerson="togglePerson"
                  :id="getSafeUserId(person.userId)"
                  buttons="less"
                  :isCard="selectedUserId === person.userId && navStore.userOpened"
                />
              </v-col>
            </template>
          </v-container> -->
          <!-- opened person -->
          <!-- <template v-if="navStore.userOpened && currentUser != null">
            <v-container class="my-4 mx-auto" fluid>
              <v-row>
                <v-col>
                  <div class="float-right">
                    <a href="#" @click.prevent="togglePerson(currentUser)">(close)</a>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-card no-body class="mt-2">
                    <v-media>
                      <h3
                        class="mt-2"
                      >{{ currentUser.firstName }} {{ currentUser.lastName }}</h3>
                      <div slot="aside" class="m-2">
                        <profile-photo :person="currentUser" />
                      </div>
                      <v-container class="mb-2" v-if="navStore.isFeatureSpendingAccountsEnabled">
                        <v-card-group deck>
                          <v-card class="mb-lg-1 m-3">
                            <v-card-text>
                              <available-balance-card
                                :balance="userContributionsByCamper[currentUser.userId] || 0"
                              />
                            </v-card-text>
                          </v-card> -->
                          <!-- TODO <v-card class="mb-lg-1 m-3"><escrow-balance-card hideButtons :balance="currentUser.escrowBalance" /></v-card> -->
                          <!-- only show the contributor card if you are the account holder -->
                          <!-- <v-card
                            class="mb-lg-1 m-3"
                            v-if="currentUser.accountHolder === userId"
                          >
                            <v-card-text>
                              <contributors-card :contributors="currentContributors" />
                            </v-card-text>
                          </v-card>
                        </v-card-group>
                      </v-container>
                      <v-container class="mb-2" v-if="navStore.isFeatureEventReservationsEnabled">
                        <v-card-group deck>
                          <v-card class="mb-lg-1 m-3">
                            <v-card-text>
                              <event-reservations-card
                                :reservations="reservations"
                                :showReservations="showReservations"
                              />
                            </v-card-text>
                          </v-card>
                        </v-card-group>
                      </v-container>
                      <v-container class="mb-2" v-if="navStore.featureEventEntryControlEnabled">
                        <v-card-group deck>
                          <v-card class="mb-lg-1 m-3">
                            <v-card-text>
                              <entry-control-card />
                            </v-card-text>
                          </v-card>
                        </v-card-group>
                      </v-container>
                    </v-media>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </template> -->
        <!-- /Accounts: End -->
      <!-- </template> -->
      <br />
      <template v-if="navStore.featureSpendingAccountsEnabled">
        <h4>{{ $tc('phrase.recent_activity') }}</h4>
        <activity-component :transactions-filter="transactionsFilter" />
      </template>
    </template>
  </span>
</template>
<script lang="ts">
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { User, AuthorizedPerson } from "@fgl/funfangle-sdk/dist/rest/profile";
import { Booking } from '@fgl/funfangle-sdk/dist/rest/event';
import { MediaQuery as MQ } from "@/MediaQuery";
import { getModule } from "vuex-module-decorators";
import { BankModule, EventModule, NavModule, ProfileModule } from "@/store";
import ActivityComponent from "@/components/ActivityComponent.vue";
import AuthorizedPersonMinimized from "@/components/home/AuthorizedPersonMinimized.vue";
import AvailableBalanceCard from "@/components/banking/AvailableBalanceCard.vue";
import CarePackageCard from "@/views/shop/CarePackageCard.vue";
import EntryControlCard from "@/components/events/EntryControlCard.vue";
import EventReservationsCard from "@/components/events/EventReservationsCard.vue";
// import ContributorsCard from "@/components/banking/ContributorsCard.vue";
import HealthProfileCard from "@/views/health/HealthProfileCard.vue";
// TODO import EscrowBalanceCard from '@/components/banking/EscrowBalanceCard'
import ProfilePhoto from "@/components/profiles/ProfilePhotoComponent.vue";
import { RouterIcons } from "@/router/RouterIcons";
import { RouterNames } from "@/router/RouterNames";
import { RouterUtils } from "@/router/RouterUtils";
var VueScrollTo = require("vue-scrollto");

@Component({
  components: {
    ActivityComponent,
    AuthorizedPersonMinimized,
    AvailableBalanceCard,
    CarePackageCard,
    // ContributorsCard,
    EntryControlCard,
    EventReservationsCard,
    // TODO EscrowBalanceCard,
    HealthProfileCard,
    ProfilePhoto
  }
})
export default class Home extends mixins(RouterUtils) {
  bankStore: BankModule = getModule(BankModule, this.$store);
  eventStore: EventModule = getModule(EventModule, this.$store);
  navStore: NavModule = getModule(NavModule, this.$store);
  profileStore: ProfileModule = getModule(ProfileModule, this.$store);
  allPersons: User[] = [];
  isLoading = true;
  MQ = MQ;
  personOpened = false;
  RouterIcons = RouterIcons;
  RouterNames = RouterNames;

  get userId() {
    return this.profileStore.userId;
  }
  get userContributionsByCamper() {
    return this.bankStore.userContributionByCamper;
  }
  get authPersons() {
    return this.profileStore.authorizedActiveUsers;
  }
  get contributingTo() {
    return this.bankStore.contributingToActive;
  }
  get currentContributors(): AuthorizedPerson[] {
    if (this.profileStore.contributors == null || this.profileStore.contributors === undefined || this.navStore.selectedUserId === undefined || this.navStore.selectedUserId == null) return [];
    // get their contributors
    let contributors = this.profileStore.contributors.filter(contributor => {
      return (
        contributor?.blackListIds?.indexOf(this.navStore.selectedUserId) === -1
      );
    });
    return contributors;
  }
  get currentUser(): User {
    if (this.navStore.selectedUser == null) return new User();
    return this.navStore.selectedUser;
  }
  get displayPhotoOption() {
    return this.navStore.displayPhotoOption;
  }
  get lingoAuthUserSingularCC() {
    return this.navStore.lingoAuthUserSingularCC;
  }
  get lingoAuthUserSingularLC() {
    return this.navStore.lingoAuthUserSingularLC;
  }
  get reservations(): Booking[] {
    return this.eventStore.bookings;
  }
  get selectedUserId(): string {
    return this.navStore.selectedUserId;
  }
  get showReservations(): boolean {
    return this.MQ.lg;
  }
  get transactionsFilter(): string {
    if (this.navStore.selectedUser == null) return "";
    return this.navStore.selectedUser.firstName || "";
  }
  get userOpened() {
    return this.navStore.userOpened;
  }

  // fetch the data once the userId is loaded
  @Watch("userId")
  onUserIdChange(): void {
    this.loadData();
  }
  @Watch("authPersons")
  onAuthPersonsChange(): void {
    this.loadData();
  }
  @Watch("contributingTo")
  onContributingToChange(): void {
    this.loadData();
  }

  // ...mapActions(['resetPersonOpened']),
  //   ...mapGetters('bank', [
  //     'getAuthorizedActivePersons',
  //     'getContributingToActive',
  //     'getScheduledAllocations',
  //     'getOrganization']),
  //   ...mapGetters('booking', ['getReservationItems']),
  //   ...mapGetters('organization', ['getOrgType']),
  //   ...mapGetters('user', [
  //     'getUserContributionByCamper',
  //     'getContributors',
  //     'getViewedTutorial'
  //   ]),
  //   ...mapMutations([
  //     'setCurrentPerson',
  //     'setCurrentPersonId',
  //     'setPersonOpened'
  //   ]),
  getSafeUserId(userId?: string): string {
    if (!userId || userId === undefined) return "idUNSET"
    return "id" + userId.replace(new RegExp(/-/, "g"), "");
  }
  getAuthUserScheduledAllocationsBalance(authUserId: string): number {
    // get scheduled allocations for user
    let scheduledAllocations = this.bankStore.scheduledAllocations.filter(item => {
      return item.authUserId === authUserId;
    });
    let bal = 0.0;
    for (var i in scheduledAllocations) {
      var allocation = scheduledAllocations[i];
      if (allocation.status === "pending") {
        bal = bal + allocation.amount;
      }
      if (allocation.status === "reversed") {
        bal = bal - allocation.amount;
      }
    }
    return bal;
  }
  async loadData(): Promise<void> {
    this.isLoading = true;
    this.allPersons = this.profileStore.authorizedActiveUsers.concat(
      this.bankStore.contributingToActive
    );
    // set first person to "current"
    if (this.allPersons && this.allPersons.length > 0) {
      this.navStore.setSelectedUser(this.allPersons[0]);
      // this.setPersonOpened(true);
    }
    this.isLoading = false;
  }
  openAddAuthorizedUser(): void {
    this.goAuthorizedUserAddPage();
  }
  resetPerson(): void {
    this.navStore.setSelectedUser(null);
    this.personOpened = false;
  }
  // only have one person opened at a time
  togglePerson(person: User): void {
    // console.log('HomeCamp | togglePerson ', person);
    // console.log('toggle person ', person)
    // if nothing is open, then set and open current person
    // or, if another person is open, then close and open this new person
    if (
      this.navStore.isUserSelected === false ||
      (this.navStore.isUserSelected === true &&
        this.navStore.selectedUserId !== person.userId)
    ) {
      this.navStore.setSelectedUser(person);
      // close everything
    } else {
      this.navStore.resetUserSelected();
    }
    // scroll to opened person if it's the small view
    if (MQ.sm) {
      VueScrollTo.scrollTo("#" + this.getSafeUserId(person.userId), 500, {
        offset: -60
      });
    }
  }

  //
  //
  //

  mustFirstReserve(person: User): boolean {
    if (this.navStore.mustFirstReserve !== true) return false;
    if (this.navStore.isFeatureReservationsEnabled !== true) return false;
    if (person.sessionCabinPairs) {
      for (const pair of person.sessionCabinPairs) {
        if (pair && pair.session !== undefined && pair.session !== '') {
          return false;
        }
      }
    }
    return true;
  }

  goReservationsPage(): void {
    this.$router.push({ name: RouterNames.RESERVATION });
  }

  //
  //
  //

  mounted(): void {
    this.navStore.resetUserSelected();
    window.scrollTo(0, 0);
    // load all of the data
    this.loadData();
    /*
     * TODO:
        - Needs to not trigger when not size=lg because element not found
        - Needs to not refresh Home screen, causing selected person to be minimized

    setTimeout(() => {
      if (!this.getViewedTutorial()) {
        this.$root.$emit('launchTour')
      }
    }, 3000)
    */
  }
}
</script>
<style>
.card-img {
  max-height: 150px;
}
.ff-card-body-md {
  padding: 10px;
}
/* .person-card {
  max-width: 400px !important;
  min-width: 200px;
} */
</style>
<style scoped>
.b-btn-amber {
    background-color: #FFBF00 !important; /* Amber */
    color: black; /* Choosing black for better contrast */
}

.b-btn-amber:hover {
    background-color: #FFC600 !important;
}

.b-btn-add-more {
  background-color: #33b5e5 !important;
  color: black;
}

.b-btn-add-more:hover {
    background-color: #2db4e4 !important;
}
</style>
