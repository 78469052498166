import { BookingParticipant, BookingParticipantCount, EventOccurance, EventPriceSetting, EventSeries } from "@fgl/funfangle-sdk/dist/rest/event";
import { Organization, User } from "@fgl/funfangle-sdk/dist/rest/profile";
import { DateTime } from "luxon";

export class EventPricing {


  public calcEventPrice(org: Organization, series: EventSeries | null, events: EventOccurance[], participants: User[], isResident: boolean | undefined, participantCount: BookingParticipantCount): number | null {
    // console.log('calcEventPrice', series, events, participants, isResident);

    // guest pricing
    if (
      participantCount !== undefined &&
      participantCount.guests !== undefined &&
      participantCount.guests > 0 &&
      org.features !== undefined &&
      org.features.eventRegistration !== undefined &&
      org.features.eventRegistration.guestDefaultPrice !== undefined &&
      org.features.eventRegistration.guestDefaultPrice !== undefined &&
      org.features.eventRegistration.guestDefaultPrice.basePrice !== undefined &&
      org.features.eventRegistration.guestDefaultPrice.basePrice > 0
    ) {
      return participantCount.guests * org.features.eventRegistration.guestDefaultPrice.basePrice;
    }

    if (!series || series === undefined) return null;
    if (series.eventPriceSettings === undefined) return null;
    // compute the price if they are a non-resident
    // compute the price based on the event price times the number of participants
    // if there's a max price then cap the price
    // if they are required to pay the software fee then charge them...

    let priceSetting: EventPriceSetting | undefined;
    if (series != null && series !== undefined && series.eventPriceSettings !== undefined) priceSetting = series.eventPriceSettings;
    if (events != null && events !== undefined && events.length > 0) {
      if (events[0].priceSettings !== undefined) priceSetting = events[0].priceSettings;
    }
    if (priceSetting === undefined) return null;

    // non-resident pricing
    if (
      isResident !== undefined &&
      isResident === true &&
      participants != null &&
      priceSetting.residentPrice !== undefined
    ) {
      // console.log('return resident price')
      return participants.length * priceSetting.residentPrice;
    }

    // non-resident pricing
    if (
      isResident !== undefined &&
      isResident === false &&
      participants != null &&
      priceSetting.nonResidentPrice !== undefined
    ) {
      // console.log('return non resident price')
      return participants.length * priceSetting.nonResidentPrice;
    }

    const price = priceSetting.basePrice;

    // non-member pricing
    // if (
    //   price === undefined &&
    //   participants != null &&
    //   series != null &&
    //   series.eventPrice !== undefined &&
    //   this.selectedPaymentMethodSlug !== "member"
    // ) {
    //   price =
    //     (price || 0) +
    //     participants.length * series.eventPrice;
    // }
    // // member pricing, if present
    // if (
    //   participants != null &&
    //   series != null &&
    //   series.eventPriceMember !== undefined &&
    //   series.eventPriceMember > 0 &&
    //   this.profileStore.memberId !== undefined &&
    //   this.profileStore.memberId !== ""
    // ) {
    //   price =
    //   participants.length * series.eventPriceMember;
    // }
    // family pricing
    // if (
    //   price !== undefined &&
    //   series != null &&
    //   series.eventPriceMax !== undefined &&
    //   price > series.eventPriceMax
    // ) {
    //   price = series.eventPriceMax;
    // }
    // user pays model
    // only has to pay annually
    // if (
    //   this.profileStore.currOrganization.billingStatus === "USER_PAYS_299" &&
    //   (this.profileStore.billingExpiresAt === undefined ||
    //     this.profileStore.billingExpiresAt < Date.now() / 1000)
    // ) {
    //   price = (price || 0) + 2.99;
    // }
    return price || null;
  }

  public calcTotalPrice(participants: BookingParticipant[]): number | null {
    // console.log('calcTotalPrice', participants);
    if (participants == null || participants === undefined || participants.length === 0) return null;
    let totalPrice = 0;
    for (const p of participants) {
      totalPrice += p.price || 0;
    }
    // console.log('calcTotalPrice totalPrice=', totalPrice);
    return totalPrice;
  }

  public priceAgeRangeIndex(org: Organization, series: EventSeries | null, events: EventOccurance[], index: number, isResident = false, participantCount: BookingParticipantCount): number | null {
    if (!series || series === undefined) return null;
    if (series.eventPriceSettings === undefined) return null;
    // compute the price if they are a non-resident
    // compute the price based on the event price times the number of participants
    // if there's a max price then cap the price
    // if they are required to pay the software fee then charge them...

    let priceSetting: EventPriceSetting | undefined;
    if (series != null && series !== undefined && series.eventPriceSettings !== undefined) priceSetting = series.eventPriceSettings;
    if (events != null && events !== undefined && events.length > 0) {
      if (events[0].priceSettings !== undefined) priceSetting = events[0].priceSettings;
    }
    if (priceSetting === undefined) return null;
    if (priceSetting.ageRange === undefined) return null;
    if (priceSetting.ageRange.length <= index) return null;

    const ageRange = priceSetting.ageRange[index];
    if (ageRange === undefined) return null;

    // console.log('priceAgeRangeIndex', ageRange, isResident, participantCount);

    // resident pricing
    if (isResident !== undefined && isResident === true && ageRange.residentPrices !== undefined) {
      for (const bracket of ageRange.residentPrices) {
        if (bracket.price !== undefined && (bracket.qtyLower || 0) <= (participantCount.total || 0) && (participantCount.total || 0) <= (bracket.qtyUpper || 999)) {
          // console.log('return resident price', bracket.price, 'bracket', bracket.qtyLower || 0, bracket.qtyUpper || 999);
          return Number(bracket.price);
        }
      }
    }
    if (
      isResident !== undefined &&
      isResident === true &&
      ageRange.residentPrice !== undefined
    ) {
      // console.log('return resident price', ageRange.residentPrice)
      return ageRange.residentPrice;
    }

    // non-resident pricing
    if (isResident !== undefined && isResident === false && ageRange.nonResidentPrices !== undefined) {
      for (const bracket of ageRange.nonResidentPrices) {
        if (bracket.price !== undefined && (bracket.qtyLower || 0) <= (participantCount.total || 0) && (participantCount.total || 0) <= (bracket.qtyUpper || 999)) {
          // console.log('return non-resident price', bracket.price, 'bracket', bracket.qtyLower || 0, bracket.qtyUpper || 999);
          return Number(bracket.price);
        }
      }
    }
    if (
      isResident !== undefined &&
      isResident === false &&
      ageRange.nonResidentPrice !== undefined
    ) {
      // console.log('return non-resident price', ageRange.nonResidentPrice)
      return ageRange.nonResidentPrice;
    }

    // console.log('return base price', ageRange.basePrice)
    return priceSetting.basePrice || null;
  }

  public priceGuest(org: Organization, session: EventSeries | null, events: EventOccurance[]): number | null {
    if (session == null || events == null) {
      // ...
    }
    if (
      org.features !== undefined &&
      org.features.eventRegistration !== undefined &&
      org.features.eventRegistration.guestDefaultPrice !== undefined &&
      org.features.eventRegistration.guestDefaultPrice !== undefined &&
      org.features.eventRegistration.guestDefaultPrice.basePrice !== undefined &&
      org.features.eventRegistration.guestDefaultPrice.basePrice > 0
    ) {
      return org.features.eventRegistration.guestDefaultPrice.basePrice;
    }
    return null;
  }

  public priceParticipant(org: Organization, session: EventSeries | null, events: EventOccurance[], participant: User, isResident: boolean | undefined): number | null {
    // console.log('priceParticipant series=', session, 'events=', events, participant, isResident);
    if (!session || session === undefined) return null;
    if (session.eventPriceSettings === undefined) return null;
    // compute the price if they are a non-resident
    // compute the price based on the event price times the number of participants
    // if there's a max price then cap the price
    // if they are required to pay the software fee then charge them...

    // console.log('priceParticipant session.eventPriceSettings=', session.eventPriceSettings);
    let quantity = 1;
    let priceSetting: EventPriceSetting | undefined;
    if (session != null && session !== undefined && session.eventPriceSettings !== undefined) priceSetting = session.eventPriceSettings;
    if (events != null && events !== undefined && events.length > 0) {
      // console.log(' events[0]priceSetting 1=', events[0].priceSettings !== undefined);
      // console.log(' events[0]priceSetting 3=', Object.keys(events[0].priceSettings || {}).length > 0);
      if (events[0].priceSettings !== undefined && Object.keys(events[0].priceSettings).length > 0) {
        priceSetting = events[0].priceSettings;
      }
      if (priceSetting !== undefined && priceSetting.basePrice === undefined) {
        if (priceSetting.nonResidentPrice !== undefined) priceSetting.basePrice = priceSetting.nonResidentPrice;
        else priceSetting.basePrice = session.eventPriceSettings.basePrice;
      }
      quantity = events.length;
    }
    // console.log(' priceSetting=', priceSetting);
    if (priceSetting === undefined) return null;

    // console.log('range=', priceSetting.ageRange);
    if (priceSetting.ageRange !== undefined) {
      let age = -1;
      // console.log('birthISO=', participant.birthISO);
      if (participant.birthISO !== undefined) {
        const dt = DateTime.fromISO(participant.birthISO);
        age = (Date.now() - dt.valueOf()) / 1000 / 60 / 60/ 24 / 365;
      } else if (participant.age !== undefined) {
        ({ age } = participant);
      }
      // console.log('age=', age);
      if (age >= 0) {
        for (const range of priceSetting.ageRange) {
          if ((range.lbound || 0) <= age && age <= (range.ubound || 0) && (range.ubound !== undefined)) {
            if (range.residentPrice !== undefined && range.nonResidentPrice !== undefined) {
              // console.log('price=', (isResident ? range.residentPrice : range.nonResidentPrice));
              return Number(isResident ? range.residentPrice : range.nonResidentPrice) * quantity;
            }
          }
        }
      }
    }

    // resident pricing
    if (
      isResident !== undefined &&
      isResident === true &&
      priceSetting.residentPrice !== undefined
    ) {
      // console.log('return resident price')
      return Number(priceSetting.residentPrice) * quantity;
    }

    // non-resident pricing
    if (
      isResident !== undefined &&
      isResident === false &&
      priceSetting.nonResidentPrice !== undefined
    ) {
      // console.log('return non resident price')
      return Number(priceSetting.nonResidentPrice) * quantity;
    }

    const price = Number(priceSetting.basePrice);
    // console.log(' - base', priceSetting.basePrice);
    return price  * quantity;
  }
}
