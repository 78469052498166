<template>
  <v-container fluid class="p-0">
    <!-- show if has existing reservations -->
    <v-row v-if="personReservationCount > 0">
      <v-col cols="6" href="#" class="d-flex justify-space-between align-center">
        Reservations
        <v-chip v-if="currentUser" class="ml-2" small>
          {{ personReservationCount }}
        </v-chip>
      </v-col>

      <v-col cols="6">
        <v-btn-toggle vertical class="d-flex mx-auto">
          <v-btn
            color="primary"
            outlined
            block
            @click="goActivityReservationPage(personId)"
          >
            New
            <v-icon right>mdi-calendar</v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <!-- show if has zero existing reservations -->
    <v-row v-else>
      <v-col>
        <v-btn-toggle vertical class="d-flex mx-auto">
          <v-btn
            color="primary"
            block
            @click="goActivityReservationPage(personId)"
          >
            Make Reservaton
            <v-icon right>mdi-calendar</v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { RouterUtils } from '@/router/RouterUtils'
import { Booking } from "@fgl/funfangle-sdk/dist/rest/event";
import { User } from "@fgl/funfangle-sdk/dist/rest/profile";

// Define the props by using Vue's canonical way.
const EventReservationsCardProps = Vue.extend({
  props: {
    'reservations': {
      type: Array
    },
    'hideButtons': {
      type: Boolean,
      default: false
    },
    'showReservations': {
      type: Boolean,
      default: true
    },
    'currentUser': {
      type: User,
    }
  },
  mixins: [RouterUtils]
});

@Component({
  components: {
    // mdbBtn,
    // mdbIcon,
    // VBtn
  }
})
export default class EventReservationsCard extends EventReservationsCardProps {

  get personId () {
      return this.$store.state.currentPersonId
  }

  get personReservations () {
      if (this.reservations == null || this.reservations === undefined) return [];
      if (this.currentUser == null || this.currentUser === undefined || this.currentUser.userId === undefined) return [];

      let rs = [];
      for (let reservation of this.reservations as Booking[]) {
        if (reservation.authUserId === this.currentUser.userId ||
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ((reservation as any).sk !== undefined && (reservation as any).sk.indexOf(this.$store.state.currentPersonId) >= 0))
        {
          rs.push(reservation);
        }
      }
      return rs;
  }

  // return count of contributors
  get personReservationCount () {
      return this.personReservations.length;
  }

  // return whether to show the buttons or not
  get showButtons () {
    if (
      this.hideButtons === true
    ) {
      return false
    }
    return true
  }

  // lifecycle
  mounted() {
    // ..
  }
}
</script>

<style>
</style>
