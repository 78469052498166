<template>
  <div v-if="MediaQuery.smOrSmaller">
    <v-footer app fixed class="pa-0">
      <v-bottom-navigation grow color="blue">
        <v-btn @click="goHome()" text>
          <span class="d-flex flex-column align-center">
            <v-icon>{{ RouterIcons.HOME }}</v-icon>
            <span class="mt-1">{{ $tc('nav.home') }}</span>
          </span>
        </v-btn>

        <v-btn v-if="true" @click="goLodgingStays()" text>
          <span class="d-flex flex-column align-center">
            <v-icon>{{ RouterIcons.ACCOUNT_LODGING_STAYS }}</v-icon>
            <span class="mt-1">Stays</span>
          </span>
        </v-btn>

        <v-btn v-if="true" @click="goLodgingUnits()" text>
          <span class="d-flex flex-column align-center">
            <v-icon>{{ RouterIcons.ACCOUNT_LODGING_UNITS }}</v-icon>
            <span class="mt-1">{{ $tc('Properties') }}</span>
          </span>
        </v-btn>

        <v-btn :to="{ name: RouterNames.SITEMAP }" text>
          <span class="d-flex flex-column align-center">
            <v-icon>{{ RouterIcons.SITEMAP }}</v-icon>
            <span class="mt-1">{{ $tc('nav.more') }}</span>
          </span>
        </v-btn>
      </v-bottom-navigation>
    </v-footer>
  </div>
</template>
<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { LingoModule, NavModule } from "@/store";
import { MediaQuery } from '@/MediaQuery';
import { RouterIcons } from '@/router/RouterIcons'
import { RouterNames } from '@/router/RouterNames'
import { RouterUtils } from '@/router/RouterUtils';

@Component({
  components: {
    // mdbCol,
    // mdbContainer,
    // mdbIcon,
    // mdbRow
  }
})
export default class LodgingFooterMenu extends Mixins(RouterUtils) {
  lingo: LingoModule = getModule(LingoModule, this.$store);
  navStore: NavModule = getModule(NavModule, this.$store);
  MediaQuery = MediaQuery;
  RouterIcons = RouterIcons;
  RouterNames = RouterNames;

  get displayLodgingStays() {
    return this.navStore.displayLodgingStays;
  }

  get displayLodgingUnits() {
    return this.navStore.displayLodgingUnits;
  }
}
</script>
<style scoped>

</style>
