<template>
  <!-- <v-container style="padding: 0"> -->
    <v-container fluid class="p-0" v-if="!showCustomMessage">
      <!-- <v-row @click.prevent.stop="toggleActiveItem(0)" class="margin-one">
        <v-col href="#" class="d-flex justify-content-between">
          Available Balance
          <span>
            {{ $n(balance, 'currency') }}
            <template
              v-if="scheduledAllocationsBalance > 0 && $route.name !== 'bankAccount'"
            >*</template>
          </span>
        </v-col>
      </v-row>
      <v-row class="margin-one">
        <v-col style="color: #bebebe"></v-col>
      </v-row> -->
      <!--
      <v-row v-if="(activeItem != 0 || showButtons) && (buttonsLayout !== 'only-manage-funds')" @click.prevent.stop="toggleActiveItem(0)">
        <v-col class="text-right"><v-icon :icon="RouterIcons.BANK_DEPOSITS" class="mx-1" /> <v-icon :icon="RouterIcons.BANK_TRANSFERS" class="mx-1" /></v-col>
      </v-row>
      -->
      <v-row v-if="currentPerson" class="margin-one">
        <v-col>
          <v-item-group vertical class="mx-auto d-flex">
            <v-btn
              v-if="currentPerson.healthMetrics === undefined"
              @click="openHealthDetails(currentPerson)"
              color="primary"
              class="mx-auto"
              style="margin-left: 0"
              large
              block
            >
              <v-icon class="mx-2">mdi-medical-bag</v-icon>
              <template v-if="!MQ.xs">Health Info</template>
            </v-btn>
            <v-btn
             v-if="currentPerson.healthMetrics !== undefined"
             @click="openHealthDetails(currentPerson)"
             color="primary"
             outlined
            >
              <!-- <v-row><v-col class="mb-0">Details ({{ (person.healthMetrics.conditionCount || 0) + (person.healthMetrics.infoCount || 0) }})</v-col></v-row>
              <v-row><v-col class="mt-0">Medications ({{ person.healthMetrics.medicationCount || 0 }})</v-col></v-row> -->
            </v-btn>
          </v-item-group>
        </v-col>
      </v-row>
    </v-container>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { BankModule, BankPrivileges, NavModule } from "@/store";
import { RouterIcons } from '@/router/RouterIcons'
import { RouterUtils } from '@/router/RouterUtils'
import { MediaQuery as MQ } from '@/MediaQuery';
import { User } from "@fgl/funfangle-sdk/dist/rest/profile";
import { RouterNames } from "@/router/RouterNames";

// Define the props by using Vue's canonical way.
const HealthProfileCardProps = Vue.extend({
  props: {
    balance: {
      type: Number,
      default: 0
    },
    hideButtons: {
      type: Boolean,
      default: false
    },
    buttonsLayout: {
      type: String,
      default: "vertical-group-all"
    }
  },
  mixins: [RouterUtils]
});

@Component({
  components: {
    // mdbIcon
  }
})
export default class HealthProfileCard extends HealthProfileCardProps {
  bankStore: BankModule = getModule(BankModule, this.$store);
  bankPrivileges = new BankPrivileges();
  navStore: NavModule = getModule(NavModule, this.$store);
  // profileStore: ProfileModule = getModule(ProfileModule, this.$store);
  MQ = MQ;

  customMessage = ""

  // return which card is active
  get activeItem() {
    return this.$store.state.bank.activeItem;
  }
  get routerIcons(): RouterIcons {
    return RouterIcons;
  }
  // return whether to show the buttons or not
  get showButtons() {
    if (
      this.hideButtons === true ||
      (this.hideButtons as unknown as any) === "" ||
      (this.hideButtons as unknown as any) === "true"
    ) {
      return false;
    }
    return true;
  }
  get showCustomMessage() {
    return false;
    // return this.navStore.displayCustomMessage;
  }
  get currentPerson() {
    return this.navStore.selectedUser;
  }
  get currentPersonId() {
    return this.navStore.selectedUserId;
  }

  openHealthDetails(person: User): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let params: any = undefined;
    if (person.userId !== undefined) {
      params = {
        personId: person.userId
      };
    }

    this.$router.push({
      name: RouterNames.HEALTH_DETAIL,
      params
    });
  }

  // toggleActiveItem(index: number): void {
  //   this.navStore.toggleActiveItem(index);
  // }

  mounted() {
    // const storeMessage = this.getCustomMessage();
    // this.customMessage = storeMessage ? storeMessage : "";
  }
}
</script>

<style>
#custom-note-input {
  margin-top: 10px;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
  font-size: 14px;
}

#custom-note-container {
  position: relative;
}

.custom-note-close-btn {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.custom-note-close-btn-icon {
  font-size: 20px;
}

.margin-one {
  margin: 1px;
}
</style>
