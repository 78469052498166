var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticStyle: { width: "100%" } },
    [
      _vm.isLoading && (!_vm.allPersons || _vm.allPersons.length <= 0)
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                [_c("v-progress-circular", { attrs: { indeterminate: "" } })],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.navStore.allowCreateAuthorizedUsers &&
      !_vm.isLoading &&
      _vm.allPersons.length === 0
        ? [
            _c("section", { staticClass: "m-3" }, [
              _c("h2", { staticClass: "h1-responsive font-weight-bold my-2" }, [
                _vm._v(_vm._s(_vm.$tc("nav.home"))),
              ]),
            ]),
            _c(
              "v-container",
              [
                _c(
                  "v-row",
                  { staticClass: "my-3" },
                  [
                    _c("v-col", [
                      _vm._v(
                        "To get started, add a " +
                          _vm._s(_vm.lingoAuthUserSingularLC)
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      [
                        _vm.MQ.smOrSmaller
                          ? [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "add-button mt-0",
                                  attrs: {
                                    "x-large": "",
                                    block: "",
                                    color: "amber",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openAddAuthorizedUser()
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", [_vm._v("mdi-plus")]),
                                  _vm._v(
                                    " Add " +
                                      _vm._s(_vm.lingoAuthUserSingularCC) +
                                      " "
                                  ),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                        _vm.MQ.mdOrBigger
                          ? [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mx-auto add-button mt-0",
                                  attrs: { "x-large": "", color: "amber" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openAddAuthorizedUser()
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", [_vm._v("mdi-plus")]),
                                  _vm._v(
                                    " Add " +
                                      _vm._s(_vm.lingoAuthUserSingularCC) +
                                      " "
                                  ),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      !_vm.navStore.allowCreateAuthorizedUsers ||
      (!_vm.isLoading && _vm.allPersons.length > 0)
        ? [
            _vm.navStore.allowCreateAuthorizedUsers
              ? _c(
                  "div",
                  { staticClass: "float-right" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "add-button mt-0",
                        attrs: { large: "", color: "light-blue" },
                        on: {
                          click: function ($event) {
                            return _vm.openAddAuthorizedUser()
                          },
                        },
                      },
                      [
                        _c("v-icon", [_vm._v("mdi-plus")]),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.MQ.smOrSmaller
                                ? ""
                                : "Add " + _vm.lingoAuthUserSingularCC
                            ) +
                            " "
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c("h3", { staticClass: "mb-5", attrs: { id: "title" } }, [
              _vm._v(_vm._s(_vm.$tc("nav.home"))),
            ]),
            _vm.navStore.displayParentAppLinks
              ? _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      [
                        _c(
                          "v-card",
                          {
                            staticClass: "card-body",
                            attrs: { width: "22rem" },
                          },
                          [
                            _c("v-card-title", [
                              _c("b", [_vm._v("Parent App")]),
                            ]),
                            _c("v-card-text", [
                              _vm._v(
                                "Download the app to complete checkout..."
                              ),
                            ]),
                            _c("div", { staticClass: "flex-row ml-2" }, [
                              _c("a", {
                                staticStyle: {
                                  display: "inline-block",
                                  overflow: "hidden",
                                  background:
                                    "url(/img/icons/apple-appstore.svg) no-repeat",
                                  width: "135px",
                                  height: "40px",
                                },
                                attrs: {
                                  target: "_new",
                                  href: "https://apps.apple.com/us/app/funfangle/id1444462507?mt=8",
                                },
                              }),
                            ]),
                            _c("div", { staticClass: "flex-row" }, [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    target: "_new",
                                    href: "https://play.google.com/store/apps/details?id=com.funfangle.parent",
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      alt: "Get FunFangle on Google Play",
                                      src: "/img/icons/google-play-badge.png",
                                      height: "60px",
                                    },
                                  }),
                                ]
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.navStore.isFeatureEventReservationsEnabled
              ? _c("v-row", [_c("v-col"), _c("v-col")], 1)
              : _vm._e(),
            _vm.MQ.smOrSmaller
              ? [
                  _vm.isLoading &&
                  (!_vm.allPersons || _vm.allPersons.length <= 0)
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-progress-circular", {
                                attrs: { indeterminate: "" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._l(_vm.allPersons, function (person) {
                    return _c(
                      "v-container",
                      { key: person.userId },
                      [
                        _vm.selectedUserId !== person.userId ||
                        !_vm.navStore.userOpened
                          ? [
                              _c(
                                "v-row",
                                {
                                  attrs: {
                                    id: _vm.getSafeUserId(person.userId),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.togglePerson(person)
                                    },
                                  },
                                },
                                [
                                  _vm.displayPhotoOption
                                    ? _c(
                                        "v-col",
                                        { staticClass: "px-0 py-1 mt-1" },
                                        [
                                          _c("v-img", {
                                            attrs: {
                                              src: person.imageUrl70,
                                              contain: "",
                                              height: "70",
                                              alt: "Photo",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  person && person.userId
                                    ? _c(
                                        "v-col",
                                        {
                                          staticClass: "px-0 mt-0",
                                          attrs: { cols: "8" },
                                        },
                                        [
                                          _c(
                                            "v-container",
                                            { staticClass: "mt-0 p-0" },
                                            [
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "pr-1 pb-0",
                                                      attrs: { cols: "10" },
                                                    },
                                                    [
                                                      _c("h5", [
                                                        _vm._v(
                                                          _vm._s(
                                                            person.firstName
                                                          ) +
                                                            " " +
                                                            _vm._s(
                                                              person.lastName
                                                            )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    { staticClass: "px-1" },
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          "mdi-dots-horizontal"
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                [
                                                  _vm.navStore
                                                    .isFeatureSpendingAccountsEnabled
                                                    ? _c(
                                                        "v-col",
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$n(
                                                                  _vm
                                                                    .userContributionsByCamper[
                                                                    person
                                                                      .userId
                                                                  ] || 0,
                                                                  "currency"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                          _vm.getAuthUserScheduledAllocationsBalance(
                                                            person.userId
                                                          ) > 0
                                                            ? [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        color:
                                                                          "#bebebe",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " plus "
                                                                    ),
                                                                    _c(
                                                                      "a",
                                                                      {
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.goBankAccountPage(
                                                                                _vm.selectedUserId
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$n(
                                                                              _vm.getAuthUserScheduledAllocationsBalance(
                                                                                person.userId
                                                                              ),
                                                                              "currency"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      " future "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            : _vm._e(),
                                                        ],
                                                        2
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                        _vm.selectedUserId === person.userId &&
                        _vm.navStore.userOpened
                          ? [
                              _c(
                                "v-card",
                                {
                                  attrs: {
                                    id: _vm.getSafeUserId(person.userId),
                                  },
                                },
                                [
                                  _c(
                                    "v-card-title",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.togglePerson(person)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(person.firstName) +
                                          " " +
                                          _vm._s(person.lastName) +
                                          " "
                                      ),
                                      _c("v-spacer"),
                                      _c(
                                        "a",
                                        {
                                          attrs: { href: "/account" },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              $event.stopPropagation()
                                              return _vm.goAccountPage()
                                            },
                                          },
                                        },
                                        [_c("v-icon", [_vm._v("mdi-pencil")])],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.navStore.isFeatureSpendingAccountsEnabled
                                    ? _c("v-divider")
                                    : _vm._e(),
                                  _vm.mustFirstReserve(_vm.currentUser)
                                    ? _c(
                                        "v-list",
                                        [
                                          _vm.navStore
                                            .isFeatureReservationsEnabled
                                            ? _c(
                                                "v-list-item",
                                                {
                                                  staticClass: "text-center",
                                                  attrs: { href: "/register" },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      $event.stopPropagation()
                                                      return _vm.goReservationsPage()
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "mx-auto add-button mt-0",
                                                      attrs: {
                                                        "x-large": "",
                                                        block:
                                                          !_vm.MQ.mdOrBigger,
                                                        color: "amber",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.goReservationsPage()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v("mdi-plus"),
                                                      ]),
                                                      _vm._v(" Register "),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "v-list",
                                        [
                                          _vm.navStore
                                            .isFeatureSpendingAccountsEnabled &&
                                          _vm.currentUser &&
                                          _vm.currentUser.userId
                                            ? _c(
                                                "v-list-item",
                                                {
                                                  staticClass:
                                                    "d-flex justify-content-between align-items-center",
                                                  attrs: { href: "/bank" },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      $event.stopPropagation()
                                                      return _vm.goBankAccountPage(
                                                        person.userId
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("available-balance-card", {
                                                    attrs: {
                                                      balance:
                                                        _vm
                                                          .userContributionsByCamper[
                                                          _vm.currentUser.userId
                                                        ] || 0,
                                                      buttonsLayout:
                                                        "only-manage-funds",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.navStore.isFeatureHealthEnabled
                                            ? _c("v-divider")
                                            : _vm._e(),
                                          _vm.navStore.isFeatureHealthEnabled &&
                                          _vm.currentUser &&
                                          _vm.currentUser.userId
                                            ? _c(
                                                "v-list-item",
                                                {
                                                  staticClass:
                                                    "d-flex justify-content-between align-items-center",
                                                },
                                                [_c("health-profile-card")],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.navStore
                                            .isFeatureCarePackageEnabled
                                            ? _c("v-divider")
                                            : _vm._e(),
                                          _vm.navStore
                                            .isFeatureCarePackageEnabled &&
                                          person &&
                                          person.userId
                                            ? _c(
                                                "v-list-item",
                                                {
                                                  staticClass:
                                                    "d-flex justify-content-between align-items-center",
                                                  attrs: { href: "/care" },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      $event.stopPropagation()
                                                      return _vm.goCarePackagesPage()
                                                    },
                                                  },
                                                },
                                                [_c("care-package-card")],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.navStore
                                            .isFeatureEventReservationsEnabled
                                            ? _c("v-divider")
                                            : _vm._e(),
                                          _vm.navStore
                                            .isFeatureEventReservationsEnabled
                                            ? _c(
                                                "v-list-item",
                                                [
                                                  _c(
                                                    "event-reservations-card",
                                                    {
                                                      attrs: {
                                                        reservations:
                                                          _vm.reservations,
                                                        showReservations:
                                                          _vm.showReservations,
                                                        currentUser:
                                                          _vm.currentUser,
                                                      },
                                                    }
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.navStore
                                            .featureEventEntryControlEnabled
                                            ? _c("v-divider")
                                            : _vm._e(),
                                          _vm.navStore
                                            .featureEventEntryControlEnabled
                                            ? _c(
                                                "v-list-item",
                                                [_c("entry-control-card")],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                  _c("profile-photo", {
                                    attrs: { person: _vm.currentUser },
                                  }),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    )
                  }),
                ]
              : _vm._e(),
            _vm.MQ.mdOrBigger
              ? [
                  _vm.allPersons.length <= 10000 &&
                  !(_vm.allPersons.length <= 1 && _vm.navStore.userOpened)
                    ? _c(
                        "v-row",
                        _vm._l(_vm.allPersons, function (person) {
                          return _c(
                            "v-col",
                            {
                              key: person.userId,
                              attrs: { cols: "12", sm: "6" },
                            },
                            [
                              _c("AuthorizedPersonMinimized", {
                                attrs: {
                                  person: person,
                                  id: _vm.getSafeUserId(person.userId),
                                  buttons: "less",
                                  isCard:
                                    _vm.selectedUserId === person.userId &&
                                    _vm.navStore.userOpened,
                                },
                                on: { togglePerson: _vm.togglePerson },
                              }),
                            ],
                            1
                          )
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm.navStore.userOpened && _vm.currentUser != null
                    ? _c(
                        "v-row",
                        [
                          _c("v-col", [
                            _c("h3", { staticClass: "mt-2" }, [
                              _vm._v(
                                _vm._s(_vm.currentUser.firstName) +
                                  " " +
                                  _vm._s(_vm.currentUser.lastName)
                              ),
                            ]),
                          ]),
                          _vm.allPersons.length >= 1
                            ? _c("v-col", [
                                _c("div", { staticClass: "float-right mt-4" }, [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.togglePerson(
                                            _vm.currentUser
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("(close)")]
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _vm.navStore.userOpened && _vm.currentUser != null
                            ? [
                                _c(
                                  "v-card",
                                  {
                                    staticClass: "mt-2",
                                    attrs: { "no-body": "" },
                                  },
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { md: "6", lg: "5" } },
                                          [
                                            _c("profile-photo", {
                                              attrs: {
                                                person: _vm.currentUser,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { md: "6", lg: "7" } },
                                          [
                                            _vm.mustFirstReserve(
                                              _vm.currentUser
                                            )
                                              ? _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          sm: "12",
                                                          md: "12",
                                                          lg: "6",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-card",
                                                          {
                                                            staticClass:
                                                              "mb-lg-1 m-3 mr-2",
                                                          },
                                                          [
                                                            _c(
                                                              "v-card-text",
                                                              [
                                                                _c(
                                                                  "v-alert",
                                                                  {
                                                                    staticClass:
                                                                      "info-light-bg",
                                                                    attrs: {
                                                                      block:
                                                                        _vm.MQ
                                                                          .smOrSmaller,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        staticClass:
                                                                          "mr-2",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-information"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      " Currently not registered. "
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    staticClass:
                                                                      "mx-auto add-button mt-0",
                                                                    attrs: {
                                                                      "x-large":
                                                                        "",
                                                                      color:
                                                                        "amber",
                                                                      block:
                                                                        _vm.MQ
                                                                          .smOrSmaller,
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.goReservationsPage()
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-plus"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      " Register "
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _c(
                                                  "v-row",
                                                  [
                                                    _vm.currentUser &&
                                                    _vm.currentUser.userId &&
                                                    (_vm.navStore
                                                      .isFeatureSpendingAccountsEnabled ||
                                                      _vm.navStore
                                                        .isFeatureHealthEnabled ||
                                                      _vm.navStore
                                                        .isFeatureCarePackageEnabled)
                                                      ? _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              md: "12",
                                                              lg: "6",
                                                            },
                                                          },
                                                          [
                                                            _vm.navStore
                                                              .isFeatureSpendingAccountsEnabled
                                                              ? _c(
                                                                  "v-card",
                                                                  {
                                                                    staticClass:
                                                                      "mb-lg-1 m-3",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-card-text",
                                                                      [
                                                                        _c(
                                                                          "available-balance-card",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                balance:
                                                                                  _vm
                                                                                    .userContributionsByCamper[
                                                                                    _vm
                                                                                      .currentUser
                                                                                      .userId
                                                                                  ] ||
                                                                                  0,
                                                                                buttonsLayout:
                                                                                  "only-manage-funds",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            _vm.navStore
                                                              .isFeatureHealthEnabled
                                                              ? _c(
                                                                  "v-card",
                                                                  {
                                                                    staticClass:
                                                                      "mb-lg-1 m-3",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-card-text",
                                                                      [
                                                                        _c(
                                                                          "health-profile-card"
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            _vm.navStore
                                                              .isFeatureCarePackageEnabled
                                                              ? _c(
                                                                  "v-card",
                                                                  {
                                                                    staticClass:
                                                                      "mb-lg-1 m-3",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-card-text",
                                                                      [
                                                                        _c(
                                                                          "care-package-card"
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.navStore
                                                      .isFeatureSpendingAccountsEnabled
                                                      ? _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              md: "12",
                                                              lg: "6",
                                                            },
                                                          },
                                                          [
                                                            _vm.currentUser
                                                              .accountHolder ===
                                                            _vm.userId
                                                              ? _c(
                                                                  "v-card",
                                                                  {
                                                                    staticClass:
                                                                      "mb-lg-1 m-3",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-card-text",
                                                                      [
                                                                        _c(
                                                                          "contributors-card",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                contributors:
                                                                                  _vm.currentContributors,
                                                                                showContributors:
                                                                                  _vm
                                                                                    .MQ
                                                                                    .lg,
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ]
              : _vm._e(),
            _c("br"),
            _vm.navStore.featureSpendingAccountsEnabled
              ? [
                  _c("h4", [_vm._v(_vm._s(_vm.$tc("phrase.recent_activity")))]),
                  _c("activity-component", {
                    attrs: { "transactions-filter": _vm.transactionsFilter },
                  }),
                ]
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }