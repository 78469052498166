<template>
  <div v-if="MediaQuery.smOrSmaller">
    <v-footer app fixed class="pa-0">
      <v-bottom-navigation grow color="blue">
        <v-btn :to="{ name: RouterNames.HOME }" text>
          <span class="d-flex flex-column align-center">
            <v-icon>{{ RouterIcons.HOME }}</v-icon>
            <span class="mt-1">{{ $tc('nav.home') }}</span>
          </span>
        </v-btn>

        <v-btn :to="{ name: RouterNames.BANK }" text>
          <span class="d-flex flex-column align-center">
            <v-icon>{{ RouterIcons.BANK }}</v-icon>
            <span class="mt-1">{{ $tc('nav.bank') }}</span>
          </span>
        </v-btn>

        <v-btn :to="{ name: RouterNames.BANK_DEPOSITS }" text>
          <span class="d-flex flex-column align-center">
            <v-icon>{{ RouterIcons.BANK_DEPOSITS }}</v-icon>
            <span class="mt-1">{{ $tc('nav.bank.deposits') }}</span>
          </span>
        </v-btn>

        <v-btn :to="{ name: RouterNames.BANK_SITEMAP }" text>
          <span class="d-flex flex-column align-center">
            <v-icon>{{ RouterIcons.BANK_SITEMAP }}</v-icon>
            <span class="mt-1">{{ $tc('nav.more') }}</span>
          </span>
        </v-btn>
      </v-bottom-navigation>
    </v-footer>
  </div>
</template>
<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { NavModule, ProfileModule } from "@/store";
import { MediaQuery } from '@/MediaQuery';
import { RouterIcons } from '@/router/RouterIcons'
import { RouterNames } from '@/router/RouterNames'

@Component({
  components: {
    // mdbContainer,
    // mdbRow,
    // mdbCol,
    // mdbIcon
  }
})
export default class BankFooter extends Vue {
  navStore: NavModule = getModule(NavModule, this.$store);
  profileStore: ProfileModule = getModule(ProfileModule, this.$store);
  MediaQuery = MediaQuery;
  RouterIcons = RouterIcons;
  RouterNames = RouterNames;
}
</script>
<style scoped>

</style>
