// import { ApiEndpoints } from '../constants';
import { store } from '@/store/Store';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { DateTime } from 'luxon';
import { getModule } from "vuex-module-decorators";
import { NavModule } from "./NavModule";
import { ProfileModule } from "./ProfileModule";
import { ParticipantUpdateRequest, User } from '@fgl/funfangle-sdk/dist/rest/profile';
import { ApiEndpoints, Constants } from '@/constants';
import { ApiService } from '@/shared/auth/auth-cognito';
// import { AuthCookies } from '@/shared/auth/auth-cognito/AuthCookies';

const apiSvc = new ApiService();

@Module({ dynamic: true, store, name: 'bank', namespaced: true })
export class BankModule extends VuexModule {
  navStore: NavModule = getModule(NavModule);
  profileStore: ProfileModule = getModule(ProfileModule);

  activeIndex = 0;

  contributingTo: User[] = [];

  scheduledAllocations: any[] = [];

  squarePaymentJS: any = null;

  squarePaymentLoaded = false;

  transactions: any[] = [];

  tableTransactions: any[] = [];

  // public getAccountContributionByUser(userId: number): number {
  //   // console.log('authorizedUsers ', authorizedUsers, contributingTo);
  //   const allPersons = this.profileStore.authorizedUsers.concat(this.contributingTo)
  //   const balancesData = {
  //     userIds: allPersons.map(authUser => authUser.userId)
  //   }
  //   // will be getting the contributor links & the associated camper balances
  //         // let contributors = res[0].data.message.Items as unknown as Contributor[]
  //         // let camperBalances = res[1].data.message.Items as any[] // TODO: Contribution[] ???
  //         // // calculate the total balance contributed for each camper
  //         // contributors.forEach((rc: Contributor) => {
  //         //   rc.balance = camperBalances
  //         //     .filter(cb => rc.contributorId === cb.contributorId)
  //         //     .map(cb => cb.currentBalance)
  //         //     .reduce((total, balance) => (total += balance), 0)
  //         // })

  //         // // let contributors = rawContributors.map(rawContrib => {
  //         // //   let contrib = {
  //         // //     name: rawContrib.firstName + ' ' + rawContrib.lastName,
  //         // //     balance: rawContrib.balance,
  //         // //     contributorId: rawContrib.contributorId,
  //         // //     status: rawContrib.status === 'accepted' ? 'Accepted' : 'Pending',
  //         // //     blackListIds: rawContrib.blackListIds,
  //         // //     email: rawContrib.email
  //         // //   }
  //         // //   return contrib
  //         // // })

  //         // this.setContributors(contributors);
  //         // // commit('setRawContributors', rawContributors)
  //         // // commit('setContributors', contributors)

  //         // now set the logged-in user's contribution
  //         let accountContributionByUser: Record<string, number> = {}
  //         // console.log('allPersons ', allPersons);
  //         // console.log('camperBalances ', camperBalances);
  //         allPersons.forEach(authUser => {
  //           let activeContribution = camperBalances.find(cb => cb.contributorId === userId && cb.authUserId === authUser.userId)
  //           let balance = 0
  //           if (activeContribution && activeContribution.currentBalance) {
  //             balance = activeContribution.currentBalance
  //           } else {
  //             balance = 0
  //           }
  //           accountContributionByUser[authUser.userId || 'UNSET'] = balance
  //         })
  //         // commit('setUserContributionByCamper', userContributionByCamper)

  //       //   resolve(contributors)
  //       // })
  //       // .catch(err => {
  //       //   // console.log('Get contributors err:', err)
  //       //   reject(err)
  //       // })
  // }

  get contributingToActive(): User[] {
    return this.contributingTo.filter(person => {
      const isGuest: boolean = person.firstName !== undefined && person.firstName.indexOf('Guest#') === 0;
      return (person.isInactive !== 1 && person.isHidden !== 1 && !isGuest);
    });
  }

  // TODO: Figure this out (used to be in user.js store)
  get userContributionByCamper(): Record<string, number> {
    // now set the logged-in user's contribution
    const contributionByCamper: Record<string, number> = {}
    // console.log('allPersons ', this.contributingTo);
    // console.log('camperBalances ', this.profileStore.userPairs);
    this.profileStore.authorizedActiveUsers.forEach(authUser => {
      const activeContribution = this.profileStore.userPairs.find(cb => cb.parentId === this.profileStore.userId && cb.childId === authUser.userId)
      let balance = 0
      if (activeContribution && activeContribution.currentBalance) {
        balance = activeContribution.currentBalance
      } else {
        balance = 0
      }
      contributionByCamper[authUser.userId || 'UNSET'] = balance;
    })
    return contributionByCamper;
  }

  @Action
  public async depositFundsAuthorizeNet(payload: any): Promise<any> {
    // Replicated from depositFundsSquare
    const timeZone = this.navStore.timeZone;
    // let organization = rootGetters['bank/getOrganization']
    // if (organization && organization !== undefined) {
    //   timeZone = organization.timeZoneOffset;
    // }
    const firstName = this.profileStore.firstName;
    const lastName = this.profileStore.lastName;
    const userId = this.profileStore.userId;
    const activeUserEmail = this.profileStore.email;
    const activeUserId = this.profileStore.userId;
    const organizationId = this.profileStore.organizationId;
    const email = this.profileStore.email;
    if (!userId || userId === undefined || userId === '') {
      return Promise.reject(new Error('Invalid userId'));
    }
    if (!organizationId || organizationId === undefined || organizationId === '') {
      return Promise.reject(new Error('Invalid organizationId'));
    }
    if (!email || email === undefined || email === '') {
      return Promise.reject(new Error('Invalid email'));
    }
    // assemble url and payload
    const url = `${ApiEndpoints.apiEndpoint}/v2/bank/deposit/from/authorizenet`
    const data = {
      organizationId,
      email,
      timeZone,
      firstName,
      lastName,
      userId,
      activeUserEmail,
      activeUserId,
      incrementBalance: true,
      isParent2: false,
      allocations: payload.allocations,
      chargeAmount: payload.chargeAmount,
      depositAmount: payload.depositAmount,
      endOfSession: payload.endOfSession,
      feeCC: payload.feeCC,
      feeFF: payload.feeFF,
      donateSelection: payload.donateSelection,
      opaqueDataDescriptor: payload.opaqueDataDescriptor,
      opaqueDataValue: payload.opaqueDataValue
    }
    // console.log('deposit payload ', data)

    return new Promise((resolve, reject) => {
      apiSvc.httpPost(url, data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          // console.log('Deposit Error:', err, data, payload)
          reject(err)
        })
    })
  }

  @Action
  public async donateFundsAuthorizeNet(payload: any): Promise<any> {
    // Replicated from donateFundsSquare
    const timeZone = this.navStore.timeZone;
    // let organization = rootGetters['bank/getOrganization']
    // if (organization && organization !== undefined) {
    //   timeZone = organization.timeZoneOffset;
    // }
    const firstName = this.profileStore.firstName;
    const lastName = this.profileStore.lastName;
    const userId = this.profileStore.userId;
    const activeUserEmail = this.profileStore.email;
    const activeUserId = this.profileStore.userId;
    const organizationId = this.profileStore.organizationId;
    const email = this.profileStore.email;
    if (!userId || userId === undefined || userId === '') {
      return Promise.reject(new Error('Invalid userId'));
    }
    if (!organizationId || organizationId === undefined || organizationId === '') {
      return Promise.reject(new Error('Invalid organizationId'));
    }
    if (!email || email === undefined || email === '') {
      return Promise.reject(new Error('Invalid email'));
    }
    // assemble url and payload
    const url = `${ApiEndpoints.apiEndpoint}/v2/bank/donate/from/authorizenet`
    const data = {
      organizationId,
      email,
      timeZone,
      firstName,
      lastName,
      userId,
      activeUserEmail,
      activeUserId,
      incrementBalance: true,
      isParent2: false,
      allocations: payload.allocations,
      chargeAmount: payload.chargeAmount,
      donationAmount: payload.donationAmount,
      endOfSession: payload.endOfSession,
      feeCC: payload.feeCC,
      feeFF: payload.feeFF,
      donateSelection: payload.donateSelection,
      opaqueDataDescriptor: payload.opaqueDataDescriptor,
      opaqueDataValue: payload.opaqueDataValue
    }
    // console.log('donate payload ', data)

    return new Promise((resolve, reject) => {
      apiSvc.httpPost(url, data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          // console.log('Deposit Error:', err, data, payload)
          reject(err)
        })
    })
  }

  @Action({rawError: true})
  public async depositFundsSquare(payload: any): Promise<any> {
    // Replicated in depositFundsAuthorizeNet
    const timeZone = this.navStore.timeZone;
    const firstName = this.profileStore.firstName;
    const lastName = this.profileStore.lastName;
    const userId = this.profileStore.userId;
    const activeUserEmail = this.profileStore.loginEmail;
    const activeUserId = this.profileStore.userId;
    const email = this.profileStore.email;
    const organizationId = this.profileStore.organizationId;
    if (!userId || userId === undefined || userId === '') {
      return Promise.reject(new Error('Invalid userId'));
    }
    if (!organizationId || organizationId === undefined || organizationId === '') {
      return Promise.reject(new Error('Invalid organizationId'));
    }
    if (!email || email === undefined || email === '') {
      return Promise.reject(new Error('Invalid email'));
    }
    let customerId = undefined;
    const squareInfo = this.profileStore.squareInfo;
    if (squareInfo && squareInfo !== undefined) {
      customerId = squareInfo.squareCustomerId
    }
    if (!customerId || customerId === undefined || customerId === '') {
      return Promise.reject(new Error('Invalid customerId'));
    }
    let cardId: string | undefined;
    const cardNonce = payload.cardNonce;
    if ((cardNonce == null || cardNonce === undefined) && squareInfo && squareInfo !== undefined) {
      cardId = squareInfo.defaultCardId;
      // let paymentMethods = this.profileStore.paymentMethods;
      // if (paymentMethods && paymentMethods !== undefined) {
      //   let defaultCard = paymentMethods.find(card => card.isDefault)
      //   if (defaultCard && defaultCard !== undefined) {
      //     cardId = defaultCard.cardId
      //     // cardNonce = defaultCard.cardNonce
      //   }
      // }
    }
    // if (!cardId || cardId === undefined || cardId === '') {
    //   return Promise.reject(new Error('Invalid cardId'));
    // }
    if (cardId === undefined && (!cardNonce || cardNonce === undefined || cardNonce === '')) {
      return Promise.reject(new Error('Invalid cardNonce'));
    }
    // assemble url and payload
    const url = `${ApiEndpoints.apiEndpoint}/v2/bank/deposit/from/square`
    const data = {
      organizationId,
      email,
      customerId,
      timeZone,
      firstName,
      lastName,
      userId,
      activeUserEmail,
      activeUserId,
      sqCardId: cardId,
      sqNonce: cardNonce,
      sqUserId: customerId,
      currency: 'USD',
      incrementBalance: true,
      isParent2: false,
      allocations: payload.allocations,
      chargeAmount: payload.chargeAmount,
      depositAmount: payload.depositAmount,
      feeCC: payload.feeCC,
      feeFF: payload.feeFF,
      endOfSession: payload.endOfSession,
      donateSelection: payload.donateSelection
    }
    // console.log('deposit payload ', data)

    return new Promise((resolve, reject) => {
      apiSvc.httpPost(url, data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          // console.log('Deposit Error:', err, data, payload)
          reject(err)
        })
    })
  }

  @Action({rawError: true})
  public async donateFundsSquare(payload: any): Promise<any> {
    // Replicated in donateFundsAuthorizeNet
    const timeZone = this.navStore.timeZone;
    const firstName = this.profileStore.firstName;
    const lastName = this.profileStore.lastName;
    const userId = this.profileStore.userId;
    const activeUserEmail = this.profileStore.loginEmail;
    const activeUserId = this.profileStore.userId;
    const email = this.profileStore.email;
    const organizationId = this.profileStore.organizationId;
    if (!userId || userId === undefined || userId === '') {
      return Promise.reject(new Error('Invalid userId'));
    }
    if (!organizationId || organizationId === undefined || organizationId === '') {
      return Promise.reject(new Error('Invalid organizationId'));
    }
    if (!email || email === undefined || email === '') {
      return Promise.reject(new Error('Invalid email'));
    }
    let customerId = undefined;
    const squareInfo = this.profileStore.squareInfo;
    if (squareInfo && squareInfo !== undefined) {
      customerId = squareInfo.squareCustomerId
    }
    if (!customerId || customerId === undefined || customerId === '') {
      return Promise.reject(new Error('Invalid customerId'));
    }
    let cardId: string | undefined;
    const cardNonce = payload.cardNonce;
    if ((cardNonce == null || cardNonce === undefined) && squareInfo && squareInfo !== undefined) {
      cardId = squareInfo.defaultCardId;
      // let paymentMethods = this.profileStore.paymentMethods;
      // if (paymentMethods && paymentMethods !== undefined) {
      //   let defaultCard = paymentMethods.find(card => card.isDefault)
      //   if (defaultCard && defaultCard !== undefined) {
      //     cardId = defaultCard.cardId
      //     // cardNonce = defaultCard.cardNonce
      //   }
      // }
    }
    // if (!cardId || cardId === undefined || cardId === '') {
    //   return Promise.reject(new Error('Invalid cardId'));
    // }
    if (cardId === undefined && (!cardNonce || cardNonce === undefined || cardNonce === '')) {
      return Promise.reject(new Error('Invalid cardNonce'));
    }
    // assemble url and payload
    const url = `${ApiEndpoints.apiEndpoint}/v2/bank/donate/from/square`
    const data = {
      organizationId,
      email,
      customerId,
      timeZone,
      firstName,
      lastName,
      userId,
      activeUserEmail,
      activeUserId,
      sqCardId: cardId,
      sqNonce: cardNonce,
      sqUserId: customerId,
      currency: 'USD',
      incrementBalance: true,
      isParent2: false,
      allocations: payload.allocations,
      donationAmount: payload.donationAmount,
      endOfSession: payload.endOfSession,
      donateSelection: payload.donateSelection
    }
    // console.log('donate payload ', data)

    return new Promise((resolve, reject) => {
      apiSvc.httpPost(url, data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          // console.log('Deposit Error:', err, data, payload)
          reject(err)
        })
    })
  }

  @Action
  public async fetchBamboraToken(payload: any): Promise<any> {
    if (payload === undefined) return;
  //   // TODO: Is this actually implemented?
  //   const url = `${ApiEndpoints.bamboraAPI}/scripts/tokenization/tokens`
  //   return apiSvc.httpPost(url, payload).then(res => res.token)
  }

  @Action({ rawError: true })
  async fetchUserTransactions(payload: any): Promise<any> {
    const organizationId = (payload && payload.organizationId) || '';
    if (organizationId == null || organizationId === undefined || organizationId === '') return Promise.reject('OrganizationId is undefined');
    const userId = (payload && payload.userId) || '';
    if (userId == null || userId === undefined || userId === '') return Promise.reject('UserId is undefined');

    let url = `${ApiEndpoints.apiEndpoint}/v2/bank/transaction/search?organizationId=${encodeURIComponent(organizationId)}`;
    url = `${url}&usermode=account&userid=${encodeURIComponent((payload && payload.userId) || '')}`

    const upperDT = DateTime.now().plus({ days: 1 }).endOf('day');
    let lowerDT = upperDT.minus({ months: 6 }).startOf('day');
    if (upperDT.month > 6) lowerDT = lowerDT.startOf('year');

    url = `${url}&loweriso=${encodeURIComponent(lowerDT.toISO())}`;
    url = `${url}&upperiso=${encodeURIComponent(upperDT.toISO())}`;

    const options: any = {
      pageSize: 1500
    };

    const transactions: any[] = [];
    do {
      // console.log(`fetchUserTransactions url=`, `${url}&options=${encodeURIComponent(JSON.stringify(options))}`);
      await apiSvc.httpGet(`${url}&options=${encodeURIComponent(JSON.stringify(options))}`)
      .then(rs => {
        if (rs && rs.data) {
          transactions.push(...(rs.data.items || []));
          options.startKey = rs.data.lastKey;
        }
      })
      .catch((err: Error) => {
        return Promise.reject(err)
      });
    } while (options.startKey !== undefined);

    // sort all the transactions by time stamp
    transactions.sort((a, b) => {
      return b.timeStamp - a.timeStamp
    })
    // add padding of two decimal places
    transactions.map(function (e) {
      if (e != null && e !== undefined && e.amount != null && e.amount !== undefined && !isNaN(parseFloat(e.amount)) && isFinite(e.amount)) {
        e.amount = e.amount.toFixed(2)
      }
    })
    // create another array
    const tableTransactions = transactions.map(trans => {
      return {
        name: trans.userFirstName,
        date: trans.date,
        location: trans.locationName || ''
      }
    })
    this.setTableTransactions(tableTransactions)
    this.setTransactions(transactions)

    return Promise.resolve(transactions)
  }

  @Action
  public async loadSquarePaymentJS(attempt = 0) {
    // import form
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const squarePaymentJS = document.createElement("script") as any;
    squarePaymentJS.async = true;
    squarePaymentJS.setAttribute("type", "text/javascript");
    squarePaymentJS.onreadystatechange = function() {
      // console.log("state", SqPaymentJS.readyState);
    };
    // Initialize SqPaymentForm
    squarePaymentJS.onload = async () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (!(window as any).Square) {
        if (attempt > 10) {
          throw new Error('Square.js failed to load properly after multiple attempts');
        }
        return this.loadSquarePaymentJS(attempt + 1);
      }
      this.setSquarePaymentLoaded(true);
      return Promise.resolve();
    };
    // load external script
    squarePaymentJS.setAttribute("src", Constants.squareWebPaymentSDKv1);
    this.setSquarePaymentJS(squarePaymentJS);
    document.head.appendChild(squarePaymentJS);
  }

  @Action
  public async sendDepositConfirmation(params: SendDepositConfirmationParams): Promise<any> {
    if (!params || params === undefined) return Promise.reject(new Error('Params are undefined'));
    if (params.userId === undefined || params.userId === 'UNSET') return Promise.reject(new Error('User ID is undefined'));
    if (params.organizationId === undefined || params.organizationId === 'UNSET') return Promise.reject(new Error('Organization ID is undefined'));
    if (params.amount === undefined) return Promise.reject(new Error('Amount is undefined'));

    const data = {
      userId: params.userId,
      organizationId: params.organizationId,
      amount: params.amount
    }

    // if it's not the person logged in, then don't send them a confirmation email
    // if (this.profileStore.email !== this.profileStore.loginEmail) return Promise.resolve();

    const url = `${ApiEndpoints.emailEndpoint}/deposit/confirm`
    return new Promise((resolve, reject) => {
      apiSvc.httpPost(url, data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          // console.log('Allocation err:', err)
          reject(err)
        })
    })
  }

  @Action
  public async transferFunds(payload: any): Promise<any> {
    const url = `${ApiEndpoints.apiEndpoint}/v2/bank/transfer`
    const data = {
      organizationId: payload.organizationId,
      contributorEmail: payload.contributorEmail,
      contributorId: payload.contributorId,
      authUserId: payload.fromAccount,
      userId: payload.userId,
      toAuthUserId: payload.toAccount,
      amount: payload.amount,
      amountType: 'DECIMAL'
    }

    return new Promise((resolve, reject) => {
      apiSvc.httpPost(url, data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          // console.log('Got error getting lingo:', err)
          reject(err)
        })
    })
  }

  @Action
  public async updateSpendingLimit(params: ParticipantUpdateRequest): Promise<any> {
    if (!params || params === undefined) return Promise.reject(new Error('Params are undefined'));
    const organizationId = params.organizationId;
    // if (email === undefined || email === 'UNSET') return Promise.reject(new Error('email is undefined'));
    if (organizationId == null || organizationId === undefined || organizationId === 'UNSET') return Promise.reject(new Error('organizationId is undefined'));
    // if (params.firstName === undefined || params.firstName === 'UNSET') return Promise.reject(new Error('First name is undefined'));
    // if (params.lastName === undefined || params.lastName === 'UNSET') return Promise.reject(new Error('Last name is undefined'));
    // const data: UserRequest = {
    //   email,
    //   firstName: params.firstName,
    //   lastName: params.lastName,
    //   organizationId
    // }

    const url = `${ApiEndpoints.apiEndpoint}/v2/profile/participant`
    return new Promise((resolve, reject) => {
      apiSvc.httpPost(url, params)
        .then(() => {
          // this.context.commit('setFirstName', firstName);
          // this.context.commit('setLastName', lastName);
          // this.setFirstName(params.firstName)
          // this.setLastName(params.lastName)
          resolve(true)
        })
        .catch(err => {
          // console.log('Update info err:', err)
          reject(err)
        })
    })
  }

  @Mutation
  setActiveIndex(activeIndex: number): void {
    this.activeIndex = activeIndex
  }

  @Mutation
  toggleActiveIndex(activeIndex: number): void {
    if (this.activeIndex === activeIndex) {
      this.activeIndex = -1
    } else {
      this.activeIndex = activeIndex
    }
  }

  @Mutation
  public setSquarePaymentJS(squarePaymentJS: any): void {
    this.squarePaymentJS = squarePaymentJS;
  }

  @Mutation
  public setSquarePaymentLoaded(isLoaded: boolean) {
    this.squarePaymentLoaded = isLoaded;
  }

  @Mutation
  public setScheduledAllocations(scheduledAllocations: any): void {
    this.scheduledAllocations = scheduledAllocations;
  }

  @Mutation
  public setTransactions(payload: any): void {
    this.transactions = payload
  }

  @Mutation
  public setTableTransactions(payload: any): void {
    this.tableTransactions = payload
  }
  // @Mutation
  // public setCurrentUser(user: User): void {
  //   this.currUser = user;
  // }

  // @Mutation
  // public setCurrentByUsersIndex(index: number): void {
  //   if (!this.users || this.users === undefined || index < 0 || this.users.length <= index) {
  //     return;
  //   }
  //   // set user
  //   //this.setCurrentUser(this.users[index]);
  //   this.currUser = this.users[index];
  //   //this.setUserIndex(index);
  //   this.userIndex = index;
  //   // set org
  //   const org = this.users[index].organization;
  //   if (org && org !== undefined) {
  //     // this.setCurrentOrganization(org);
  //     this.currOrganization = org;
  //   }
  // }

  // @Mutation
  // public setOrgStatus(arr: string[]): void {
  //   if (this.currOrganization !== undefined) {
  //     this.currOrganization.organizationStatus = arr;
  //   }
  // }

  // @Mutation
  // public setUserIndex(index: number): void {
  //   this.userIndex = index;
  // }

  // @Mutation
  // public setUsers(users: User[]): void {
  //   this.users = users;
  // }
}

export interface SendDepositConfirmationParams {
  organizationId: string;
  userId: string;
  amount: number;
}

