var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.MediaQuery.smOrSmaller
    ? _c(
        "div",
        [
          _c(
            "v-footer",
            { staticClass: "pa-0", attrs: { app: "", fixed: "" } },
            [
              _c(
                "v-bottom-navigation",
                { attrs: { grow: "", color: "blue" } },
                [
                  _c(
                    "v-btn",
                    { attrs: { to: { name: _vm.RouterNames.HOME }, text: "" } },
                    [
                      _c(
                        "span",
                        { staticClass: "d-flex flex-column align-center" },
                        [
                          _c("v-icon", [_vm._v(_vm._s(_vm.RouterIcons.HOME))]),
                          _c("span", { staticClass: "mt-1" }, [
                            _vm._v(_vm._s(_vm.$tc("nav.home"))),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        to: { name: _vm.RouterNames.CARE_PACKAGES },
                        text: "",
                      },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "d-flex flex-column align-center" },
                        [
                          _c("v-icon", [
                            _vm._v(_vm._s(_vm.RouterIcons.CARE_PACKAGES)),
                          ]),
                          _c("span", { staticClass: "mt-1" }, [
                            _vm._v(_vm._s(_vm.$tc("nav.care.packages"))),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        to: { name: _vm.RouterNames.CARE_ORDERS },
                        text: "",
                      },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "d-flex flex-column align-center" },
                        [
                          _c("v-icon", [
                            _vm._v(_vm._s(_vm.RouterIcons.CARE_ORDERS)),
                          ]),
                          _c("span", { staticClass: "mt-1" }, [
                            _vm._v(_vm._s(_vm.$tc("nav.care.orders"))),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        to: { name: _vm.RouterNames.SITEMAP },
                        text: "",
                      },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "d-flex flex-column align-center" },
                        [
                          _c("v-icon", [
                            _vm._v(_vm._s(_vm.RouterIcons.SITEMAP)),
                          ]),
                          _c("span", { staticClass: "mt-1" }, [
                            _vm._v(_vm._s(_vm.$tc("nav.more"))),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }