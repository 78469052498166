var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _vm.isLoading &&
      (!_vm.reservations ||
        _vm.reservations === undefined ||
        _vm.reservations.length <= 0)
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("v-progress-circular", {
                    attrs: { indeterminate: "", size: "70" },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.isLoading &&
      _vm.activeSection === "bookings" &&
      (_vm.reservations === undefined || _vm.reservations.length === 0)
        ? [
            _c("section", { staticClass: "m-3" }, [
              _c("h2", { staticClass: "h1-responsive font-weight-bold my-2" }, [
                _vm._v("Reservations"),
              ]),
            ]),
            _c(
              "v-container",
              [
                _c(
                  "v-row",
                  { staticClass: "my-3" },
                  [
                    _c("v-col", [
                      _vm._v("No reservations found. Make a new one today."),
                    ]),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      [
                        _vm.MQ.smOrSmaller
                          ? [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "add-button mt-0",
                                  attrs: {
                                    "x-large": "",
                                    block: "",
                                    color: _vm.navStore.buttonNextColor,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openAdd()
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", { attrs: { left: "" } }, [
                                    _vm._v("mdi-plus"),
                                  ]),
                                  _vm._v("Add Reservation "),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                        _vm.MQ.mdOrBigger
                          ? [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mx-auto add-button mt-0",
                                  attrs: {
                                    "x-large": "",
                                    color: _vm.navStore.buttonNextColor,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openAdd()
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", { attrs: { left: "" } }, [
                                    _vm._v("mdi-plus"),
                                  ]),
                                  _vm._v("Add Reservation "),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      !_vm.isLoading &&
      _vm.activeSection === "bookings" &&
      _vm.reservations !== undefined &&
      _vm.reservations.length > 0
        ? [
            _c(
              "div",
              { staticClass: "float-right" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "add-button mt-0",
                    attrs: {
                      large: "",
                      color: "" + _vm.navStore.buttonNextColor,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.openAdd()
                      },
                    },
                  },
                  [
                    _c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-plus")]),
                    _vm._v("Add Reservation "),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("section", { staticClass: "m-3" }, [
              _c("h2", { staticClass: "h1-responsive font-weight-bold my-2" }, [
                _vm._v("Reservations"),
              ]),
            ]),
            _c("br"),
            _c("v-data-table", {
              attrs: {
                items: _vm.reservations,
                headers: _vm.tableFields,
                "items-per-page": -1,
                "hide-default-footer": "",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "item.status",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          " " + _vm._s(_vm.formatBookingStatus(item)) + " "
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.actions",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _vm.showCancelButton(item) &&
                        _vm.formatBookingStatus(item) !== "Needs Approval"
                          ? [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-1",
                                  attrs: {
                                    outlined: "",
                                    color: "error",
                                    size: "small",
                                    disabled: item.isCancelling,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showCancelSection(
                                        item,
                                        item.index
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.isCancelling
                                          ? "Cancelling ..."
                                          : "Cancel reservation"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          : _vm.showCancelButton(item) &&
                            _vm.formatBookingStatus(item) === "Needs Approval"
                          ? [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-1",
                                  attrs: {
                                    outlined: "",
                                    color: "error",
                                    size: "small",
                                    disabled: item.isCancelling,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showCancelSection(
                                        item,
                                        item.index
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.isCancelling
                                          ? "Cancelling ..."
                                          : "Cancel request"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          : _vm._e(),
                        _vm.showQrButton(item)
                          ? [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-1",
                                  attrs: {
                                    outlined: "",
                                    color: "primary",
                                    size: "small",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showQrCode(item)
                                    },
                                  },
                                },
                                [_vm._v(" View QR Code ")]
                              ),
                            ]
                          : _vm._e(),
                      ]
                    },
                  },
                ],
                null,
                false,
                3265569058
              ),
            }),
          ]
        : _vm._e(),
      !_vm.isLoading && _vm.activeSection === "qrcode"
        ? [
            _c(
              "v-container",
              { staticClass: "mx-auto", staticStyle: { "max-width": "300px" } },
              [
                _c(
                  "v-row",
                  [
                    _c("v-col", [
                      _c(
                        "div",
                        {
                          staticClass: "float-right",
                          on: {
                            click: function ($event) {
                              _vm.activeSection = "bookings"
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-close-circle")])],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  { staticClass: "mx-auto" },
                  [
                    _vm.selectedReservation
                      ? _c(
                          "v-col",
                          [
                            _c("v-lightbox", {
                              staticClass: "mx-auto my-auto text-center",
                              attrs: {
                                imgs: ["", _vm.selectedReservation.qrUrl],
                                gallery: "",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  { staticClass: "mt-2" },
                  [
                    _c(
                      "v-col",
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              show: true,
                              large: "",
                              color: "amber",
                              block: "",
                            },
                            nativeOn: {
                              click: function ($event) {
                                $event.stopPropagation()
                                _vm.activeSection = "bookings"
                              },
                            },
                          },
                          [_vm._v(" Close ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      !_vm.isLoading && _vm.activeSection === "cancellation"
        ? [
            _c("section", { staticClass: "m-3" }, [
              _c("h2", { staticClass: "h1-responsive font-weight-bold my-2" }, [
                _vm._v("Confirm Cancellation"),
              ]),
            ]),
            _c(
              "v-container",
              {},
              [
                _vm.cancellationReservationCandidates === undefined ||
                _vm.cancellationReservationCandidates.length < 1
                  ? _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          [
                            _c(
                              "v-alert",
                              { attrs: { color: "info", show: true } },
                              [_vm._v("No one is listed in reservation.")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.cancellationReservationCandidates !== undefined &&
                _vm.cancellationReservationCandidates.length > 1
                  ? _c(
                      "v-row",
                      { staticClass: "pb-1 pt-3" },
                      [
                        _c(
                          "v-col",
                          { staticClass: "align-middle text-right" },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "mx-2",
                                staticStyle: {
                                  "text-decoration": "underline",
                                  color: "blue",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    $event.stopPropagation()
                                    return _vm.handleSelectPersonsCancelAll.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [_vm._v("Select All")]
                            ),
                            _c(
                              "a",
                              {
                                staticClass: "mx-2",
                                staticStyle: {
                                  "text-decoration": "underline",
                                  color: "blue",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    $event.stopPropagation()
                                    return _vm.handleSelectPersonsCancelNone.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [_vm._v("None")]
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._l(
                  _vm.cancellationReservationCandidates,
                  function (reservation, index) {
                    return _c(
                      "v-row",
                      {
                        key: index,
                        staticClass: "pb-3 pt-3",
                        style: _vm.rowStyle(index),
                      },
                      [
                        _c(
                          "v-col",
                          { staticClass: "align-middle text-center" },
                          [
                            _c(
                              "v-row",
                              [
                                _c("v-col", [
                                  _vm._v(_vm._s(reservation.firstName)),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { staticClass: "my-auto" },
                          [
                            _c("v-checkbox", {
                              attrs: {
                                value: reservation,
                                id: "defaultUnchecked" + index,
                                label: "Cancel",
                              },
                              model: {
                                value: _vm.cancellationReservationSelected,
                                callback: function ($$v) {
                                  _vm.cancellationReservationSelected = $$v
                                },
                                expression: "cancellationReservationSelected",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }
                ),
              ],
              2
            ),
            _c(
              "v-container",
              { staticClass: "mt-4" },
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { staticClass: "text-center mx-auto" },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "mx-3 mt-3",
                            attrs: { color: "light" },
                            on: {
                              click: function ($event) {
                                _vm.activeSection = "bookings"
                              },
                            },
                          },
                          [_vm._v("Close")]
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "mx-3 mt-3",
                            attrs: {
                              color: _vm.navStore.buttonNextColor,
                              loading: _vm.isCancelling,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleCancelYesClick()
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.isCancelling
                                    ? "Cancelling ..."
                                    : "Yes, Cancel Reservation"
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _c(
        "v-snackbar",
        {
          attrs: {
            color: _vm.snackbar.color,
            timeout: _vm.snackbar.timeout,
            top: "",
            right: "",
          },
          model: {
            value: _vm.snackbar.show,
            callback: function ($$v) {
              _vm.$set(_vm.snackbar, "show", $$v)
            },
            expression: "snackbar.show",
          },
        },
        [_vm._v(" " + _vm._s(_vm.snackbar.text) + " ")]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }