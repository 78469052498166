import { ProfileModule, store } from '@/store';
import { HealthAdditionalInfo, HealthFile, HealthWaiver } from '@fgl/funfangle-sdk/dist/rest/profile';
import { getModule } from 'vuex-module-decorators';

export class HealthUtils {
  profileStore: ProfileModule;

  constructor(profileStore?: ProfileModule) {
    if (profileStore !== undefined) this.profileStore = profileStore;
    else this.profileStore = getModule(ProfileModule, store);
  }

  isOrganizationHealthItemsAddable(): boolean {
    return false;
  }

  getOrganizationHealthItemMappings(): Map<string, HealthAdditionalInfo> {
    const mapping = new Map<string, HealthAdditionalInfo>();
    // Crossing Camp
    if (this.profileStore.organizationId === 'aa52e87d-ee1e-4244-90fb-1094a9c2a037') {
      mapping.set('Food Allergies', { name: 'Food Allergies', longName: 'Please list any known food allergies', required: true, type: 'text' });
      mapping.set('Other Allergies', { name: 'Other Allergies', longName: 'Please list any known medication, environmental or other allergies', required: true, type: 'text' });
      mapping.set('Equipment', { name: 'Equipment', longName: 'Please list any medical equipment the camper is bringing to camp', required: true, type: 'text' });
      mapping.set('Inhaler Permission', { name: 'Inhaler Permission', longName: 'Camper has permission to self-administer inhaler as needed and is responsible for its use.', required: true, type: 'switch', defaultValue: false });
      mapping.set('Epi-pen Permission', { name: 'Epi-pen Permission', longName: 'Camper has an Epi-pen and permission to self-administer as needed and is responsible for its use.', required: true, type: 'switch', defaultValue: false });
      mapping.set('Requires Assistance', { name: 'Requires Assistance', longName: 'Specify assistance camper requires from personnel specifically trained to perform procedures (such as giving injection, testing blood sugar, etc.).', required: true, defaultValue: false });

      mapping.set('OTC_Permission', { name: 'OTC_Permission', longName: 'Permission for Over The Counter (OTC) medications, creams, or powders (as needed) ex. Pain relievers, antihistamines, antacids, pepto bismol, sunburn relief creams, etc.', required: true, type: 'switch', defaultValue: false });
      mapping.set('OTC_Exclusion', { name: 'OTC_Exclusion', longName: 'Please do NOT administer the following OTC medication to my student', required: true, type: 'text' });

      mapping.set('Ins_Carrier', { name: 'Ins_Carrier', longName: 'Insurance Carrier', required: true, type: 'text' });
      mapping.set('Ins_Policy', { name: 'Ins_Policy', longName: 'Policy #', required: true, type: 'text' });
      mapping.set('Ins_Group', { name: 'Ins_Group', longName: 'Group #', required: false, type: 'text' });
    }
    // Foundations
    if (this.profileStore.organizationId === '90c7c4fb-8956-439d-851c-28e94e78bc8a' || this.profileStore.organizationId === 'aaaaaaaa-8956-439d-851c-28e94e78bc8a') {
      mapping.set('Allergies', { name: 'Allergies', required: true });
      mapping.set('Conditions', { name: 'Conditions', longName: 'Health Issues', required: true });
    }
    // RMMN
    if (this.profileStore.organizationId === 'b79159dc-540b-429e-9eda-2c5548e39407') {
      mapping.set('Allergies', { name: 'Allergies', longName: 'Please list any allergies', required: true });
      mapping.set('Conditions', { name: 'Conditions', longName: 'Please list any medical conditions', required: true });
      mapping.set('Dietary Restrictions', { name: 'Dietary Restrictions', longName: 'Please list any dietary restrictions', required: true });
    }
    // Venture of Faith
    if (this.profileStore.organizationId === '7e706cde-a37c-4d8a-bb34-45771f037e9f') {
      mapping.set('Conditions', { name: 'Conditions', longName: 'List any allergies, physical handicaps, etc.', required: true });
    }
    return mapping;
  }

  getOrganizationHealthFileMappings(): Map<string, HealthFile> {
    const mapping = new Map<string, HealthFile>();
    // Foundations
    if (this.profileStore.organizationId === '90c7c4fb-8956-439d-851c-28e94e78bc8a' || this.profileStore.organizationId === 'aaaaaaaa-8956-439d-851c-28e94e78bc8a') {
      mapping.set('immunization-record', { key: 'immunization-record', label: 'Immunization Record', required: true })
    }
    return mapping;
  }

  getOrganizationHealthWaiverMappings(): Map<string, HealthWaiver> {
    const mapping = new Map<string, HealthWaiver>();
    // Crossing Camp
    if (this.profileStore.organizationId === 'aa52e87d-ee1e-4244-90fb-1094a9c2a037') {
      mapping.set('medication-authorization-form', {
        key: 'medication-authorization-form',
        label: 'Medication Authorization Waiver',
        lines: [
          'Medication Administration Guidelines',
          ' ★ Parent/Guardian must bring the medication to the facility to be checked-in with the designated medical/Camp Leader/Church Staff.',
          ' ★ Medication is defined as prescription, non-prescription (over the counter) drugs, and herbal preparations.',
          ' ★ All medication is to be brought to the facility by a parent or responsible adult designee and must be in a PRESCRIPTION CONTAINER plainly marked with the Child’s name, medication name and dosage; including explicit directions for administration. The camp does not allow non-FDA approved drugs to be administered.',
          ' ★ The camp is unable to administer any unlabeled medication. The camp will not give doses, which are different from the amount labeled.',
          ' ★ Only send the amount of medication needed to be administered for the duration of camp (1 pill/day x 2days=2 pills).',
          ' ★ If your child is to receive 1⁄2 a tablet, the medication must be cut at home or by the pharmacist when the prescription is filled.',
          ' ★ Camp is not responsible for the administration of any type of medication that is not checked in with the child.',
          ' ★ The camp Medical staff will do everything possible to support a camper in complying with taking medication as directed in the information provided. However, a camper cannot hold the camp responsible for non-adherence.',
          ' ★ Unused medication shall either be picked up by the parent/guardian otherwise it will be discarded following the check out procedures.',
          'OVER-THE-COUNTER MEDICATION',
          'I give permission for my child to receive any approved medication as needed for minor discomfort or injury. I understand that generic equivalent medications may be used.',
          'Parent/Guardian Authorization:',
          'I hereby give permission for The Crossing Camp Medical Provider to administer the medication provided and listed on this form to my child. I have read the camp’s policies regarding the administration of medication and agree to comply. I understand that the camp is not responsible for non-adherence by my child.',
          'I attest that I have administered at least one dose of the medication to my child without adverse effects. I certify that I have legal authority, understand the risk and consent to medical treatment for the child named above, including the administration of medication. I agree to review special instructions and demonstrate medication administration procedures to the Medical Provider or designated Camp Personnel.',
          'I hereby confirm that my primary responsibility is to administer medication to my child. However, in the event that I am unable to do so, I hereby authorize The Crossing Camp and its employees and agents, on my behalf, to           administer or to attempt to administer to my child (or to allow my child to self- administer, while under the supervision of the designated Crossing Camp Medical Provider and/or Camp personnel), lawfully prescribed           medication in the manner described. I ACKNOWLEDGE THAT IT MAY BE NECESSARY FOR THE          ADMINISTRATION OF MEDICATIONS TO MY CHILD TO BE PERFORMED BY AN INDIVIDUAL OTHER THAN A           NURSE AND SPECIFICALLY CONSENT TO SUCH PRACTICES. I further acknowledge and agree that, when the          lawfully prescribed medication is so administered or attempted to be administered, I waive any claims I might have           against the Crossing Church, its employees and agents arising out of the administration of said medications. In          addition, I agree to hold harmless and indemnify The Crossing Church, against any and all claims, damages, causes of action or injuries incurred or resulting from the administration or attempts at administration of said medication.'
        ]
      });
    }
    return mapping;
  }
}
