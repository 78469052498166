var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "p-0", attrs: { fluid: "" } },
    [
      _vm.showButtons
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", large: "", block: "" },
                      nativeOn: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.goEntryPass(_vm.personId)
                        },
                      },
                    },
                    [_vm._v("Entry Pass")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }